import { InjectableClass, InjectProperty } from "@codecapers/fusion";
import { IonButton, IonContent, IonInput, IonItem, IonLabel, IonList, IonPage } from "@ionic/react";
import * as React from "react";
import { IAuthentication, IAuthentication_id } from "../services/authentication";
import { asyncHandler } from "../utils/async-handler";
import { updateState } from "../utils/update-state";
import { Link, RouteComponentProps } from "react-router-dom";
import { INavigation, INavigation_id } from "../services/navigation";
import "./Login.scss";


export interface IResetPasswordScreenPropsParams {
    //
    // The email that password reset was sent to.
    //
    emailAddress: string;
}

export interface IResetPasswordScreenProps extends RouteComponentProps<IResetPasswordScreenPropsParams> {

}

export interface IResetPasswordScreenState {
    validated: boolean;
    errorMessage?: string;
    working: boolean;
    token: string;
    password: string;
    confirmPassword: string;
}

@InjectableClass()
export class ResetPasswordScreen extends React.Component<IResetPasswordScreenProps, IResetPasswordScreenState> {

    @InjectProperty(IAuthentication_id)
    authentication!: IAuthentication;

    @InjectProperty(INavigation_id)
    navigation!: INavigation;

    constructor(props: IResetPasswordScreenProps) {
        super(props);

        this.state = {
            validated: false,
            working: false,
            token: "",
            password: "",
            confirmPassword: "",
        };

        this.onTokenInputChange = asyncHandler(this, this.onTokenInputChange);
        this.onPasswordInputChange = asyncHandler(this, this.onPasswordInputChange);
        this.onConfirmPasswordInputChange = asyncHandler(this, this.onConfirmPasswordInputChange);
        this.onResetPasswordClick = asyncHandler(this, this.onResetPasswordClick);
    }

    private isValid(): boolean {
        return this.state.token.length > 0
            && this.state.password.length > 0
            && this.state.confirmPassword.length > 0
            && this.state.password === this.state.confirmPassword;
    }

    private async validate(): Promise<void> {
        const isValid = this.isValid();
        let errorMessage: string | undefined;
        if (!isValid) {
            if (this.state.password !== this.state.confirmPassword) {
                errorMessage = "Both passwords must match";
            }
        }

        await updateState(this, {
            errorMessage: errorMessage,
            validated: isValid,
        });

    }

    private async onTokenInputChange(value: string | null | undefined): Promise<void> {
        await updateState(this, { token: value });
        await this.validate();
    }

    private async onPasswordInputChange(value: string | null | undefined): Promise<void> {
        await updateState(this, { password: value });
        await this.validate();
    }

    private async onConfirmPasswordInputChange(value: string | null | undefined): Promise<void> {
        await updateState(this, { confirmPassword: value });
        await this.validate();
    }

    private async onResetPasswordClick(): Promise<void> {
        try {
            await updateState(this, { working: true });
            const result = await this.authentication.resetPassword(
                this.props.match.params.emailAddress,
                this.state.password,
                this.state.token
            );
            this.navigation.navigateRoot(`/account/login?email=${encodeURIComponent(this.props.match.params.emailAddress)}`);
        }
        catch (err) {
            console.error("Error reseting password:");
            console.error(err && err.stack || err);
            await updateState(this, {
                working: false,
                errorMessage: "An error occurred"
            });
        }
    }

    render() {
        return (
            <IonPage className="signup">
                <IonContent fullscreen>
                    <div className="flex flex-col items-center pb-4 login-screen">
                    <div className="mt-16">
                            <img className="balloon-logo" src="assets/logo/final logo_icon colour.svg" />
                        </div>

                        <div className="flex flex-col w-full max-w-xs mt-8 pl-2 pr-2">
                            <div className="text-header text-center">Now check your email</div>
                            <div className="text-message text-center mt-4">We've sent an email to {this.props.match.params.emailAddress} with a token, please copy the token here</div>
                        </div>

                        <div className="flex flex-col w-full max-w-xs pl-2 pr-2">
                            <IonList className="w-full">
                                <IonItem className="mt-1">
                                    <IonLabel position="floating">Token</IonLabel>
                                    <IonInput
                                        name="token-input"
                                        id="token-input"
                                        value={this.state.token}
                                        onIonChange={e => this.onTokenInputChange(e.detail.value)}
                                        required
                                        disabled={this.state.working}
                                        />
                                </IonItem>

                                <IonItem className="mt-1">
                                    <IonLabel position="floating">Password</IonLabel>
                                    <IonInput
                                        name="password-input"
                                        id="password-input"
                                        type="password"
                                        value={this.state.password}
                                        onIonChange={e => this.onPasswordInputChange(e.detail.value)}
                                        required
                                        disabled={this.state.working}
                                        />
                                </IonItem>

                                <IonItem className="mt-1">
                                    <IonLabel position="floating">Confirm password</IonLabel>
                                    <IonInput
                                        name="password-confirm-input"
                                        id="password-confirm-input"
                                        type="password"
                                        value={this.state.confirmPassword}
                                        onIonChange={e => this.onConfirmPasswordInputChange(e.detail.value)}
                                        required
                                        disabled={this.state.working}
                                        />
                                </IonItem>
                            </IonList>
                        </div>

                        {this.state.errorMessage
                            && <div className="flex flex-col w-full max-w-xs mt-8 pl-5 pr-5">
                                <IonItem
                                    color="danger"
                                    className="text-error"
                                    >
                                    <div className="p-2">
                                        {this.state.errorMessage}
                                    </div>
                                </IonItem>
                            </div>
                        }

                        <div className="flex flex-col w-full max-w-xs mt-4 pl-2 pr-2">
                            <IonButton
                                className="main-button h-14 w-full"
                                onClick={this.onResetPasswordClick}
                                disabled={!this.state.validated || this.state.working}
                                >
                                <span className="ml-2">
                                    Reset your password
                                </span>
                            </IonButton>

                            <div className="mt-8">
                                <div className="text-regular">Don't need a password reset?</div>
                                <Link
                                    to="/"
                                    >
                                    Return to start
                                </Link>
                            </div>
                       </div>
                   </div>
                </IonContent>
            </IonPage>
        );
    }
}