import { InjectableClass, InjectProperty } from "@codecapers/fusion";
import { IonButton, IonIcon, IonImg, IonItem, IonSlides, IonSlide } from "@ionic/react";
import { chatbubbleOutline, heartOutline, imageOutline, locationOutline } from "ionicons/icons";
import * as React from "react";
import { truncateText } from "../utils/text";
import "./ViewJourney.scss";
import moment from "moment";
import { IAuthentication, IAuthentication_id } from "../services/authentication";
import { IRepository, IRepository_id } from "../services/repository";
import { asyncHandler } from "../utils/async-handler";
import { IJourneyData, IPostData } from "../model/model";
import { Description } from "./Description";

export interface IViewJourneyProps {

    //
    // The journey being viewed.
    //
    journey: IJourneyData;
}

export interface IViewJourneyState {

}

@InjectableClass()
export class ViewJourney extends React.Component<IViewJourneyProps, IViewJourneyState> {

    @InjectProperty(IAuthentication_id)
    authentication!: IAuthentication;

    @InjectProperty(IRepository_id)
    post!: IRepository;

    constructor(props: IViewJourneyProps) {
        super(props);

        this.state = {
        };

        this.onLikeClick = asyncHandler(this, this.onLikeClick);
    }

    private async onLikeClick(post: IPostData): Promise<void> {

        if (post.liked) {
            post.liked = false;
            post.numLikes -= 1;
            await this.post.unlikePost(post.id);
        }
        else {
            post.liked = true;
            post.numLikes += 1;
            await this.post.likePost(post.id);
        }

        this.forceUpdate(); //TODO: This can work better if the post is in a component of it's won.
    }

    render() {
        return (
            <div className="view-journey pl-2 pt-2 pb-20">
                {this.props.journey.posts && this.props.journey.posts.map((post, index) => {

                    let leftHandGfx: JSX.Element;
                    const lastPostIndex = this.props.journey.posts.length - 1;
                    if (index === 0 && index === lastPostIndex) {
                        // Both first and last post.

                        leftHandGfx = (
                            <>
                                <div
                                    className="absolute"
                                    style={{
                                        top: "calc(30% - 8px)",
                                        left: -8,
                                        color: "#07B7E0",
                                        width: "16px",
                                        height: "16px",
                                    }}
                                    >
                                    <IonIcon icon={imageOutline} />
                                </div>
                            </>
                        );
                    }
                    else if (index === 0) {
                        // The first post.
                        leftHandGfx = (
                            <>
                                <div
                                    className="absolute"
                                    style={{
                                        borderLeft: "1px dashed #C3C3C3",
                                        top: "30%",
                                        height: "70%",
                                    }}
                                    >
                                </div>

                                <div
                                    className="absolute"
                                    style={{
                                        top: "calc(30% - 13px)",
                                        left: -13,
                                        width: "26px",
                                        height: "26px",
                                        backgroundColor: "white",
                                        borderRadius: "50%",
                                        boxShadow: "0 2px 9px 0 rgba(0,0,0,0.3)",
                                    }}
                                    >
                                </div>

                                <div
                                    className="absolute"
                                    style={{
                                        top: "calc(30% - 8px)",
                                        left: -8,
                                        color: "#07B7E0",
                                        width: "16px",
                                        height: "16px",
                                    }}
                                    >
                                    <IonIcon icon={imageOutline} />
                                </div>
                            </>
                        );
                    }
                    else if (index === lastPostIndex) {
                        // The last post.
                        leftHandGfx = (
                            <>
                                <div
                                    className="absolute"
                                    style={{
                                        borderLeft: "1px dashed #C3C3C3",
                                        top: 0,
                                        height: "30%",
                                    }}
                                    >
                                </div>

                                <div
                                    className="absolute"
                                    style={{
                                        top: "calc(30% - 13px)",
                                        left: -13,
                                        width: "26px",
                                        height: "26px",
                                        backgroundColor: "white",
                                        borderRadius: "50%",
                                        boxShadow: "0 2px 9px 0 rgba(0,0,0,0.3)",
                                    }}
                                    >
                                </div>

                                <div
                                    className="absolute"
                                    style={{
                                        top: "calc(30% - 8px)",
                                        left: -8,
                                        color: "#07B7E0",
                                        width: "16px",
                                        height: "16px",
                                    }}
                                    >
                                    <IonIcon icon={imageOutline} />
                                </div>
                            </>
                        );
                    }
                    else {
                        // A post in the middle.
                        leftHandGfx = (
                            <>
                                <div
                                    className="absolute"
                                    style={{
                                        borderLeft: "1px dashed #C3C3C3",
                                        top: 0,
                                        height: "100%",
                                    }}
                                    >
                                </div>

                                <div
                                    className="absolute"
                                    style={{
                                        top: "calc(30% - 13px)",
                                        left: -13,
                                        width: "26px",
                                        height: "26px",
                                        backgroundColor: "white",
                                        borderRadius: "50%",
                                        boxShadow: "0 2px 9px 0 rgba(0,0,0,0.3)",
                                    }}
                                    >
                                </div>

                                <div
                                    className="absolute"
                                    style={{
                                        top: "calc(30% - 8px)",
                                        left: -8,
                                        color: "#07B7E0",
                                        width: "16px",
                                        height: "16px",
                                    }}
                                    >
                                    <IonIcon icon={imageOutline} />
                                </div>
                            </>
                        );
                    }

                    return (
                        <div
                            key={post.id}
                            className="flex flex-row relative overflow-visible ml-6"
                            >

                            {leftHandGfx}

                            <div className="flex flex-col ml-6 w-full">
                                <IonItem
                                    key={post.id}
                                    detail={false}
                                    lines="none"
                                    routerLink={`/main/post/${post.id}`}
                                    className="flex flex-col w-full"
                                    >
                                    <div className="img-row w-full mt-3">
                                        <div className="w-full overflow-x-scroll overflow-y-hidden">
                                            <div className="flex flex-row items-stretch h-full">
                                                <IonSlides pager={post.mediaAssets.length > 1} options={{initialSlide: 0}}>
                                                {post.mediaAssets.map(mediaAsset => {
                                                        const imageHeight = 179;
                                                        return (
                                                          <IonSlide className="feedSlide">
                                                            {/* <div
                                                                key={mediaAsset.id}
                                                                className="mr-1 h-full"
                                                                > */}
                                                                <IonImg
                                                                    style={{
                                                                        height: `${imageHeight}px`,
                                                                        width: `${imageHeight * (mediaAsset.resolution.width / mediaAsset.resolution.height)}px`,
                                                                    }}
                                                                    src={this.authentication.makeAuthenticatedUrl(`/api/asset/get`, { id: `thumb/${mediaAsset.id}` })}
                                                                    />
                                                            {/* </div> */}
                                                            </IonSlide>
                                                        );
                                                    })}
                                                </IonSlides>
                                            </div>
                                        </div>
                                    </div>
                                </IonItem>

                                <div className="flex flex-row mt-2">
                                    <div className="flex flex-col w-full">
                                        <div className="flex flex-row items-center text-location">
                                            <div className="flex flex-row flex-grow items-center">
                                                <IonIcon className="text-sm" icon={locationOutline} />
                                                <div className="ml-1">{post.location.name}</div>
                                            </div>
                                            <div className="flex flex-row items-center ml-1 mr-7">
                                                <div>{post.date && moment(post.date, "YYYY-MM-DD").toDate().toLocaleDateString()}</div>
                                                <div className="ml-1">{post.time}</div>
                                            </div>
                                        </div>

                                        <div className="mt-2">
                                            <Description
                                                text={post.text ?? ""}
                                                />
                                        </div>

                                        <div className="flex flex-row items-center">
                                            <IonButton
                                                fill="clear"
                                                size="small"
                                                className="flex flex-row items-center text-black"
                                                routerLink={`/main/post/${post.id}`}
                                                >
                                                <div className="text-count mr-1 w-4">{post.numComments}</div>
                                                <IonIcon icon={chatbubbleOutline} />
                                            </IonButton>

                                            <IonButton
                                                fill="clear"
                                                size="small"
                                                className="flex flex-row items-center text-black ml-2"
                                                onClick={() => this.onLikeClick(post)}
                                                >
                                                <div className="text-count mr-1 w-4">{post.numLikes}</div>
                                                <IonIcon icon={heartOutline} />
                                            </IonButton>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
       );
    }
}
