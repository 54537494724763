import { Plugins } from "@capacitor/core";
import { InjectableClass, InjectProperty } from "@codecapers/fusion";
import { IonButton, IonContent, IonIcon, IonPage } from "@ionic/react";
import { logInOutline, mailOutline } from "ionicons/icons";
import * as React from "react";
import { IAuthentication, IAuthentication_id } from "../services/authentication";
import { INavigation, INavigation_id } from "../services/navigation";
import { asyncHandler } from "../utils/async-handler";
import { Link } from "react-router-dom";
import "./Onboarding.scss";

export interface IOnboardingScreenProps {

}

export interface IOnboardingScreenState {
}

@InjectableClass()
export class OnboardingScreen extends React.Component<IOnboardingScreenProps, IOnboardingScreenState> {

    @InjectProperty(IAuthentication_id)
    authentication!: IAuthentication;

    @InjectProperty(INavigation_id)
    navigation!: INavigation;

    constructor(props: IOnboardingScreenProps) {
        super(props);

        this.state = {
        };

        this.componentDidMount = asyncHandler(this, this.componentDidMount);
    }

    async componentDidMount(): Promise<void> {
        const connected = await this.authentication.checkConnectivity();
        if (!connected) {
            this.navigation.navigateRoot("/offline");
            return;
        }

        if (this.authentication.isSignedIn()) {
            this.navigation.navigateRoot("/account/setup");
            return;
        }
        else {
            const signedin = await this.authentication.checkSignedIn();
            if (signedin) {
                this.navigation.navigateRoot("/account/setup");
                return;
            }
        }

        Plugins.SplashScreen.hide();
    }

    render() {
        return (
            <IonPage className="onboarding">
                <IonContent fullscreen>
                    <div className="flex flex-col pl-4 pb-4 onboarding-screen">
                        <div className="mt-8">
                            <img className="heading-logo" src="assets/logo/final logo_logo - colour.svg" />
                        </div>

                        <div className="flex flex-col mt-16 mb-24">
                            <div className="onboarding-heading">
						        <h1>Welcome to your adventure!</h1>
                            </div>
                        </div>
                        {/* <div className="flex flex-col w-full max-w-xs mt-32 pl-2 pr-2">
                            <div className="text-regular ml-1">
                                Get started with
                            </div>
                            <div className="flex flex-row mt-5">
                                <IonButton
                                    className="facebook-button w-20 h-14"
                                    routerLink="/account/setup"
                                    routerDirection="root"
                                    >
                                    <IonIcon
                                        icon={logoFacebook}
                                        slot="icon-only"
                                        />
                                </IonButton>
                                <div className="flex-grow"></div>
                                <IonButton
                                    className="google-button w-20 h-14"
                                    routerLink="/account/setup"
                                    routerDirection="root"
                                    >
                                    <IonIcon
                                        icon={logoGoogle}
                                        slot="icon-only"
                                        />
                                </IonButton>
                                <div className="flex-grow"></div>
                                <IonButton
                                    className="twitter-button w-20 h-14"
                                    routerLink="/account/setup"
                                    routerDirection="root"
                                    >
                                    <IonIcon
                                        icon={logoTwitter}
                                        slot="icon-only"
                                        />
                                </IonButton>
                            </div>
                        </div> */}

                        <div className="flex flex-col flex-end w-full max-w-xs mt-64 pl-2 pr-2 align-items-end">
                            <div className="mt-1">
                                <IonButton
                                    className="main-button h-14 w-full"
                                    routerLink="/account/login"
                                    routerDirection="root"
                                    >
                                    {/* <IonIcon
                                        icon={logInOutline}
                                        slot="icon-only"
                                        /> */}
                                    <span className="ml-2">
                                        Login
                                    </span>
                                </IonButton>
                            </div>
                        </div>

                        <div className="flex flex-col w-full max-w-xs mt-6 pl-2 pr-2">
                            <div>
                                {/* <IonButton
                                    className="alt-button h-14 w-full"
                                    routerLink="/account/signup"
                                    routerDirection="root"
                                    fill="clear"
                                    > */}
                                    {/* <IonIcon
                                        icon={mailOutline}
                                        slot="icon-only"
                                        /> */}
                                    <Link className="forgot-password-link sign-up-link items-centre"
                                        to="/account/sign-up"
                                    >
                                        <span className="flex flex-col">
                                            Sign up
                                        </span>
                                    </Link>
                                {/* </IonButton> */}
                            </div>
                        </div>

                        <div className="flex-grow" />
                    </div>
                </IonContent>
            </IonPage>
        );
    }
}