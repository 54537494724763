import { InjectableClass, InjectProperty } from "@codecapers/fusion";
import { ActionSheetButton, withIonLifeCycle } from "@ionic/react";
import * as React from "react";
import { INotification, INotification_id } from "../services/notification";
import { asyncHandler } from "../utils/async-handler";
import { updateState } from "../utils/update-state";
import { FullScreenSpinner } from "./FullScreenSpinner";
import RefreshableScreen from "./RefreshableScreen";

export interface ILoadableScreenProps {

    //
    // Set to true wnen working.
    //
    working?: boolean;

    //
    // Set to true to enable the back button.
    //
    enableBackButton?: boolean;

    //
    // Default back href for the back button.
    //
    defaultBackHref?: string;

    //
    // Enables the menu buttons.
    //
    enableMenuButton?: boolean;

    //
    // Sets the link for the menu button.
    //
    menuButtonLink?: string;

    //
    // Event raised when the menu button is clicked.
    //
    onMenuButtonClick?: () => void;

    //
    // Buttons to display in the action sheet menu.
    //
    menuButtons?: ActionSheetButton[];

    //
    // Class name for the page.
    //
    className?: string;

    //
    // The collapsed header for the page.
    //
    header?: JSX.Element;

    //
    // The collapsed header for the page.
    //
    title?: JSX.Element | string;

    //
    // The collapsible header for the page.
    //
    collapsibleHeader?: JSX.Element;

    //
    // An element to render outside the page's main content.
    //
    postContent?: JSX.Element;

    //
    // Event raise when the page will be entered.
    //
    onWillEnter?: () => Promise<void>;

    //
    // Event raised just before the screen is refreshed.
    //
    onBeforeRefresh?: () => Promise<void>;

    //
    // Event raised to load data for the screen.
    //
    onLoad: () => Promise<void>;
}

export interface ILoadableScreenState {
    //
    // Set to true wnen loading.
    //
    loading: boolean;

    //
    // Set to true wnen working.
    //
    working: boolean;
}

@InjectableClass()
class LoadableScreen extends React.Component<ILoadableScreenProps, ILoadableScreenState> {

    @InjectProperty(INotification_id)
    notification!: INotification;

    constructor(props: ILoadableScreenProps) {
        super(props);

        this.state = {
            loading: true,
            working: false,
        };

        this.ionViewWillEnter = asyncHandler(this, this.ionViewWillEnter);
        this.onRefresh = asyncHandler(this, this.onRefresh);
    }

    async ionViewWillEnter() {
        if (this.props.onWillEnter) {
            await this.props.onWillEnter();
        }
        await this.onLoad(true);
    }

    //
    // Loads initial data.
    //
    private async onLoad(firstLoad: boolean) {
        try {
            await updateState(this, {
                loading: firstLoad,
                working: true,
            });

            await this.props.onLoad();

            await updateState(this, {
                loading: false,
                working: false,
            });
        }
        catch (err) {
            console.error("Error loading:");
            console.error(err && err.stack || err);
            this.notification.error(`Failed to load, please try again.`);
            await updateState(this, {
                loading: false,
                working: false,
            });
        }
    }

    //
    // Event raised to refresh the page.
    //
    private async onRefresh(): Promise<void> {
        if (this.props.onBeforeRefresh) {
            await this.props.onBeforeRefresh();
        }
        await this.onLoad(false);
    }

    render() {
        return (
            <RefreshableScreen
                enableBackButton={this.props.enableBackButton}
                defaultBackHref={this.props.defaultBackHref}
                enableMenuButton={this.props.enableMenuButton}
                menuButtonLink={this.props.menuButtonLink}
                onMenuButtonClick={this.props.onMenuButtonClick}
                menuButtons={this.props.menuButtons}
                className={this.props.className}
                header={this.props.header}
                title={this.props.title}
                collapsibleHeader={this.props.collapsibleHeader}
                onRefresh={this.onRefresh}
                postContent={(
                    <>
                        {(this.state.loading || this.state.working || this.props.working)
                            && <FullScreenSpinner opaque={this.state.loading} />                
                        } 

                        {this.props.postContent}                        
                    </>   
                )}
                >
                {this.props.children}

            </RefreshableScreen>
        );
    }
}

export default withIonLifeCycle(LoadableScreen);