import { InjectableClass, InjectProperty } from "@codecapers/fusion";
import { IonButton, IonContent, IonInput, IonItem, IonLabel, IonList, IonPage } from "@ionic/react";
import * as React from "react";
import { IAuthentication, IAuthentication_id } from "../services/authentication";
import { asyncHandler } from "../utils/async-handler";
import { updateState } from "../utils/update-state";
import { validateEmail } from "../utils/validate-email";
import { Link } from "react-router-dom";
import { INavigation, INavigation_id } from "../services/navigation";

export interface ISignupScreenProps {

}

export interface ISignupScreenState {
    validated: boolean;
    errorMessage?: string;
    working: boolean;
    emailAddress: string;
    password: string;
    confirmPassword: string;
}

@InjectableClass()
export class SignupScreen extends React.Component<ISignupScreenProps, ISignupScreenState> {

    @InjectProperty(IAuthentication_id)
    authentication!: IAuthentication;

    @InjectProperty(INavigation_id)
    navigation!: INavigation;

    constructor(props: ISignupScreenProps) {
        super(props);

        this.state = {
            validated: false,
            working: false,
            emailAddress: "",
            password: "",
            confirmPassword: "",
        };

        this.onEmailInputChange = asyncHandler(this, this.onEmailInputChange);
        this.onPasswordInputChange = asyncHandler(this, this.onPasswordInputChange);
        this.onConfirmPasswordInputChange = asyncHandler(this, this.onConfirmPasswordInputChange);
        this.onSignUpClick = asyncHandler(this, this.onSignUpClick);
    }

    private isValid(): boolean {
        return this.state.emailAddress.length > 0
            && this.state.password.length > 0
            && this.state.confirmPassword.length > 0
            && this.state.password === this.state.confirmPassword
            && validateEmail(this.state.emailAddress);
    }

    private async validate(): Promise<void> {
        const isValid = this.isValid();
        let errorMessage: string | undefined;
        if (!isValid) {
            if (this.state.password !== this.state.confirmPassword) {
                errorMessage = "Both passwords must match";
            }
        }

        await updateState(this, {
            errorMessage: errorMessage,
            validated: isValid,
        });

    }

    private async onEmailInputChange(value: string | null | undefined): Promise<void> {
        await updateState(this, { emailAddress: value });
        await this.validate();
    }

    private async onPasswordInputChange(value: string | null | undefined): Promise<void> {
        await updateState(this, { password: value });
        await this.validate();
    }

    private async onConfirmPasswordInputChange(value: string | null | undefined): Promise<void> {
        await updateState(this, { confirmPassword: value });
        await this.validate();
    }

    private async onSignUpClick(): Promise<void> {
        try {
            await updateState(this, { working: true });
            const result = await this.authentication.register(
                this.state.emailAddress.trim(),
                this.state.password
            );
            await this.authentication.checkSignedIn();
            if (result.ok) {
                this.navigation.navigateRoot(`/account/verify/${encodeURIComponent(this.state.emailAddress)}`);
            }
            else {
                console.error("Error signing up:");
                console.error(result.errorMessage || "Error message not specified.");
                await updateState(this, {
                    working: false,
                    errorMessage: result.errorMessage || "An error occurred"
                });
            }
        }
        catch (err) {
            console.error("Error signing up:");
            console.error(err && err.stack || err);
            await updateState(this, {
                working: false,
                errorMessage: "An error occurred"
            });
        }
    }

    render() {
        return (
            <IonPage className="signup">
                <IonContent fullscreen>
                    <div className="flex flex-col pl-4 pb-4 onroute-logo-background">
                        <div className="mt-16">
                            <img className="heading-logo" src="assets/logo/final logo_logo - colour.svg" />
                        </div>

                        <div className="flex flex-col mt-16 mb-24">
                            <div className="onboarding-heading">
						        <h1>Create your account</h1>
                            </div>
                        </div>

                        <div className="flex flex-col w-full max-w-xs mt-32 pl-2 pr-2">
                            <IonList className="w-full">
                                <IonItem className="mt-1">
                                    <IonLabel position="floating">Email</IonLabel>
                                    <IonInput
                                        name="email-input"
                                        id="email-input"
                                        type="email"
                                        value={this.state.emailAddress}
                                        onIonChange={e => this.onEmailInputChange(e.detail.value)}
                                        required
                                        disabled={this.state.working}
                                        />
                                </IonItem>

                                <IonItem className="mt-1">
                                    <IonLabel position="floating">Password</IonLabel>
                                    <IonInput
                                        name="password-input"
                                        id="password-input"
                                        type="password"
                                        value={this.state.password}
                                        onIonChange={e => this.onPasswordInputChange(e.detail.value)}
                                        required
                                        disabled={this.state.working}
                                        />
                                </IonItem>

                                <IonItem className="mt-1">
                                    <IonLabel position="floating">Confirm password</IonLabel>
                                    <IonInput
                                        name="password-confirm-input"
                                        id="password-confirm-input"
                                        type="password"
                                        value={this.state.confirmPassword}
                                        onIonChange={e => this.onConfirmPasswordInputChange(e.detail.value)}
                                        required
                                        disabled={this.state.working}
                                        />
                                </IonItem>
                            </IonList>
                        </div>

                        {this.state.errorMessage
                            && <div className="flex flex-col w-full max-w-xs mt-8 pl-5 pr-5">
                                <IonItem
                                    color="danger"
                                    className="text-error"
                                    >
                                    <div className="p-2">
                                        {this.state.errorMessage}
                                    </div>
                                </IonItem>
                            </div>
                        }

                        <div className="flex flex-col w-full max-w-xs mt-16 pl-2 pr-2">
                            <IonButton
                                className="main-button h-14 w-full"
                                onClick={this.onSignUpClick}
                                disabled={!this.state.validated || this.state.working}
                                >
                                <span className="ml-2">
                                    Sign up
                                </span>
                            </IonButton>

                            <div className="flex flex-col items-center mt-1 text-xs text-gray-500">
                                By signing up you agree to our terms and conditions
                                <div className="flex flex-row">
                                    <a
                                        className="mr-2"
                                        href="https://www.theonrouteapp.com/privacy-policy"
                                        target="_blank"
                                        >
                                        Privacy
                                    </a>
                                    |
                                    <a
                                        className="ml-2"
                                        href="https://www.theonrouteapp.com/terms-and-conditions"
                                        target="_blank"
                                        >
                                        Legal
                                    </a>
                                </div>
                            </div>

                           <div className="mt-8">
                                <span className="text-regular">Already onboard? </span>
                                <IonButton
                                    className="alt-button h-14 w-full"
                                    routerLink="/account/login"
                                    routerDirection="root"
                                    fill="clear"
                                    >
                                        Login
                                </IonButton>
                            </div>
                        </div>
                   </div>
                </IonContent>
            </IonPage>
        );
    }
}