import { IonIcon, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from "@ionic/react";
import * as React from "react";
import { Route } from "react-router";
import { addCircleOutline, home, notifications, personCircleOutline, search } from 'ionicons/icons';
import FeedScreen from "./Feed";
import LiveJourneyScreen from "./LiveJourney";
import PostScreen from "./Post";
import MapScreen from "./Map";
import ProfileScreen from "./Profile";
import EditProfileScreen from "./EditProfile";
import Explore from "./Explore";
import { InjectableClass, InjectProperty } from "@codecapers/fusion";
import { IAuthentication, IAuthentication_id } from "../services/authentication";
import NewJourneyScreen from "./NewJourney";
import ViewJourneyScreen from "./ViewJourney";
import EditJourneyScreen from "./EditJourney";
import EditPostScreen from "./EditPost";
import LocationPickerTestScreen from "./LocationPickerTest";
import { IProfileData } from "../model/model";
import { asyncHandler } from "../utils/async-handler";
import { IRepository, IRepository_id } from "../services/repository";
import { updateState } from "../utils/update-state";
import { ProfilePhoto } from "../components/ProfilePhoto";
import NotificationsScreen from "./Notifications";

export interface IMainScreenProps {

}

export interface IMainScreenState {
    //
    // Profile data (once loaded).
    //
    profile?: IProfileData;
}

@InjectableClass()
export default class MainScreen extends React.Component<IMainScreenProps, IMainScreenState> {

    @InjectProperty(IAuthentication_id)
    authentication!: IAuthentication;

    @InjectProperty(IRepository_id)
    post!: IRepository;

    constructor(props: IMainScreenProps) {
        super(props);

        this.state = {};
        
        this.componentDidMount = asyncHandler(this, this.componentDidMount);
    }

    async componentDidMount() {
        const profile = await this.post.loadProfileForDisplay();
        await updateState(this, { profile: profile });        
    }

    render() {
        return (
            <IonTabs>
                <IonRouterOutlet>
                    <Route path="/main/feed" component={FeedScreen} exact={true} />
                    <Route path="/main/explore" component={Explore} exact={true} />
                    <Route path="/main/live-journey" component={LiveJourneyScreen} exact={true} />
                    <Route path="/main/new-journey" component={NewJourneyScreen} exact={true} />
                    <Route path="/main/journey/:id" component={ViewJourneyScreen} exact={true} />
                    <Route path="/main/edit-journey/:id" component={EditJourneyScreen} exact={true} />
                    <Route path="/main/post/:id" component={PostScreen} exact={true} />
                    <Route path="/main/edit-post/:id" component={EditPostScreen} exact={true} />
                    <Route path="/main/map/:id" component={MapScreen} exact={true} />
                    <Route path="/main/notifications" component={NotificationsScreen} exact={true} />
                    <Route path="/main/profile/:id?" component={ProfileScreen} />                    
                    <Route path="/main/edit-profile" component={EditProfileScreen} exact={true} />
                    <Route path="/main/location-picker-test" component={LocationPickerTestScreen} exact={true} />
                </IonRouterOutlet>
                <IonTabBar slot="bottom">
                    <IonTabButton tab="feed" href="/main/feed">
                        <IonIcon icon={home} />
                    </IonTabButton>
                    <IonTabButton tab="explore" href="/main/explore">
                        <IonIcon icon={search} />
                    </IonTabButton>
                    <IonTabButton tab="journey" href="/main/live-journey">
                        <IonIcon icon={addCircleOutline} />
                    </IonTabButton>
                    <IonTabButton tab="notifications" href="/main/notifications">
                        <IonIcon icon={notifications} />
                    </IonTabButton>
                    <IonTabButton tab="profile" href="/main/profile">
                        {this.state.profile?.photo 
                            ? <ProfilePhoto
                                size={30}
                                photo={this.state.profile?.photo}
                                />
                            : <IonIcon 
                                icon={personCircleOutline} 
                                />
                        }
                    </IonTabButton>
                </IonTabBar>
            </IonTabs>
        );
    }
}
