import { InjectableClass, InjectProperty } from "@codecapers/fusion";
import { IonAlert, IonButton, IonContent, IonIcon, IonItem, IonTextarea } from "@ionic/react";
import { analyticsOutline, chatbubbleOutline, heart, heartOutline, locationOutline } from "ionicons/icons";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import { ICommentData, IMediaAsset, IPostData } from "../model/model";
import { IAuthentication, IAuthentication_id } from "../services/authentication";
import { INotification, INotification_id } from "../services/notification";
import { ICachedList, IRepository, IRepository_id } from "../services/repository";
import { asyncHandler, handleAsync } from "../utils/async-handler";
import { updateState } from "../utils/update-state";
import moment from "moment";
import "./Post.scss";
import { ProfilePhoto } from "../components/ProfilePhoto";
import { Description } from "../components/Description";
import LoadableListScreen from "../components/LoadableListScreen";
import { INavigation, INavigation_id } from "../services/navigation";
import { Comment } from "../components/Coment";
import { MediaAssetView } from "../components/MediaAssetView";
import Modal from "../components/Modal";
import { MediaAssetPreview } from "../components/MediaAssetPreview";

//
// Number of comments in a "page".
//
const PAGE_SIZE = 10;

//
// Parameters passed in by URL.
//
export interface IPostScreenMatchProps {
    //
    // ID of the post we are viewing.
    //
    id: string;
}

export interface IPostScreenProps extends RouteComponentProps<IPostScreenMatchProps> {
}

export interface IPostScreenState {
    //
    // Post data (once loaded).
    //
    post?: IPostData;

    //
    // Comments on the post, if requested to load.
    //
    comments?: ICachedList<ICommentData>;

    //
    // Comment currently being edited.
    //
    comment: string;

    //
    // Set this to view a media asset full screen.
    //
    fullScreenMediaAsset?: IMediaAsset;

    //
    // Set to true to show the delete post confirmation dialog.
    //
    showDeletePostConfirmation: boolean;
}

@InjectableClass()
export default class PostScreen extends React.Component<IPostScreenProps, IPostScreenState> {

    @InjectProperty(IRepository_id)
    post!: IRepository;

    @InjectProperty(INotification_id)
    notification!: INotification;

    @InjectProperty(IAuthentication_id)
    authentication!: IAuthentication;

    @InjectProperty(INavigation_id)
    navigation!: INavigation;
    
    constructor(props: IPostScreenProps) {
        super(props);

        this.state = {
            comment: "",
            showDeletePostConfirmation: false,
        };

        this.onLoad = this.onLoad.bind(this);
        this.loadComments = this.loadComments.bind(this);
        this.onLikeClick = asyncHandler(this, this.onLikeClick);
        this.onCommentChange = asyncHandler(this, this.onCommentChange);
        this.onPostComment = asyncHandler(this, this.onPostComment);
        this.onDeletePostClicked = asyncHandler(this, this.onDeletePostClicked);
        this.onConfirmDeletePostClicked = asyncHandler(this, this.onConfirmDeletePostClicked);
    }

    //
    // Loads data for this screen.
    //
    private async onLoad(): Promise<void> {
        const postData = await this.post.loadPost(this.props.match.params.id);
        await updateState(this, {
            post: postData,
        });

        await this.loadComments(false);
    }

    //
    // Load comments.
    //
    private async loadComments(loadMore: boolean) {
        const comments = await this.post.loadCommentsForPost(this.props.match.params.id, PAGE_SIZE, loadMore);
        await updateState(this, {
            comments: comments,
        });
    }

    //
    // User clicked the like button.
    //
    private async onLikeClick(): Promise<void> {

        if (!this.state.post) {
            throw new Error(`Data not loaded yet!`);
        }

        let newNumLikes = this.state.post.numLikes;

        if (this.state.post.liked) {
            await this.post.unlikePost(this.props.match.params.id);    
            newNumLikes -= 1;
        }
        else {
            await this.post.likePost(this.props.match.params.id);
            newNumLikes += 1;
        }

        await updateState(this, {
            post: {
                ...this.state.post,
                numLikes: newNumLikes,
                liked: !this.state.post.liked,
            },
        });
    }

    //
    // Event raised when user has typed a comment.
    //
    private async onCommentChange(value: string | null | undefined): Promise<void> {
        await updateState(this, { comment: value || "" });
    }

    //
    // Event raised when user has clicked button to post a comment.
    //
    private async onPostComment(): Promise<void> {

        try {
            await updateState(this, { working: true });
    
            await this.post.addNewComment({
                text: this.state.comment.trim(),
                postId: this.state.post!.id,
            });
    
            // Reset state for a new post.
            await updateState(this, {
                comment: "",
                working: false,
            });

            // Quietly reload the post and comments.
            await this.onLoad();

            await this.notification.success(`Your comment was published`);
        }    
        catch (err) {
            console.log("Failed to publish comment.");
            console.log(err && err.stack || err);
            await this.notification.error("Failed to publish comment, please try again")
            await updateState(this, {
                working: false,
            });
        }
    }

    //
    // Event raised when the user has clicked the button to delete their own post.
    //
    private async onDeletePostClicked(): Promise<void> {
        await updateState(this, { showDeletePostConfirmation: true });
    }
    
    //
    // Event raised when the user has confirmed to delete their post.
    //
    private async onConfirmDeletePostClicked(): Promise<void> {
        try {
            await this.post.deletePost(this.props.match.params.id);
        }
        catch (err) {
            console.error(`Failed to delete post ${this.props.match.params.id}.`);
            console.error(err && err.stack || err);
            this.notification.error("Failed to delete post, please try again later");
            return;
        }

        this.navigation.back();
        this.notification.success("You deleted your post"); 
    }

    render() {
        const liked = this.state.post && this.state.post.liked;
        const numLikes = this.state.post && this.state.post.numLikes;
        const numComments = this.state.post && this.state.post.numComments;
        const menuButtons =
            this.state.post?.own
                ? [ // Menu buttons for the user's own post.
                    {
                        text: 'Edit post',
                        handler: () => {
                            this.navigation.push(`/main/edit-post/${this.props.match.params.id}`);
                        },
                    }, 
                    {
                        text: 'Delete post',
                        role: "destructive",
                        handler: this.onDeletePostClicked,
                    }, 
                ]
                : [ // Menu buttons when looking at another user's post.
                {
                    text: 'Report offensive content',
                    handler: () => {
                        handleAsync(async () => {
                            await this.authentication.post("/api/report", { postId: this.state.post?.id });
                        });
                    },
                }, 
            ]

        return (
            <LoadableListScreen
                className="post-screen"
                enableBackButton={true}
                menuButtons={menuButtons}
                title="View post"
                numLoaded={this.state.comments?.data.length ?? 0}
                hasMore={this.state.comments?.haveMore ?? false}
                onLoad={this.onLoad}
                onLoadMore={() => this.loadComments(true)}
                >
                <div className="flex flex-col pt-4 pb-8 pl-4">

                    <div className="flex flex-row items-center w-full">
                        <IonItem
                            detail={false}
                            lines="none"
                            routerLink={`/main/profile/${this.state.post?.userId}`}
                            className="flex flew-row flex-grow items-center w-full"
                            >                                
                            <ProfilePhoto 
                                size={40}
                                photo={this.state.post?.profile?.photo}
                                />

                            <div className="flex flex-col ml-2">
                                <div className="text-profile">{this.state.post?.profile?.screenName}</div>
                                <div className="text-time">Updated {moment.utc(this.state.post?.dateUpdated).fromNow()}</div>
                            </div>
                        </IonItem>
                    </div>                    
                
                    <div className="flex flex-col flex-none w-full">
                        
                        <div className="flex flex-row items-center flex-none">
                            <div className="text-title">
                                {this.state.post?.journey.title}
                            </div>

                            <div className="flex-grow" />

                            <IonButton
                                fill="clear"
                                size="small"
                                routerLink={`/main/journey/${this.state.post?.journeyId}`}
                                >
                                <IonIcon 
                                    icon={analyticsOutline} 
                                    slot="icon-only"
                                    className="text-black"
                                    />
                            </IonButton>
                        </div> 

                        <div className="text-time">
                            {this.state.post?.date
                                && moment(this.state.post.date, "YYYY-MM-DD").toDate().toLocaleDateString() 
                            }
                        </div>

                        <div className="pt-1 pr-4">
                            <Description
                                text={this.state.post?.text ?? ""}
                                />
                        </div>

                        <div className="img-row w-full mt-2">
                            <IonContent className="w-full" scrollX={true} scrollY={false}>
                                <div className="flex flex-row items-stretch h-full">
                                    {this.state.post && this.state.post.mediaAssets.map(mediaAsset => {
                                        return (
                                            <div 
                                                key={mediaAsset.id}
                                                className="mr-1 h-full relative"
                                                onClick={() => {
                                                    this.setState({ fullScreenMediaAsset: mediaAsset });
                                                }}
                                                >
                                                <MediaAssetPreview 
                                                    mediaAsset={mediaAsset}
                                                    imageHeight={240}
                                                    />
                                            </div>
                                        );
                                    })}
                                </div>
                            </IonContent>
                        </div>

                        <div className="flex flex-row items-center">
                            <IonIcon className="text-sm" icon={locationOutline} />
                            <div className="text-location ml-1">{this.state.post?.location.name ?? "Location not reverse geocoded yet"}</div>
                        </div>                                            
                    </div>
                        
                    <div className="flex flex-col flex-none w-full mt-1">

                        <div className="flex flex-row items-center">
                            <div
                                className="flex flex-row items-center text-black ml-1"
                                >
                                <div className="text-count mr-1 w-4">{numComments}</div>
                                <IonIcon icon={chatbubbleOutline} />
                            </div>

                            <IonButton 
                                fill="clear"
                                size="small"
                                className="flex flex-row items-center text-black ml-2"
                                onClick={this.onLikeClick}
                                >
                                <div className="text-count mr-1 w-4">{numLikes}</div>
                                <IonIcon icon={liked ? heart : heartOutline} />
                            </IonButton>

                            {/* NOT IN MVP
                            <IonButton 
                                fill="clear"
                                size="small"
                                className="flex flex-row items-center text-black"
                                >
                                <div className="text-count mr-1">2</div>
                                <IonIcon icon={eyeOutline} />
                            </IonButton>                                                 

                            <div className="flex-grow" />

                            <IonButton 
                                fill="clear"
                                size="small"
                                className="flex flex-row items-center text-black"
                                >
                                <IonIcon icon={bookmarkOutline} slot="icon-only" />
                            </IonButton>                                                
                            */}
                        </div>

                        <div className="mt-1 w-full h-0 page-border"></div>

                        <div className="flex flex-col w-full mt-2 pl-1 pr-4 pb-40">

                            <IonItem 
                                className="flex flex-row"
                                lines="none"
                                detail={false}
                                >
                                <ProfilePhoto 
                                    size={40}
                                    photo={this.state.post?.profile?.photo}
                                    />

                                <div className="flex-grow text-comment h-full pt-1 pl-3 pr-2 pb-1">
                                    <IonTextarea
                                        className="h-full"
                                        placeholder="Add a comment..."
                                        autoCapitalize="sentences"
                                        autoGrow={true}
                                        value={this.state.comment}
                                        onIonChange={e => this.onCommentChange(e.detail.value)}
                                        />
                                </div>

                                {this.state.comment.length > 0
                                    && <IonButton
                                        fill="clear"
                                        size="small"
                                        onClick={this.onPostComment}
                                        >
                                        Post
                                    </IonButton>
                                }
                            </IonItem>

                            {this.state.comments?.data.map(comment => {
                                return (
                                    <Comment
                                        key={comment.id} 
                                        comment={comment}
                                        onCommentDeleted={this.onLoad}
                                        />
                                );
                            })}

                        </div>
                    </div>
                </div>

                <Modal
                    show={this.state.fullScreenMediaAsset !== undefined}
                    onClose={() => updateState(this, { fullScreenMediaAsset: undefined })}
                    header={this.state.post?.profile
                        && <IonItem
                            detail={false}
                            lines="none" 
                            routerLink={`/main/profile/${this.state.post.userId}`}
                            className="flex flew-row items-center w-full flex-none pl-2 pt-2 pb-2"
                            >
                            <ProfilePhoto 
                                size={40}
                                photo={this.state.post.profile.photo}
                                />
                            <div className="flex flex-col ml-4">
                                <div className="text-profile">{this.state.post.profile.screenName}</div>
                                <div className="text-time">Updated {this.state.post && moment.utc(this.state.post.dateUpdated).fromNow()}</div>
                            </div>
                            <div className="flex-grow" />
                        </IonItem>
                    }
                    >
                    <div 
                        className="flex flex-col items-center justify-center h-full w-full"
                        >
                        <MediaAssetView
                            mediaAsset={this.state.fullScreenMediaAsset!}
                            />
                    </div>
                </Modal>

                <IonAlert
                    isOpen={this.state.showDeletePostConfirmation}
                    onDidDismiss={() => this.setState({ showDeletePostConfirmation: false })}
                    header="Delete post"
                    message="Are you sure you want to delete this post?"
                    buttons={[
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            handler: () => this.setState({ showDeletePostConfirmation: false }),
                        },
                        {
                            text: 'OK',
                            role: "destructive",
                            handler: this.onConfirmDeletePostClicked,
                        },
                    ]}                    
                    />

            </LoadableListScreen>
       );
    }
}
