import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, withIonLifeCycle, IonItem, IonCard, IonButton, IonCardHeader, createGesture, IonApp } from "@ionic/react";
import React, { useRef, useEffect } from "react";
import "./Map.scss";
import L from "leaflet";
import { RouteComponentProps } from "react-router";
import { updateState } from "../utils/update-state";
import { InjectableClass, InjectProperty } from "@codecapers/fusion";
import { IRepository, IRepository_id } from "../services/repository";
import { asyncHandler } from "../utils/async-handler";
import { IAuthentication, IAuthentication_id } from "../services/authentication";
import { sleep } from "../utils/sleep";
import { IJourneyData, IPostData, IProfileData} from "../model/model";
import Map from "../components/Map";
import { INavigation, INavigation_id } from "../services/navigation";
import BottomDrawer  from "../components/BottomDrawer";

//
// Parameters passed in by URL.
//
export interface IMapScreenMatchProps {
    //
    // ID of the journey we are viewing.
    //
    id: string;
}

export interface IMapScreenProps extends RouteComponentProps<IMapScreenMatchProps> {

}

export interface IMapScreenState {
    //
    // Set to true while data is being loaded.
    //
    loading: boolean;

    //
    // Journey data, once loaded.
    //
    journey?: IJourneyData;

    //
    // Users profile data, once loaded.
    //
    profile?: IProfileData;
}

@InjectableClass()
class MapScreen extends React.Component<IMapScreenProps, IMapScreenState> {

    @InjectProperty(IRepository_id)
    post!: IRepository;

    @InjectProperty(IAuthentication_id)
    authentication!: IAuthentication;

    @InjectProperty(INavigation_id)
    navigation!: INavigation;

    //
    // Reference to the map's HTML element.
    //
    private mapRef: React.RefObject<Map>;

    //
    // Reference to the map API object.
    //
    private map?: L.Map;

    //
    // Markers that were added to the map.
    //
    private markers: L.Marker[] = [];

    //
    // The line that marks the route on the map.
    //
    private line?: L.Polyline;

    constructor(props: IMapScreenProps) {
        super(props);

        this.state = {
            loading: true,
        };

        this.mapRef = React.createRef<Map>();

        this.componentDidMount = asyncHandler(this, this.componentDidMount);
        this.ionViewWillEnter = asyncHandler(this, this.ionViewWillEnter);
        this.onMapLoaded = this.onMapLoaded.bind(this);
    }

    //
    // Event raised when the map has been loaded.
    //
    async componentDidMount(): Promise<void> {
        await this.onMapLoaded();
    }

    //
    // Event raised when the map has been loaded.
    //
    private async onMapLoaded(): Promise<void> {
        console.log('onMapLoaded')
        if (this.mapRef.current) {
            this.map = this.mapRef.current.getMap();
        }
        const journey = await this.post.loadJourney(this.props.match.params.id);
        await updateState(this, {
            journey: journey,
        });

        const profile = await this.post.loadProfileForDisplay(journey.userId);
        await updateState(this, {
            profile: profile,
        });
    }

    //
    // This is called 600 ms before ionViewDidEnter.
    // If loading is started in ionViewDidEnter we are wasting a lot of time!
    //
    async ionViewWillEnter() {
        await updateState(this, {
            loading: true,
        });

        try {
            this.clearMap();

            const journey = await this.post.loadJourney(this.props.match.params.id);
            await updateState(this, {
                journey: journey,
            });

            while (!this.map) { // Wait until map has loaded.
                await sleep(10);
            }

            await this.addJourneyToMap();
        }
        finally {
            await updateState(this, {
                loading: false,
            });
        }
    }

    //
    // Renders the journey on the map.
    //
    private async addJourneyToMap(): Promise<void> {
        if (!this.state.journey) {
            return;
        }

        const posts = this.state.journey!.posts.filter(post => post.location);
        const locs = posts.map(post => L.latLng([ post.location.latitude, post.location.longitude ]));

        // This has to be done after the load!
        this.map!.fitBounds(L.latLngBounds(locs), {
            padding: [120, 120],
            animate: false,
        });

        // https://leafletjs.com/reference-1.7.1.html#polyline
        this.line = L.polyline(
            locs,
            {
                color: "#54c6e8",
                weight: 2,
                dashArray: "10,12",
            }
        );
        this.line.addTo(this.map!);

        await Promise.all(
            posts.map(post =>
                this.createMarker(
                    post,
                    (post.mediaAssets && post.mediaAssets.length > 0)
                        ? this.authentication.makeAuthenticatedUrl(`/api/asset/get`, { id: `thumb/${post.mediaAssets[0].id}` }) // Display the first photo in the map
                        : `assets/leaflet/marker-icon-2x.png`
                )
            )
        );
    }

    //
    // Create a route marker at the requested location.
    //
    private createMarker(post: IPostData, imgSrc: string): Promise<void> {
        return new Promise<void>(resolve => {

            var canvas = document.createElement('canvas');
            const size = 256;
            const pointerGutter = 50;
            const shadowBorder = 30;
            const markerBorder = 12;
            canvas.width = size;
            canvas.height = size + pointerGutter;

            // For debugging
            // canvas.style.zIndex = "50000";
            // canvas.style.position = "absolute";
            // document.body.appendChild(canvas);
            const ctx = canvas.getContext("2d")!;

            const img = document.createElement('img');
            img.crossOrigin = "anonymous";

            // When the image is loaded, draw it
            img.onload = () => {

                const center = size / 2;

                ctx.shadowColor = "rgb(0, 0, 0, 0.9)";
                ctx.shadowBlur = shadowBorder;
                ctx.shadowOffsetX = 0;
                ctx.shadowOffsetY = 0;

                let radius = (canvas.width / 2) - shadowBorder;

                ctx.fillStyle = "white";

                ctx.beginPath();
                ctx.beginPath();
                ctx.arc(center, center, radius, 0, 2 * Math.PI, false);
                ctx.moveTo(center, canvas.height - shadowBorder);
                ctx.lineTo(center - pointerGutter / 2, size - shadowBorder - 5);
                ctx.lineTo(center + pointerGutter / 2, size - shadowBorder - 5);
                ctx.closePath();
                ctx.fill();

                radius -= markerBorder;

                // Save the state, so we can undo the clipping
                ctx.save();

                // Create a shape, of some sort
                ctx.beginPath();
                ctx.arc(center, center, radius, 0, 2 * Math.PI, false);
                ctx.closePath();

                // Clip to the current path
                ctx.clip();

                ctx.drawImage(img, center - (img.width / 2), center - (img.height / 2));

                // Undo the clipping
                ctx.restore();

                const photoIcon = L.icon({
                    iconUrl: canvas.toDataURL(),
                    iconSize: [75, 90],
                    iconAnchor: [75 / 2, 85],
                });

                const loc: L.LatLngTuple = [ post.location.latitude, post.location.longitude ];
                const marker = L.marker(loc, { icon: photoIcon })
                    .addTo(this.map!)
                    .on("click", () => {
                        this.navigation.push(`/main/post/${post.id}`);
                    });
                this.markers.push(marker);
                resolve();
            };

            // Specify the src to load the image
            img.src = imgSrc;
        });
    }

    componentWillUnmount() {
        this.clearMap();
    }

    //
    // Clears the map of all previous lines and markers.
    //
    private clearMap() {
        if (!this.map) {
            return;
        }

        for (const marker of this.markers) {
            marker.remove();
        }

        if (this.line) {
            this.line.remove();
            this.line = undefined;
        }

        this.markers = [];
    }

    render() {
        return (
            <IonPage className="map">
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton
                                className="text-black"
                                defaultHref="/main/feed"
                                text={""}
                                />
                        </IonButtons>

                        <IonTitle>Journey map</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <div
                        className="flex flex-col w-full h-3/4 z-0 static"
                        >
                        <Map
                            ref={this.mapRef}
                            loading={this.state.loading}
                            cacheKey="journey-map"
                            onLoaded={this.onMapLoaded}
                            tileLayer={this.authentication.getConfigValue("mapScreen.tileLayer", {
                                    urlTemplate: "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}",
                                    options: {
                                        attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ'
                                    },
                                })
                            }
                            zoom={this.authentication.getConfigValue("mapScreen.zoom", {
                                min: 0,
                                max: 16,
                            })}
                        />
                        <div className="absolute bottom-0 w-full h-full">
                            <BottomDrawer profile={this.state.profile} journey={this.state.journey} />
                        </div>
                    </div>
                </IonContent>
           </IonPage>
       );
    }
}

export default withIonLifeCycle(MapScreen);
