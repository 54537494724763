import React from 'react';
import { Route } from 'react-router-dom';
import {
  IonApp,
  IonRouterOutlet,
  IonToast,
  setupConfig,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import "./theme/tailwind.css";
import "./app.scss";

import MainScreen from './pages/Main';
import { OnboardingScreen } from './pages/Onboarding';
import { SignupScreen } from './pages/Signup';
import { LoginScreen } from './pages/Login';
import { VerifyScreen } from './pages/Verify';
import SetupScreen from './pages/Setup';
import { FindJourneysScreen } from './pages/FindJourneys';
import { InjectableClass, InjectProperty, registerSingleton } from '@codecapers/fusion';
import { INotification, INotification_id } from './services/notification';
import { OfflineScreen } from './pages/Offline';
import { IAuthentication, IAuthentication_id } from './services/authentication';
import { forceUpdate } from './utils/force-update';
import { Plugins } from '@capacitor/core';
import { INavigation, INavigation_id, Navigation } from './services/navigation';
import { IRepository, IRepository_id } from './services/repository';
import { RequestPasswordResetScreen } from './pages/RequestPasswordReset';
import { ResetPasswordScreen } from './pages/ResetPassword';

export interface IAppProps {
}

interface IAppState {
}


setupConfig({
    hardwareBackButton: false,
    swipeBackEnabled: false,
})
@InjectableClass()
class App extends React.Component<IAppProps, IAppState> {

    @InjectProperty(INotification_id)
    notification!: INotification;

    @InjectProperty(IAuthentication_id)
    authentication!: IAuthentication;

    @InjectProperty(IRepository_id)
    post!: IRepository;

    //
    // Reference to the router.
    //
    routerRef: React.RefObject<IonReactRouter>;

    //
    // Navigation interface.
    //
    navigation!: INavigation;

    constructor(props: IAppProps) {
        super(props);

        this.state = {
        };

        this.routerRef = React.createRef<IonReactRouter>();

        this.onNotificationsUpdated = this.onNotificationsUpdated.bind(this);
        this.onDisconnected = this.onDisconnected.bind(this);
        this.onSignedOut = this.onSignedOut.bind(this);

        Plugins.SplashScreen.show();
    }
    
    componentDidMount() {
        this.navigation = new Navigation(this.routerRef.current!);
        registerSingleton(INavigation_id, this.navigation);

        this.authentication.onDisconnected.attach(this.onDisconnected);
        this.authentication.onSignedOut.attach(this.onSignedOut);
        this.notification.onUpdated.attach(this.onNotificationsUpdated);

        window.addEventListener("push-notification", (event: any) => {
            if (event.notificationType === "like") {
                this.navigation.push(`/main/post/${event.postId}`);
            }
            else if (event.notificationType === "comment") {
                this.navigation.push(`/main/post/${event.postId}`);
            }
            else if (event.notificationType === "follow") {
                this.navigation.navigateRoot(`/main/profile`);
            }
            else {
                console.error(`Unexpected notification:`);
                console.error(event);
            }
        });
    }

    componentWillUnmount(): void {
        this.authentication.onDisconnected.detach(this.onDisconnected);
        this.authentication.onSignedOut.detach(this.onSignedOut);
        this.notification.onUpdated.detach(this.onNotificationsUpdated);
    }

    //
    // Event raised when notifications are received.
    //
    private async onNotificationsUpdated(): Promise<void> {
        await forceUpdate(this);
    }

    //
    // Event raised when app has been disconnected from the internet.
    //
    private async onDisconnected(): Promise<void> {
        console.log("Internet is disconnected, navigating to offline");
        this.navigation.navigateRoot("/offline");
    }

    //
    // Event raised when the user has been signed out.
    //
    private async onSignedOut(): Promise<void> {
        console.log("User signed out, navigating to onboarding");
        this.post.clearCache();
        this.navigation.navigateRoot("/");
    }

    render() {
        return (
            <IonApp>
                <IonReactRouter ref={this.routerRef} >
                    <IonRouterOutlet>
                        <Route path="/" component={OnboardingScreen} exact={true} />
                        <Route path="/offline" component={OfflineScreen} />
                        <Route path="/account/signup" component={SignupScreen} />
                        <Route path="/account/login" component={LoginScreen} />
                        <Route path="/account/verify/:emailAddress" component={VerifyScreen} />
                        <Route path="/account/setup" component={SetupScreen} />
                        <Route path="/account/find-journeys" component={FindJourneysScreen} />
                        <Route path="/account/request-password-reset" component={RequestPasswordResetScreen} />
                        <Route path="/account/reset-password/:emailAddress" component={ResetPasswordScreen} />
                        <Route path="/main" render={() => <MainScreen />} />
                    </IonRouterOutlet>
                </IonReactRouter>

                {this.notification.getNotifications().map(toast => (
                    <IonToast
                        key={toast.id}
                        isOpen={true}
                        onDidDismiss={() => {
                            this.notification.dismiss(toast)
                        }}
                        message={toast.message}
                        duration={toast.duration}
                        position={toast.position}
                        header={toast.header}
                        color={toast.color}
                        buttons={[{
                            side: "end",
                            role: "cancel",
                            icon: "close",
                        }]}
                    />
                ))}
            </IonApp>
        );
    }    
}

export default App;
