import * as React from 'react';
import L from "leaflet";
 import { asyncHandler, handleAsync } from '../utils/async-handler';
import { IonSpinner } from '@ionic/react';
import { Plugins } from '@capacitor/core';
import { ILocation } from '../model/model';
const { Geolocation } = Plugins;

const DEFAULT_LOCATION = {
    latitude: 40.66402,
    longitude: -74.10477,
};

const DEFAULT_ZOOM = 7;

export interface IMapProps {
    //
    // Set to true to display the loading spinner.
    //
    loading?: boolean;

    //
    // If set the map will be cached and not reloaded.
    //
    cacheKey?: string;

    //
    // Initial location to focus on.
    // If not specifed it defaults to users current location.
    //
    initialLocation?: ILocation;

    //
    // Set to true to default the location of the map to the user's location (if they give permission).
    //
    defaultToUserLocation?: boolean;

    //
    // Event raised when the map is loaded.
    //
    onLoaded?: () => Promise<void>;

    //
    // Event raised when the map is clicked.
    //
    onClicked?: (location: ILocation) => Promise<void>;

    //
    // Options for loading the tile layer.
    //
    tileLayer: {
        urlTemplate: string;
        options: any;
    };

    //
    // Sets minimum and maximum zoom levels for the map.
    //
    zoom: {
        min: number;
        max: number;
    };
}

interface IMapState {
    //
    // Set to true when loading.
    //
    loading: boolean;
}

//
// Records a cached map.
// Means the map doesn't have to be reloaded.
//
interface IMapCacheEntry {
    //
    // Reference to the map API object.
    //
    map: L.Map;

    //
    // Cached map element.
    //
    mapElement: HTMLElement;
}

//
// Caches a map by cache key so it doesn't have to be reloaded.
//
interface IMapCache {
    [cacheKey: string]: IMapCacheEntry;
}

export default class Map extends React.Component<IMapProps, IMapState> {

    //
    // Reference to the map's contaning HTML element.
    //
    private mapContainerRef: React.RefObject<HTMLDivElement>;

    //
    // Cache for map reuse.
    //
    private static mapCache: IMapCache = {};

    //
    // Reference to the map API object, once loaded.
    //
    private map?: L.Map;

    //
    // The map object returned to the user, set only when the map is loaded.
    //
    private userMap?: L.Map;

    //
    // Cached map element.
    //
    private mapElement?: HTMLElement;

    //
    // Initial location to focus on.
    // If not specifed it defaults to users current location.
    //
    initialLocation?: ILocation;

    //
    // Gets the Leaflet map.
    //
    getMap(): L.Map | undefined {
        return this.userMap;
    }

    //
    // Gets the initial location, which may have been defaulted.
    //
    getInitialLocation(): ILocation | undefined {
        return this.initialLocation;
    }

    constructor(props: IMapProps) {
        super(props);

        this.state = {
            loading: true,
        };

        this.mapContainerRef = React.createRef<HTMLDivElement>();

        this.componentDidMount = asyncHandler(this, this.componentDidMount);
    }

    async componentDidMount(): Promise<void> {
        console.log("Loading map.");
        this.initialLocation = this.props.initialLocation;
        if (!this.initialLocation) {
            if (this.props.defaultToUserLocation) {
                try {
                    this.initialLocation = await this.getLocation();
                }
                catch (err) {
                    console.error(`Failed to get current location, using default location instead.`);
                    console.error(err && err.stack || err);
                    this.initialLocation = DEFAULT_LOCATION;
                }
            }
            else {
                this.initialLocation = DEFAULT_LOCATION;
            }
        }

        if (this.props.cacheKey) {
            const cacheEntry = Map.mapCache[this.props.cacheKey];
            if (cacheEntry) {
                //
                // Reuse previously cached map.
                //
                cacheEntry.mapElement.remove();
                this.mapElement = cacheEntry.mapElement;              
                this.mapContainerRef.current!.appendChild(this.mapElement);

                this.map = cacheEntry.map;
                this.map.setView([ this.initialLocation!.latitude, this.initialLocation!.longitude ], DEFAULT_ZOOM, { animate: false });

                this.setState({
                    loading: false,
                });

                this.userMap = this.map;

                if (this.props.onLoaded) {
                    console.log("Reused cached map.");
                    await this.props.onLoaded();
                }
                return;
            }
        }

        console.log("Creating new map.");

        //
        // Create a new map.
        //
        this.mapElement = document.createElement("div");
        this.mapElement.id = `map-${this.props.cacheKey ?? "uncached"}`
        this.mapElement.className ="flex-grow w-full";

        // Set images directory.
        L.Icon.Default.imagePath = "assets/leaflet/";

        this.map = L.map(this.mapElement, {
            center: [ this.initialLocation!.latitude, this.initialLocation!.longitude ],
            zoom: DEFAULT_ZOOM,
            zoomSnap: 0.1,
            zoomControl: false,
            minZoom: this.props.zoom.min,
            maxZoom: this.props.zoom.max,
        });

        this.map.on('click', (e: L.LeafletMouseEvent) => {
            console.log("Clicked map at " + e.latlng);

            if (this.props.onClicked) {
                handleAsync(() => this.props.onClicked!({
                    latitude: e.latlng.lat,
                    longitude: e.latlng.lng,
                }));
            }
        });

        console.log(`Initialising map with tile layer:`);
        console.log(this.props.tileLayer.urlTemplate);
        console.log(this.props.tileLayer.options);

        const tileLayer = L.tileLayer(
            this.props.tileLayer.urlTemplate, 
            this.props.tileLayer.options,
        );
        tileLayer.addTo(this.map);

        const onLoaded = () => {
            tileLayer.removeEventListener("load", onLoaded);

            //
            // This next part looks like a massive hack.
            // But I carefully constructed this code to hide the initial resize of the map.
            //
            setTimeout(() => { 
                // Causes the map to render.
                // https://stackoverflow.com/a/53883127/25868
                this.map!.invalidateSize(true); 

                requestAnimationFrame(() => {
                    setTimeout(() => {
                        this.setState({
                            loading: false, // Wait until after the resize before removing the loading screen.
                        });

                        this.userMap = this.map;

                        if (this.props.onLoaded) {
                            console.log("New map loaded.");
                            handleAsync(() => this.props.onLoaded!());
                        }
                        
                    }, 500);
                });
            }, 500);
        };

        tileLayer.addEventListener("load", onLoaded);

        this.mapContainerRef.current!.appendChild(this.mapElement);
    }

    componentWillUnmount() {

        if (this.map && this.mapElement && this.props.cacheKey) {
            // Save the map for later.
            Map.mapCache[this.props.cacheKey] = {
                map: this.map,
                mapElement: this.mapElement,
            };

            this.map = undefined;
            this.mapElement.remove();
            document.body.appendChild(this.mapElement);
            this.mapElement = undefined;
        }

        if (this.map) {
            this.map.remove();
            this.map = undefined;
        }

        if (this.mapElement) {
            this.mapElement.remove();
            this.mapElement = undefined;
        }
    }

    //
    // Gets the users current location.
    //
    private async getLocation(): Promise<ILocation> {
        const position = await Geolocation.getCurrentPosition();
        return {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
        };
    }

    render() {
        return (
            <div
                className="flex flex-col flex-grow w-full relative"
                >
                <div
                    className="flex flex-col flex-grow w-full"
                    ref={this.mapContainerRef}
                    />
                
                {(this.state.loading || this.props.loading)
                    && <div
                        className="absolute bg-white"
                        style={{
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0, 
                            zIndex: 5000, // Place it above the map.
                        }}
                        >
                        <div 
                            className="absolute"
                            style={{
                                left: "50%",
                                top: "50%",
                            }}
                            >
                            <div
                                className="relative"
                                style={{
                                    left: "-50%",
                                    top: "-50%",
                                }}
                                >
                                <IonSpinner 
                                    className="text-black"
                                    />
                            </div>
                        </div>                        
                    </div>
                }
            </div>
        );
   }
}

