//
// Renders a date picker.
//

import { InjectableClass, InjectProperty } from "@codecapers/fusion";
import React from "react";
import moment from "moment";
import DayPicker from "react-day-picker";
import { updateState } from "../utils/update-state";
import { IonButton, IonPicker } from "@ionic/react";
import { asyncHandler } from "../utils/async-handler";

const NUM_YEARS_TO_SHOW = 25;

export interface IDatePickerProps {

    //
    // The currently selected date.
    //
    selectedDate: Date;

    //
    // Event raised when the date has been updated.
    //
    onDateUpdated: (date: Date) => Promise<void>;
}

interface IDatePickerState {

    //
    // Currently selected month.
    //
    currentMonth: number;

    //
    // Currently selected year.
    //
    currentYear: number;

    //
    // Shows the month picker.
    //
    showMonthPicker: boolean;

    //
    // Shows the year picker.
    //
    showYearPicker: boolean;
}

const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

@InjectableClass()
export class DatePicker extends React.Component<IDatePickerProps, IDatePickerState> {

    constructor(props: IDatePickerProps) {
        super(props);

        this.state = {
            currentMonth: moment(this.props.selectedDate).month(),
            currentYear: moment(this.props.selectedDate).year(),
            showMonthPicker: false,
            showYearPicker: false,
        };

        this.onDateUpdated = asyncHandler(this, this.onDateUpdated);
        this.onMonthSelected = asyncHandler(this, this.onMonthSelected);
        this.onYearSelected = asyncHandler(this, this.onYearSelected);

    }

    //
    // Event raised when the date has been updated by the user.
    //
    private async onDateUpdated(date: Date): Promise<void> {
        await this.props.onDateUpdated(date);
    }

    //
    // A new month was selected.
    //
    private async onMonthSelected(newMonth: number): Promise<void> {
        await updateState(this, {
            currentMonth: newMonth,
            showMonthPicker: false,
        });
        const newDate = moment(this.props.selectedDate).set("month", newMonth).toDate();
        await this.props.onDateUpdated(newDate);
    }

    //
    // A new year was selected.
    //
    private async onYearSelected(newYear: number): Promise<void> {
        await updateState(this, {
            currentYear: newYear,
            showYearPicker: false,
        });
        const newDate = moment(this.props.selectedDate).set("year", newYear).toDate();
        await this.props.onDateUpdated(newDate);
    }

    render() {
        const LATEST_YEAR = moment().year();

        return (
            <>
                <DayPicker
                    showOutsideDays={true}
                    selectedDays={this.props.selectedDate}
                    onDayClick={this.onDateUpdated}
                    todayButton="Today"
                    captionElement={({ date, localeUtils }) => (
                        <div className="DayPicker-Caption">
                            <div className="flex flex-row items-center justify-center w-full">
                                <div>
                                    <IonButton
                                        fill="clear"
                                        size="small"
                                        onClick={() => this.setState({ showMonthPicker: true })}
                                        >
                                        {months[this.state.currentMonth]}
                                    </IonButton>
                                </div>
                                <div>/</div>
                                <div>
                                    <IonButton
                                        fill="clear"
                                        size="small"
                                        onClick={() => this.setState({ showYearPicker: true })}
                                        >
                                        {this.state.currentYear}
                                    </IonButton>
                                </div>
                            </div>
                        </div>
                    )}
                    />

                <IonPicker
                    isOpen={this.state.showMonthPicker}
                    columns={[
                        {
                            name: "Month",
                            selectedIndex: this.state.currentMonth,
                            options: months.map((month, index) => ({
                                text: month,
                                value: index,
                            })),
                        },
                    ]}
                    buttons={[
                        {
                            text: "Cancel",
                            role: "cancel",
                            handler: () => {
                                this.setState({
                                    showMonthPicker: false,
                                });
                            },
                        },
                        {
                            text: "OK",
                            handler: value => {
                                this.onMonthSelected(value.Month.value);
                            },
                        },
                    ]}
                    />

                 <IonPicker
                    isOpen={this.state.showYearPicker}
                    columns={[
                        {
                            name: "Year",
                            selectedIndex: this.state.currentYear,
                            options: Array.from({ length: NUM_YEARS_TO_SHOW }).map((value, index) => ({
                                text: `${moment().year() + 1 - NUM_YEARS_TO_SHOW + index}`,
                                value: moment().year() + 1 - NUM_YEARS_TO_SHOW + index,
                            })),
                        },
                    ]}
                    buttons={[
                        {
                            text: "Cancel",
                            role: "cancel",
                            handler: () => {
                                this.setState({
                                    showYearPicker: false,
                                });
                            },
                        },
                        {
                            text: "OK",
                            handler: value => {
                                this.onYearSelected(value.Year.value);
                            },
                        },
                    ]}
                    />
           </>
        );
    }
}