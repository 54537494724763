import { InjectableClass, InjectProperty } from "@codecapers/fusion";
import { IonButton, IonContent, IonInput, IonItem, IonLabel, IonList, IonPage, IonIcon } from "@ionic/react";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import { IAuthentication, IAuthentication_id } from "../services/authentication";
import { asyncHandler } from "../utils/async-handler";
import { updateState } from "../utils/update-state";
import { validateEmail } from "../utils/validate-email";
import { Link } from "react-router-dom";
import "./Login.scss";
import { INavigation, INavigation_id } from "../services/navigation";

export interface ILoginScreenProps extends RouteComponentProps<{}> {

}

export interface ILoginScreenState {
    validated: boolean;
    errorMessage?: string;
    working: boolean;
    emailAddress: string,
    password: string;
}

@InjectableClass()
export class LoginScreen extends React.Component<ILoginScreenProps, ILoginScreenState> {

    @InjectProperty(IAuthentication_id)
    authentication!: IAuthentication;

    @InjectProperty(INavigation_id)
    navigation!: INavigation;

    constructor(props: ILoginScreenProps) {
        super(props);

        const params = new URLSearchParams(this.props.location.search);
        const inputEmail = params.get("email");

        this.state = {
            validated: false,
            working: false,
            emailAddress: inputEmail && decodeURIComponent(inputEmail) || "",
            password: "",
        };

        this.onEmailInputChange = asyncHandler(this, this.onEmailInputChange);
        this.onPasswordInputChange = asyncHandler(this, this.onPasswordInputChange);
        this.onSignInClick = asyncHandler(this, this.onSignInClick);
    }

    private isValid(): boolean {
        return this.state.emailAddress.length > 0
            && this.state.password.length > 0
            && validateEmail(this.state.emailAddress);
    }

    private async validate(): Promise<void> {
        await updateState(this, {
            validated: this.isValid(),
        });
    }

    private async onEmailInputChange(value: string | null | undefined): Promise<void> {
        await updateState(this, { emailAddress: value });
        await this.validate();
    }

    private async onPasswordInputChange(value: string | null | undefined): Promise<void> {
        await updateState(this, { password: value });
        await this.validate();
    }

    async onSignInClick(): Promise<void> {
        try {
            await updateState(this, { working: true });
            const result = await this.authentication.authenticate(this.state.emailAddress.trim(), this.state.password);
            if (result.ok) {
                await updateState(this, {
                    working: false,
                });

                this.navigation.navigateRoot("/main/feed");
            }
            else {
                console.error("Error signing in:");
                console.error(result.errorMessage || "Error message not specified.");
                await updateState(this, {
                    working: false,
                    errorMessage: result.errorMessage || "An error occurred"
                });
            }
        }
        catch (err) {
            console.error("Error signing in:");
            console.error(err && err.stack || err);
            await updateState(this, {
                working: false,
                errorMessage: "An error occurred",
            });
        }
    }

    render() {
        return (
            <IonPage className="login">
                <IonContent fullscreen>
                    <div className="flex flex-col pl-4 pb-4 login-screen">
                        <div className="mt-16">
                            <img className="heading-logo" src="assets/logo/final logo_logo - colour.svg" />
                        </div>

                        {/* NOT IN MVP
                         <div className="flex flex-col w-full max-w-xs mt-32 pl-2 pr-2">
                            <div className="text-regular ml-1">
                                Login with
                            </div>

                            <div className="flex flex-row mt-1">
                                <IonButton
                                    className="facebook-button w-20 h-14"
                                    routerLink="/main/feed"
                                    routerDirection="root"
                                    >
                                    <IonIcon
                                        icon={logoFacebook}
                                        slot="icon-only"
                                        />
                                </IonButton>

                                <div className="flex-grow"></div>

                                <IonButton
                                    className="google-button w-20 h-14"
                                    routerLink="/main/feed"
                                    routerDirection="root"
                                    >
                                    <IonIcon
                                        icon={logoGoogle}
                                        slot="icon-only"
                                        />
                                </IonButton>

                                <div className="flex-grow" />

                                <IonButton
                                    className="twitter-button w-20 h-14"
                                    routerLink="/main/feed"
                                    routerDirection="root"
                                    >
                                    <IonIcon
                                        icon={logoTwitter}
                                        slot="icon-only"
                                        />
                                </IonButton>
                            </div>
                        </div> */}

                        <div className="flex flex-col w-full max-w-xs mt-16 pl-2 pr-2">
                            <div className="onboarding-heading">
							    <h1>Welcome back!</h1>
                            </div>

                            <IonList className="mt-4 w-full">
                                <IonItem>
                                    <IonLabel position="floating">Email</IonLabel>
                                    <IonInput
                                        name="email-input"
                                        id="email-input"
                                        type="email"
                                        value={this.state.emailAddress}
                                        onIonChange={e => this.onEmailInputChange(e.detail.value)}
                                        required
                                        autofocus
                                        disabled={this.state.working}
                                        />
                                </IonItem>

                                <IonItem>
                                    <IonLabel position="floating">Password</IonLabel>
                                    <IonInput
                                        name="password-input"
                                        id="password-input"
                                        type="password"
                                        value={this.state.password}
                                        onIonChange={e => this.onPasswordInputChange(e.detail.value)}
                                        required
                                        disabled={this.state.working}
                                        />
                                </IonItem>
                            </IonList>
                        </div>

                        {this.state.errorMessage
                            && <div className="flex flex-col w-full max-w-xs mt-8 pl-5 pr-5">
                                <IonItem
                                    color="danger"
                                    className="text-error"
                                    >
                                    <div className="p-2">
                                        {this.state.errorMessage}
                                    </div>
                                </IonItem>
                            </div>
                        }

                        <div className="flex flex-col w-full max-w-xs mt-16 pl-2 pr-2">
                            <IonButton
                                className="main-button h-14 w-full"
                                onClick={this.onSignInClick}
                                disabled={!this.state.validated || this.state.working}
                                >
                                <span className="ml-2">
                                    Login
                                </span>
                            </IonButton>


                            <div className="flex flex-col mt-2 items-center">
                                <Link className="forgot-password-link"
                                    to="/account/request-password-reset"
                                    >
                                    Forgot password?
                                </Link>
                            </div>

                            <div className="mt-8">
                            <IonButton
                                    className="alt-button h-14 w-full"
                                    routerLink="/account/signup"
                                    routerDirection="root"
                                    fill="clear"
                                    >
                                    {/* <IonIcon
                                        icon={mailOutline}
                                        slot="icon-only"
                                        /> */}
                                    <span className="ml-2">
                                        Sign up
                                    </span>
                                </IonButton>
                            </div>


                            <div className="flex flex-col items-center mt-1 text-xs text-gray-500">
                                By logging in you agree to our terms and conditions
                                <div className="flex flex-row">
                                    <a
                                        className="mr-2"
                                        href="https://www.theonrouteapp.com/privacy-policy"
                                        target="_blank"
                                        >
                                        Privacy
                                    </a>
                                    |
                                    <a
                                        className="ml-2"
                                        href="https://www.theonrouteapp.com/terms-and-conditions"
                                        target="_blank"
                                        >
                                        Legal
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
               </IonContent>
            </IonPage>
        );
    }
}