import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import React from "react";
import { handleAsync } from "../utils/async-handler";

export interface IModalProps {
    //
    // Set to true to show the modal.
    //
    show: boolean;

    //
    // Title of the modal.
    //
    title?: string;

    //
    // The header for the modal.
    //
    header?: JSX.Element;

    //
    // Event raised when the modal is closed.
    //
    onClose: () => Promise<void>;
}

interface IModalState {

}

export default class Modal extends React.Component<IModalProps, IModalState> {

    render() {
        return (
            <IonModal
                isOpen={this.props.show}
                onDidDismiss={() => {
                    handleAsync(() => this.props.onClose());
                }}
                swipeToClose={true}
                backdropDismiss={false}
                >
                <IonHeader>
                    <IonToolbar>
                        {this.props.title
                            && <IonTitle>
                                {this.props.title}
                            </IonTitle>
                        }

                        {this.props.header}
                            
                        <IonButtons slot="end">
                            <IonButton
                                fill="clear"
                                size="small"
                                onClick={() => {
                                    handleAsync(() => this.props.onClose());
                                }}
                                >
                                <IonIcon 
                                    className="text-sm text-gray-400"                                            
                                    icon={closeOutline} 
                                    slot="icon-only"
                                    />
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                    {this.props.children}
                </IonContent>
            </IonModal>
        )        
    }
}