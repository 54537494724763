import { InjectableClass, InjectProperty } from "@codecapers/fusion";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import { INotificationData } from "../model/model";
import { INotification, INotification_id } from "../services/notification";
import { ICachedList, IRepository, IRepository_id } from "../services/repository";
import { updateState } from "../utils/update-state";
import LoadableListScreen from "../components/LoadableListScreen";
import { Plugins } from "@capacitor/core";
import { IonItem } from "@ionic/react";
import { INavigation, INavigation_id } from "../services/navigation";
import { ProfilePhoto } from "../components/ProfilePhoto";
import moment from "moment";

//
// Number of posts in a "page".
//
const PAGE_SIZE = 10;

export interface INotificationsScreenProps extends RouteComponentProps<{}> {

}

export interface INotificationsScreenState {
    //
    // Notifications to display once loaded.
    //
    notifications?: ICachedList<INotificationData>;
}

@InjectableClass()
export default class NotificationsScreen extends React.Component<INotificationsScreenProps, INotificationsScreenState> {

    @InjectProperty(IRepository_id)
    post!: IRepository;

    @InjectProperty(INotification_id)
    notification!: INotification;

    @InjectProperty(INavigation_id)
    navigation!: INavigation;

    constructor(props: INotificationsScreenProps) {
        super(props);

        this.state = {
        };

        this.onBeforeRefresh = this.onBeforeRefresh.bind(this);
        this.onLoad = this.onLoad.bind(this);
        this.onLoadMore = this.onLoadMore.bind(this);
    }

    //
    // Event raised before the screen is refreshed.
    //
    async onBeforeRefresh(): Promise<void> {
        this.post.clearCache("notifications");
    }

    //
    // Does the initial load of posts.
    //
    async onLoad(): Promise<void> {
        const notifications = await this.post.loadNotifications(PAGE_SIZE, false);
        await updateState(this, {
            notifications: notifications,
        });

        console.log(notifications.data); //fio:

        setTimeout(() => { // Give it a moment for initial photos to load.
            Plugins.SplashScreen.hide(); // Make sure the splash screen is finally hidden.
        }, 1000);
    }

    async onLoadMore(): Promise<void> {
        const notifications = await this.post.loadNotifications(PAGE_SIZE, true);
        await updateState(this, {
            notifications: notifications,
        });
    }

    //
    // Renders a notification message.
    //
    private composeMessage(notification: INotificationData) {
        const screenName = notification.profile?.screenName || "Someone";
        return notification.template.replace("%u", screenName);
    }

    render() {
        return (
            <LoadableListScreen 
                className="notifications-screen"
                title="Notifications"
                numLoaded={this.state.notifications?.data.length ?? 0}
                hasMore={this.state.notifications?.haveMore ?? false}
                onBeforeRefresh={this.onBeforeRefresh}
                onLoad={this.onLoad}
                onLoadMore={this.onLoadMore}
                >
                {this.state.notifications?.data.map(notification => (
                    <IonItem
                        key={notification.id}
                        className="p-2"
                        detail={true}
                        onClick={() => {
                            if (notification.payload.postId) {
                                this.navigation.push(`/main/post/${notification.payload.postId}`);
                            }
                            else if (notification.payload.userId) {
                                this.navigation.push(`/main/profile/${notification.payload.userId}`);
                            }
                        }}
                        >
                        <div className="flex flex-row items-center">
                            {notification.profile
                                && <div className="flex flex-row items-center mr-2">
                                    <ProfilePhoto 
                                        size={30}
                                        photo={notification.profile.photo}
                                        />
                                </div>
                            }
                            <div className="flex flex-col">
                                <div className="text-regular">
                                    {this.composeMessage(notification)}
                                </div>                            
                                <div className="text-time mt-1 mb-2">
                                    {moment.utc(notification.dateAdded).fromNow()}
                                </div>
                            </div>
                        </div>
                    </IonItem>
                ))}
            </LoadableListScreen >
        );
    }
}
