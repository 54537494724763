//
// Service for creating notification messages.
//
import { InjectableSingleton, InjectProperty } from "@codecapers/fusion";
import { IEventSource, BasicEventHandler, EventSource } from "../utils/event-source";

export interface IToast {    
    //
    // Numeric ID of the toast.
    //
    id: number;

    //
    // The position of the toast.
    //
    position?: "bottom" | "middle" | "top";

    //
    // The message to display.
    //
    message: string;

    //
    // Amount of time to show the toast.
    //
    duration: number;

    //
    // Header to show in the toast.
    //
    header?: string;

    //
    // Color of the toast.
    //
    color?: "primary" | "secondary" | "tertiary" | "success" | "warning" | "danger" | "light" | "medium" | "dark";
}

export interface INotification {

    //
    // Get all current notifications.
    //
    getNotifications(): IToast[];

    //
    // Notify user of a successful operation. 
    //
    success(msg: string, duration?: number): Promise<void>;

    //
    // Notify user of an error.
    //
    error(err: any): Promise<void>;

    //
    // Call to dismiss a notification.
    //
    dismiss(toast: IToast): Promise<void>;

    //
    // Event raised when notifications are added or removed.
    //
    onUpdated: IEventSource<BasicEventHandler>;
}

export const INotification_id = "INotification";

//
// ID for the next toast.
//
let nextToastId = 0;

@InjectableSingleton(INotification_id)
export class Notification implements INotification {

    //
    // All current notifications.
    //
    private notifications: IToast[] = [];

    //
    // Get all current notifications.
    //
    getNotifications(): IToast[] {
        return this.notifications;
    }

    //
    // Notify user of a successful operation. 
    //
    async success(msg: string, duration?: number): Promise<void> {
        this.notifications.push({
            id: nextToastId++,
            message: msg,
            duration: duration !== undefined ? duration : 5000,
            header: "Success",
            color: "success",
            position: "bottom",
        });
        await this.onUpdated.raise();
    }

    //
    // Notify user of an error.
    //
    async error(err: any): Promise<void> {
        this.notifications.push({
            id: nextToastId++,
            message: err.message || err.toString(),
            duration: 500000,
            header: "Error",
            color: "danger",
            position: "middle",
        });
        await this.onUpdated.raise();
    }


    //
    // Call to dismiss a notification
    //
    async dismiss(toastToDismiss: IToast): Promise<void> {
        this.notifications = this.notifications.filter(toast => toast.id !== toastToDismiss.id);
        await this.onUpdated.raise();
    }

    //
    // Event raised when notifications are added or removed.
    //
    onUpdated: IEventSource<BasicEventHandler> = new EventSource<BasicEventHandler>();
}
