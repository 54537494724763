//
// Renders a comment.
//

import { InjectableClass, InjectProperty } from "@codecapers/fusion";
import { IonButton, IonIcon } from "@ionic/react";
import { flagOutline, trashOutline } from "ionicons/icons";
import React from "react";
import { ICommentData } from "../model/model";
import { IAuthentication, IAuthentication_id } from "../services/authentication";
import { INavigation, INavigation_id } from "../services/navigation";
import { IRepository, IRepository_id } from "../services/repository";
import { asyncHandler } from "../utils/async-handler";
import { ProfilePhoto } from "./ProfilePhoto";

export interface ICommentProps {

    //
    // The comment to be rendered.
    //
    comment: ICommentData;  

    //
    // Event raised when the comment is deleted.
    //
    onCommentDeleted: () => Promise<void>;
}

interface ICommentState {

}

@InjectableClass()
export class Comment extends React.Component<ICommentProps, ICommentState> {

    @InjectProperty(IAuthentication_id)
    authentication!: IAuthentication;

    @InjectProperty(INavigation_id)
    navigation!: INavigation;

    @InjectProperty(IRepository_id)
    post!: IRepository;
    
    constructor(props: ICommentProps) {
        super(props);

        this.state = {};

        this.onReportCommentClick = asyncHandler(this, this.onReportCommentClick);
        this.onDeleteCommentClick = asyncHandler(this, this.onDeleteCommentClick);
    }

    //
    // User has clicked the button to report the comment.
    //
    private async onReportCommentClick(): Promise<void> {
        await this.authentication.post("/api/report", { commentId: this.props.comment.id });
    }

    //
    // User clicks button to delete their own comment.
    //
    private async onDeleteCommentClick(): Promise<void> {
        await this.post.deleteComment(this.props.comment.id);
        await this.props.onCommentDeleted();
    }

    render() {
        return (
            <div className="flex flex-row items-start mt-4">
                <div 
                    className="mt-3"
                    onClick={() => {
                        this.navigation.push(`/main/profile/${this.props.comment.userId}`)
                    }}
                    >
                    <ProfilePhoto 
                        size={20}
                        photo={this.props.comment.profile?.photo}
                        />
                </div>

                <div className="ml-3 w-full">
                    <div className="bg-gray-100 p-3 rounded-md">
                        <div className="text-name">{this.props.comment.profile?.screenName}</div>
                        <div className="text-comment">{this.props.comment.text}</div>
                    </div>

                    <div className="flex flex-row items-center">

                        {/* NOT IN MVP
                        <IonButton 
                            fill="clear"
                            size="small"
                            className="flex flex-row items-center text-black text-count"
                            >
                            <div className="text-count">Reply</div>
                        </IonButton>

                        <div className="text-time">Updated {moment.utc(comment.dateCreated).fromNow()}</div>
                        <IonButton 
                            fill="clear"
                            size="small"
                            className="flex flex-row items-center text-black ml-3"
                            >
                            <div className="text-count mr-1">2</div>
                            <IonIcon icon={heartOutline} />
                        </IonButton> */}

                        <div className="flex-grow" />

                        {this.props.comment.own
                            && <IonButton 
                                fill="clear"
                                size="small"
                                className="text-red-600 mt-2"
                                onClick={this.onDeleteCommentClick}
                                >
                                <IonIcon 
                                    icon={trashOutline} 
                                    slot="icon-only"
                                    />
                            </IonButton>
                        }

                        {!this.props.comment.own
                            && <IonButton 
                                fill="clear"
                                size="small"
                                className="text-red-600 mt-2"
                                onClick={this.onReportCommentClick}
                                >
                                <IonIcon 
                                    icon={flagOutline} 
                                    slot="icon-only"
                                    />
                            </IonButton>
                        }
                    </div>
                </div>
            </div>
        );
    }
}