import { InjectableClass, InjectProperty } from "@codecapers/fusion";
import { IonButton, IonContent, IonPage, IonSpinner } from "@ionic/react";
import * as React from "react";
import { IAuthentication, IAuthentication_id } from "../services/authentication";
import { asyncHandler } from "../utils/async-handler";
import { updateState } from "../utils/update-state";
import "./Offline.scss";
import { Plugins } from "@capacitor/core";
import { INavigation, INavigation_id } from "../services/navigation";

export interface IOfflineScreenProps {
}

export interface IOfflineScreenState {
    //
    // Set to true when working.
    //
    working: boolean;
}

@InjectableClass()
export class OfflineScreen extends React.Component<IOfflineScreenProps, IOfflineScreenState> {

    @InjectProperty(IAuthentication_id)
    authentication!: IAuthentication;

    @InjectProperty(INavigation_id)
    navigation!: INavigation;

    constructor(props: IOfflineScreenProps) {
        super(props);

        this.state = {
            working: false,
        };

        this.componentDidMount = asyncHandler(this, this.componentDidMount);
        this.onCheckInternet = asyncHandler(this, this.onCheckInternet);
    }

    async componentDidMount() {
        Plugins.SplashScreen.hide();
    }

    private async onCheckInternet(): Promise<void> {
        try {
            await updateState(this, { working: true });

            const connnected = await this.authentication.checkConnectivity();
            await updateState(this, { working: false });

            if (connnected) {
                Plugins.SplashScreen.show();
                this.navigation.navigateRoot("/");
            }
        }
        catch (err) {
            await updateState(this, { working: false });
        }
    }

    render() {
        return (
            <IonPage className="offline-screen">
                <IonContent fullscreen>
                    <div className="flex flex-col items-center pb-4">
                        <div className="mt-32">
                            <img className="heading-logo" src="assets/logo/final logo_logo - colour.svg" />
                        </div>

                        <div className="flex flex-row items-center text-center mt-16 h-10 p-8">
                            This app requires internet access, but it looks like you are offline
                        </div>

                        <IonButton 
                            className="mt-8 main-button"
                            disabled={this.state.working}
                            onClick={this.onCheckInternet}
                            >
                            Check for internet
                            {this.state.working && 
                                <IonSpinner className="ml-4" />
                            }
                        </IonButton>


                    </div>
                </IonContent>
            </IonPage>
        );
    }
}