import { IImageResolution } from "../model/model";

export function getVideoResolution(file: File): Promise<IImageResolution> {
    return new Promise<IImageResolution>((resolve, reject) => {
        const videoPlayer = document.createElement('video');
        videoPlayer.setAttribute('src', URL.createObjectURL(file));
        videoPlayer.load();
        videoPlayer.addEventListener('error', err => {
            reject(err);
        });

        videoPlayer.addEventListener('loadedmetadata', () => {
            resolve({
                width: videoPlayer.videoWidth,
                height: videoPlayer.videoHeight,
            });
        });
    });
}

//
// Extracts a thumbnail from a video.
//
//https://stackoverflow.com/a/63474748/25868
export function extractVideoThumbnail(file: File, outputType: string, seekTo: number = 0.0): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        const videoPlayer = document.createElement('video');
        videoPlayer.setAttribute('src', URL.createObjectURL(file));
        videoPlayer.load();
        videoPlayer.addEventListener('error', err => {
            reject(err);
        });

        videoPlayer.addEventListener('loadedmetadata', () => {
            if (videoPlayer.duration < seekTo) {
                reject(new Error("Video is too short."));
                return;
            }

            // Delay seeking or else 'seeked' event won't fire on Safari.
            setTimeout(() => {
              videoPlayer.currentTime = seekTo;
            }, 200);

            videoPlayer.addEventListener('seeked', () => {                
                const canvas = document.createElement("canvas");
                canvas.width = videoPlayer.videoWidth;
                canvas.height = videoPlayer.videoHeight;
                const ctx = canvas.getContext("2d");
                ctx!.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
                resolve(canvas.toDataURL(outputType));
            });
        });
    });
}