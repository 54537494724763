//
// Edits a post.
//

import { InjectableClass, InjectProperty } from "@codecapers/fusion";
import { IonButton, IonIcon, IonImg, IonItem, IonLabel, IonPicker, IonTextarea, IonThumbnail } from "@ionic/react";
import { calendarOutline, cameraOutline, locationOutline, timeOutline } from "ionicons/icons";
import moment from "moment";
import React from "react";
import { ILocation, IMediaAsset } from "../model/model";
import { IAuthentication, IAuthentication_id, IMediaFile } from "../services/authentication";
import { asyncHandler } from "../utils/async-handler";
import { truncateText } from "../utils/text";
import { updateState } from "../utils/update-state";
import ActionModal from "./ActionModal";
import { DatePicker } from "./DatePicker";
import LocationPicker from "./LocationPicker";
import { MediaAssetView } from "./MediaAssetView";
import { MediaFilePreview } from "./MediaFilePreview";
import { MediaFileView } from "./MediaFileView";
import { Spinner } from "./Spinner";

export interface IPostEditorProps {
    //
    // Text of the post.
    //
    text: string;

    //
    // Set true when working is being done.
    //
    working: boolean;

    //
    // Assets attach to the existing post, if any.
    //
    mediaAssets: IMediaAsset[];

    //
    // Media files already added to this post.
    //
    mediaFiles: IMediaFile[];

    //
    // Current location of the post, if any.
    //
    location?: ILocation;

    // 
    // Current date of the post, if any.
    //
    date?: string;

    // 
    // Current time of the post, if any.
    //
    time?: string;

    //
    // Event raised when media assets are selected.
    //
    onSelectMediaAssets: (files: FileList) => Promise<void>;

    //
    // Event raised when a user chooses to delete an uploaded media asset.
    //
    onMediaAssetDeleted?(mediaAsset: IMediaAsset): Promise<void>;

    //
    // Event raised when a user chooses to delete a media file that isn't uploaded yet.
    //
    onMediaFileDeleted(mediaFile: IMediaFile): Promise<void>

    //
    // A field of the post has changed.
    //
    onFieldChanged(fieldName: string, value: string | null | undefined): Promise<void>;

    //
    // A new location has been selected.
    //
    onLocationSelected(location: ILocation): Promise<void>;

    //
    // A new date has been selected.
    //
    onDateSelected(date: string): Promise<void>;

    //
    // A new time has been selected.
    //
    onTimeSelected(time: string): Promise<void>;
}

interface IPostEditorState {

    //
    // Set to true to open the location picker modal.
    //
    showLocationPicker: boolean;

    //
    // Set to true to show the date picker for the post.
    //
    showDatePicker: boolean;

    //
    // Set to true to show the time picker for the post.
    //
    showTimePicker: boolean;

    //
    // Location for the post, before selection.
    //
    selectedLocation?: ILocation;

    //
    // New date selected by the user.
    // Format YYYY-MM-DD.
    //
    selectedDate?: string;

    //
    // The selected media asset.
    //
    selectedMediaAsset?: IMediaAsset;

    //
    // The selected media file.
    //
    selectedMediaFile?: IMediaFile;

    //
    // Hour of the post, selected by the user.
    // Defaults to now.
    //
    hour?: number;

    //
    // Minute of the post, selected by the user.
    // Defaults to now.
    //
    minute?: number;
}

@InjectableClass()
export class PostEditor extends React.Component<IPostEditorProps, IPostEditorState> {

    @InjectProperty(IAuthentication_id)
    authentication!: IAuthentication;

    //
    // Ref to the hidden file input, so it can be invoked programmatically.
    //
    private fileInputRef: React.RefObject<HTMLInputElement>;
    
    constructor(props: IPostEditorProps) {
        super(props);

        this.state = {
            showLocationPicker: false,
            showDatePicker: false,
            showTimePicker: false,
        };

        this.fileInputRef = React.createRef<HTMLInputElement>();

        this.onSelectMediaAssets = asyncHandler(this, this.onSelectMediaAssets);
        this.onLocationUpdated = asyncHandler(this, this.onLocationUpdated);
        this.onDateUpdated = asyncHandler(this, this.onDateUpdated);
        this.onLocationSelected = asyncHandler(this, this.onLocationSelected);
        this.onDateSelected = asyncHandler(this, this.onDateSelected);
        this.onTimeSelected = asyncHandler(this, this.onTimeSelected);
    }

    //
    // User has requested to upload various media files.
    //
    private async onSelectMediaAssets(files: FileList): Promise<void> {
        await this.props.onSelectMediaAssets(files);
    }

    //
    // Event raised when the user has selected a location.
    //
    private async onLocationUpdated(location: ILocation): Promise<void> {
        await updateState(this, { selectedLocation: location });
    }

    //
    // Event raised when the date has been updated by the user.
    //
    private async onDateUpdated(date: Date): Promise<void> {
        await updateState(this, {
            selectedDate: moment(date).format("YYYY-MM-DD"),
        });
    }

    //
    // Event raised when the user has selected a location.
    //
    private async onLocationSelected(): Promise<void> {
        if (this.state.selectedLocation) {
            await this.props.onLocationSelected(this.state.selectedLocation);
        }

        await updateState(this, {
            selectedLocation: undefined,
            showLocationPicker: false,
        });
    }

    //
    // Event raised when the user has selected a new date.
    //
    private async onDateSelected(): Promise<void> {
        if (this.state.selectedDate) {
            await this.props.onDateSelected(this.state.selectedDate);
        }
        await updateState(this, {
            selectedDate: undefined,
            showDatePicker: false,
        });
    }

    //
    // User has selected the time for the post.
    //
    private async onTimeSelected(timeValue: any): Promise<void> {

        const hour = timeValue.Hour.value;
        const minute = timeValue.Minute.value;

        const now = moment();
        const time = `${hour ?? now.hour()}:${minute ?? now.minute()}`;
        await this.props.onTimeSelected(time);

        await updateState(this, {
            hour: hour,
            minute: minute,
            showTimePicker: false,
        });
    }

    render() {
        console.log(this.props.time); //fio:
        return (
            <>
                <div className="flex flex-col p-4">
                            
                    <IonItem className="pl-3 pr-3 pb-2 relative" lines="none" >
                        <IonLabel position="floating">Post text</IonLabel>
                        <IonTextarea
                            value={this.props.text}
                            onIonChange={e => this.props.onFieldChanged("text", e.detail.value)}
                            required                   
                            disabled={this.props.working}
                            autoCapitalize="sentences"
                            autoGrow
                            />

                        {this.props.working
                            && <Spinner opaque={false} />
                        }
                    </IonItem>

                    <div className="flex flex-row pl-1 pr-1 pb-1">
                        <div>
                            <IonButton
                                fill="outline"
                                size="small"
                                className="post-additional-button"
                                onClick={() => {
                                    if (this.fileInputRef.current) {
                                        this.fileInputRef.current.click();
                                    }
                                }}
                                disabled={this.props.working}
                                >
                                <IonIcon icon={cameraOutline} slot="start" />
                                Photo
                            </IonButton>
                            <input 
                                ref={this.fileInputRef}
                                className="hidden"
                                type="file" 
                                multiple
                                accept="image/*,video/*"
                                onChange={evt => {
                                    if (evt.currentTarget && evt.currentTarget.files) {
                                        this.onSelectMediaAssets(evt.currentTarget.files);
                                    }
                                }}
                                />                            
                        </div>

                        <IonButton
                            fill="outline"
                            size="small"
                            className="post-additional-button ml-1"
                            onClick={() => {
                                this.setState({ showLocationPicker: true });
                            }}
                            disabled={this.props.working}
                            >
                            <IonIcon icon={locationOutline} slot="start" />
                            {this.props.location?.name
                                ? truncateText(this.props.location.name, 8)
                                : "Location"
                            }                            
                        </IonButton>

                        <IonButton
                            fill="outline"
                            size="small"
                            className="post-additional-button ml-1"
                            onClick={() => {
                                this.setState({ showDatePicker: true });
                            }}
                            disabled={this.props.working}
                            >
                            <IonIcon icon={calendarOutline} slot="start" />
                            {this.props.date
                                ? moment(this.props.date, "YYYY-MM-DD").toDate().toLocaleDateString()
                                : "Date"
                            }                            
                        </IonButton>

                        <IonButton
                            fill="outline"
                            size="small"
                            className="post-additional-button ml-1"
                            onClick={() => {
                                this.setState({ showTimePicker: true });
                            }}
                            disabled={this.props.working}
                            >
                            <IonIcon icon={timeOutline} slot="start" />
                            {this.props.time
                                ? moment(this.props.time, "HH:mm").format("HH:mm")
                                : "Time"
                            }                            
                        </IonButton>
                    </div>

                    {((this.props.mediaAssets.length > 0) || this.props.mediaFiles.length > 0)
                        && <div className="flex flex-row flex-wrap mt-1 pl-3 pr-3 pb-1">
                            {this.props.mediaAssets.map(mediaAsset => (
                                <IonThumbnail
                                    key={mediaAsset.id}
                                    className="ml-2 mb-2"
                                    style={{
                                        "--size": "2em",
                                    }}
                                    onClick={() => {
                                        this.setState({ selectedMediaAsset: mediaAsset });
                                    }}
                                    >
                                    <IonImg 
                                        src={this.authentication.makeAuthenticatedUrl(`/api/asset/get`, { id: `thumb/${mediaAsset.id}` })} 
                                        />
                                </IonThumbnail>
                            ))}

                            {this.props.mediaFiles.map(mediaFile => (
                                <IonThumbnail
                                    key={mediaFile.file.name}
                                    className="ml-2 mb-2"
                                    style={{
                                        "--size": "2em",
                                    }}
                                    onClick={() => {
                                        this.setState({ selectedMediaFile: mediaFile });
                                    }}
                                    >
                                    <MediaFilePreview 
                                        imageFile={mediaFile.file}
                                        />
                                </IonThumbnail>
                            ))}
                        </div>
                    }

                </div>

                <ActionModal
                    show={this.state.showLocationPicker}
                    title="Pick location"
                    enableOk={this.state.selectedLocation !== undefined}
                    okTitle="Select location"
                    onOk={this.onLocationSelected}
                    onClose={async () => {
                        await updateState(this, { showLocationPicker: false });
                    }}
                    >
                    <LocationPicker 
                        initialLocation={this.props.location}
                        onLocationUpdated={this.onLocationUpdated}
                        />
                </ActionModal>

                <ActionModal
                    show={this.state.showDatePicker}
                    title="Pick date"
                    enableOk={this.state.selectedDate !== undefined}
                    okTitle="Select date"
                    onOk={this.onDateSelected}
                    onClose={async () => {
                        await updateState(this, { showDatePicker: false });
                    }}
                    >
                    <DatePicker
                        selectedDate={moment(this.props.date, "YYYY-MM-DD").toDate()}
                        onDateUpdated={this.onDateUpdated}
                        />
                </ActionModal>

                <ActionModal
                    show={this.state.selectedMediaAsset !== undefined}
                    title="Photo"
                    enableOk={true}
                    okTitle="Remove photo"
                    onOk={async () => {
                        if (this.props.onMediaAssetDeleted) {
                            await this.props.onMediaAssetDeleted(this.state.selectedMediaAsset!);
                        }
                        await updateState(this, { selectedMediaAsset: undefined });
                    }}
                    onClose={async () => {
                        await updateState(this, { selectedMediaAsset: undefined });
                    }}
                    >
                    <div 
                        className="flex flex-col items-center justify-center h-full w-full"
                        >
                        <MediaAssetView
                            mediaAsset={this.state.selectedMediaAsset!}
                            />
                    </div>
                </ActionModal>

                <ActionModal
                    show={this.state.selectedMediaFile !== undefined}
                    title="Photo"
                    enableOk={true}
                    okTitle="Remove photo"
                    onOk={async () => {
                        await this.props.onMediaFileDeleted(this.state.selectedMediaFile!);
                        await updateState(this, { selectedMediaFile: undefined });
                    }}
                    onClose={async () => {
                        await updateState(this, { selectedMediaFile: undefined });
                    }}
                    >
                    {this.state.selectedMediaFile
                        && <MediaFileView
                            imageFile={this.state.selectedMediaFile.file}
                            />
                    }
                </ActionModal>

                <IonPicker
                    isOpen={this.state.showTimePicker}
                    columns={[
                        {
                            name: "Hour",
                            selectedIndex: this.state.hour ?? moment(this.props.time, "HH:mm").hour(),
                            options: Array.from({ length: 24 }).map((value, index) => ({
                                text: index > 9 ? index.toString() : `0${index.toString()}`,
                                value: index,
                            })),
                            columnWidth: "60px",
                        },
                        {
                            name: "Minute",
                            selectedIndex: this.state.minute ?? moment(this.props.time, "HH:mm").minute(),
                            options: Array.from({ length: 60 }).map((value, index) => ({
                                text: index > 9 ? index.toString() : `0${index.toString()}`,
                                value: index,
                            })),
                            columnWidth: "60px",
                        },
                    ]}
                    buttons={[
                        {
                            text: "Cancel",
                            role: "cancel",
                            handler: () => {
                                this.setState({
                                    showTimePicker: false,
                                });
                            },
                        },
                        {
                            text: "OK",
                            handler: value => {
                                this.onTimeSelected(value);
                            },
                        },
                    ]}
                    />                     
            </>
        );
    }
}