import { IonPage, IonContent, IonHeader, IonRefresher, IonRefresherContent, IonTitle, IonToolbar, IonButtons, IonBackButton, IonButton, IonIcon, ActionSheetButton, IonActionSheet } from "@ionic/react";
import { ellipsisHorizontal } from "ionicons/icons";
import * as React from "react";
import { runInThisContext } from "vm";
import {  asyncHandler, handleAsync } from "../utils/async-handler";

export interface IRefreshableScreenProps {

    //
    // Set to true to enable the back button.
    //
    enableBackButton?: boolean;

    //
    // Default back href for the back button.
    //
    defaultBackHref?: string;

    //
    // Enables the menu buttons.
    //
    enableMenuButton?: boolean;

    //
    // Sets the link for the menu button.
    //
    menuButtonLink?: string;

    //
    // Event raised when the menu button is clicked.
    //
    onMenuButtonClick?: () => void;

    //
    // Buttons to display in the action sheet menu.
    //
    menuButtons?: ActionSheetButton[];

    //
    // Class name for the page.
    //
    className?: string;

    //
    // The collapsed header for the page.
    //
    header?: JSX.Element;

    //
    // The collapsed header for the page.
    //
    title?: JSX.Element | string;

    //
    // The collapsible header for the page.
    //
    collapsibleHeader?: JSX.Element;

    //
    // An element to render outside the page's main content.
    //
    postContent?: JSX.Element;

    //
    // Event raised to refresh the page.
    //
    onRefresh: () => Promise<void>;
}

export interface IRefreshableScreenState {
    //
    // Set to true to show the menu.
    //
    showMenu: boolean;
}

export default class RefreshableScreen extends React.Component<IRefreshableScreenProps, IRefreshableScreenState> {

    constructor(props: IRefreshableScreenProps) {
        super(props);

        this.state = {
            showMenu: false,
        };

        this.onMenuButtonClick = asyncHandler(this, this.onMenuButtonClick);
    }

    //
    // Event raised when the user clicks the menu button.
    //
    private async onMenuButtonClick(): Promise<void> {

        if (this.props.menuButtons) {
            this.setState({ showMenu: true });
        }
        else if (this.props.onMenuButtonClick) {
            await this.props.onMenuButtonClick();
        }
    }

    render() {
        return (
            <IonPage className={this.props.className}>
                <IonHeader>
                    <IonToolbar>
                        {this.props.enableBackButton
                            && <IonButtons slot="start">
                                <IonBackButton
                                    className="text-black"
                                    defaultHref={this.props.defaultBackHref ?? "/main/feed"}
                                    text={""}
                                    />
                            </IonButtons>
                        }

                        {this.props.header}

                        {this.props.title
                            && <IonTitle className="pl-4">
                                {this.props.title}
                            </IonTitle>
                        }

                        {(this.props.enableMenuButton || this.props.menuButtons !== undefined)
                            && <IonButtons slot="end">
                                <IonButton
                                    fill="clear"
                                    size="small"
                                    routerLink={this.props.menuButtonLink}
                                    onClick={this.onMenuButtonClick}
                                    >
                                    <IonIcon
                                        className="text-sm text-black text-opacity-100"
                                        icon={ellipsisHorizontal}
                                        slot="icon-only"
                                        />
                                </IonButton>
                            </IonButtons>
                        }

                    </IonToolbar>
                </IonHeader>


                {/*
                scrollY must be enabled for "pull to refresh" to work.
                */}
                <IonContent fullscreen scrollY={true} scrollX={false}>
                    {this.props.collapsibleHeader
                        && <IonHeader collapse="condense">
                            <IonToolbar>
                                {this.props.collapsibleHeader}
                            </IonToolbar>
                        </IonHeader>
                    }

                    <IonRefresher
                        slot="fixed"
                        onIonRefresh={event => {
                            handleAsync(() => this.props.onRefresh()
                                .finally(() => {
                                    event.detail.complete();
                                })
                            );
                        }}
                        >
                        <IonRefresherContent
                            pullingIcon="lines"
                            refreshingSpinner="lines"
                            >
                        </IonRefresherContent>
                    </IonRefresher>

                    <div className="v-full h-full overflow-x-hidden overflow-y-scroll">
                        {this.props.children}
                    </div>

                </IonContent>

                {this.props.menuButtons
                    && <IonActionSheet
                        isOpen={this.state.showMenu}
                        onDidDismiss={() => {
                            this.setState({ showMenu: false });
                        }}
                        buttons={this.props.menuButtons}
                        />
                }

                {this.props.postContent}

            </IonPage>
        );
    }
}
