import { InjectableClass, InjectProperty } from "@codecapers/fusion";
import { IonButton, IonContent, IonPage } from "@ionic/react";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import { IAuthentication_id, IAuthentication } from "../services/authentication";
import { INotification, INotification_id } from "../services/notification";
import { asyncHandler } from "../utils/async-handler";
import { updateState } from "../utils/update-state";

export interface IVerifyScreenPropsParams {
    //
    // The email that confirmation was sent to.
    //
    emailAddress: string;
}

export interface IVerifyScreenProps extends RouteComponentProps<IVerifyScreenPropsParams> {
}

export interface IVerifyScreenState {
    working: boolean;

    //
    // The email address.
    email: string;
}

@InjectableClass()
export class VerifyScreen extends React.Component<IVerifyScreenProps, IVerifyScreenState> {

    @InjectProperty(IAuthentication_id)
    authentication!: IAuthentication;

    @InjectProperty(INotification_id)
    notifcations!: INotification;

    constructor(props: IVerifyScreenProps) {
        super(props);

        this.state = {
            working: false,
            email: decodeURIComponent(this.props.match.params.emailAddress),
        };

        this.onResendEmailClick = asyncHandler(this, this.onResendEmailClick);
    }

    private async onResendEmailClick(): Promise<void> {
        if (this.state.working) {
            return;
        }

        try {
            await updateState(this, { working: true });
            await this.authentication.resendConfirmationEmail(this.state.email);
            await updateState(this, { working: false });
            this.notifcations.success("The confirmation email was reset");
        }
        catch (err) {
            console.error("Error resending email:");
            console.error(err && err.stack || err);
            this.notifcations.error("An error occured resending the email, please try again");
        }
    }    

    render() {
        return (
            <IonPage className="verify">
                <IonContent fullscreen>
                    <div className="flex flex-col items-center">
                        <div className="mt-16">
                            <img className="balloon-logo" src="assets/logo/final logo_icon colour.svg" />
                        </div>

                        <div className="text-title mt-2">Thank-you</div>

                        <div className="flex flex-col w-full max-w-xs mt-16 pl-2 pr-2"> 

                            <div className="text-header text-center">Please verify your email</div>
                            <div className="text-message text-center mt-4">We've sent an email to {this.state.email}.</div>
                            <div className="text-message text-center mt-1">Please check your emails and complete the signup process.</div>
                        </div>

                        <div className="flex flex-col w-full max-w-xs mt-16 pl-2 pr-2">                         
                            <IonButton 
                                className="main-button h-14 w-full mt-20"
                                routerLink={`/account/login?email=${encodeURIComponent(this.state.email)}`}
                                routerDirection="root"
                                disabled={this.state.working}
                                >
                                <span className="ml-2">
                                    Next
                                </span>
                            </IonButton>
                        </div>

                        <div className="mt-8">
                            <div className="text-regular">Can't find the email? </div>
                            <a onClick={this.onResendEmailClick}>
                                Resend the confirmation email
                            </a>
                        </div>
                        
                    </div>
                </IonContent>
            </IonPage>
        );
    }
}