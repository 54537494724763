import { InjectableClass, InjectProperty } from "@codecapers/fusion";
import { IonAvatar, IonButton, IonIcon, IonImg, IonItem, IonThumbnail } from "@ionic/react";
import { cameraOutline, personCircleOutline } from "ionicons/icons";
import React from "react";
import { IMediaAsset } from "../model/model";
import { IAuthentication, IAuthentication_id, IMediaFile } from "../services/authentication";
import { asyncHandler } from "../utils/async-handler";
import { updateState } from "../utils/update-state";
import { MediaFilePreview } from "./MediaFilePreview";

export interface IPhotoSelectorProps {
    //
    // The profile picture, if there is already one.
    //
    photo?: IMediaAsset;

    //
    // Event raised when a file is selected.
    //
    onSelectMediaAsset(file: File): Promise<void>;
}

export interface IPhotoSelectorState {
    //
    // The local file that has been selected.
    //
    selectedFile?: IMediaFile;
}

@InjectableClass()
export class PhotoSelector extends React.Component<IPhotoSelectorProps, IPhotoSelectorState> {

    @InjectProperty(IAuthentication_id)
    authentication!: IAuthentication;

    //
    // Ref to the hidden file input, so it can be invoked programmatically.
    //
    private fileInputRef: React.RefObject<HTMLInputElement>;

    constructor(props: IPhotoSelectorProps) {
        super(props);

        this.state = {
        };

        this.fileInputRef = React.createRef<HTMLInputElement>();

        this.onSelectMediaAsset = asyncHandler(this, this.onSelectMediaAsset);
    }    
    
    //
    // User has requested to upload a media asset.
    //
    private async onSelectMediaAsset(file: File): Promise<void> {
        await updateState(this, { 
            selectedFile: {
                file: file,
            },
        });

        await this.props.onSelectMediaAsset(file);
    }

    render() {
        return (
            <>
                {(!this.props.photo && !this.state.selectedFile)
                    && <IonButton 
                        className="h-14 w-20 mt-2 text-black"
                        fill="clear"
                        onClick={() => {
                            if (this.fileInputRef.current) {
                                this.fileInputRef.current.click();
                            }
                        }}
                        >
                        <IonIcon
                            icon={cameraOutline} 
                            slot="icon-only"
                            />
                    </IonButton>
                }

                {(this.props.photo && !this.state.selectedFile)
                    && <IonButton 
                        className="h-14 w-20 mt-2 text-black"
                        fill="clear"
                        onClick={() => {
                            if (this.fileInputRef.current) {
                                this.fileInputRef.current.click();
                            }
                        }}
                        >
                        <IonImg 
                            src={this.authentication.makeAuthenticatedUrl(`/api/asset/get`, { id: `thumb/${this.props.photo.id}` })}
                            />
                    </IonButton>
                }

                {(this.state.selectedFile)
                    && <div className="mt-2 pl-3 pr-3 pb-1">
                        <IonThumbnail
                            key={this.state.selectedFile.file.name}
                            className="ml-2 mb-2"
                            style={{
                                "--size": "2em",
                            }}
                            onClick={() => {
                                if (this.fileInputRef.current) {
                                    this.fileInputRef.current.click();
                                }
                            }}
                            >
                            <MediaFilePreview 
                                imageFile={this.state.selectedFile.file}
                                />
                        </IonThumbnail>
                    </div>
                }

                <input 
                    ref={this.fileInputRef}
                    className="hidden"
                    type="file" 
                    multiple={false}
                    accept="image/*"
                    onChange={evt => {
                        if (evt.currentTarget.files && evt.currentTarget.files.length > 0 && evt.currentTarget.files[0]) {
                            this.onSelectMediaAsset(evt.currentTarget.files[0]);
                        }
                    }}
                    />                            

            </>          
        );
    }
}

