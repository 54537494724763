import { InjectableClass, InjectProperty } from "@codecapers/fusion";
import { IonButton, IonContent, IonInput, IonItem, IonLabel, IonList, IonPage } from "@ionic/react";
import * as React from "react";
import { IAuthentication, IAuthentication_id } from "../services/authentication";
import { asyncHandler } from "../utils/async-handler";
import { updateState } from "../utils/update-state";
import { validateEmail } from "../utils/validate-email";
import { Link } from "react-router-dom";
import { INavigation, INavigation_id } from "../services/navigation";
import "./Login.scss";


export interface IRequestPasswordResetScreenProps {

}

export interface IRequestPasswordResetScreenState {
    validated: boolean;
    errorMessage?: string;
    working: boolean;
    emailAddress: string;
}

@InjectableClass()
export class RequestPasswordResetScreen extends React.Component<IRequestPasswordResetScreenProps, IRequestPasswordResetScreenState> {

    @InjectProperty(IAuthentication_id)
    authentication!: IAuthentication;

    @InjectProperty(INavigation_id)
    navigation!: INavigation;

    constructor(props: IRequestPasswordResetScreenProps) {
        super(props);

        this.state = {
            validated: false,
            working: false,
            emailAddress: "",
        };

        this.onEmailInputChange = asyncHandler(this, this.onEmailInputChange);
        this.onSubmitClick = asyncHandler(this, this.onSubmitClick);
    }

    private isValid(): boolean {
        return this.state.emailAddress.length > 0
            && validateEmail(this.state.emailAddress);
    }

    private async validate(): Promise<void> {
        const isValid = this.isValid();
        await updateState(this, {
            errorMessage: undefined,
            validated: isValid,
        });
    }

    private async onEmailInputChange(value: string | null | undefined): Promise<void> {
        await updateState(this, { emailAddress: value });
        await this.validate();
    }

    private async onSubmitClick(): Promise<void> {
        try {
            await updateState(this, { working: true });
            await this.authentication.requestPasswordReset(
                this.state.emailAddress.trim(),
            );
            this.navigation.navigateRoot(`/account/reset-password/${this.state.emailAddress}`);
        }
        catch (err) {
            console.error("Error requesting password reset:");
            console.error(err && err.stack || err);
            await updateState(this, {
                working: false,
                errorMessage: "An error occurred"
            });
        }
    }

    render() {
        return (
            <IonPage className="signup">
                <IonContent fullscreen>
                    <div className="flex flex-col pl-4 pb-4 login-screen">
                        <div className="mt-16">
                            <img className="heading-logo" src="assets/logo/final logo_logo - colour.svg" />
                        </div>

                        <div className="flex flex-col w-full max-w-xs mt-16 mb-16 pl-2 pr-2">
                            {/* <div className="text-header text-center">Request a password reset</div> */}
                            <div className="onboarding-heading">
						        <h1>Forgot password</h1>
                            </div>
                            {/* <div className="text-message text-center mt-1">Enter the email address linked to your account</div> */}
                        </div>

                        <div className="flex flex-col w-full max-w-xs mt-32 pl-2 pr-2">
                            <IonList className="w-full">
                                <IonItem className="mt-1">
                                    <IonLabel position="floating">Email</IonLabel>
                                    <IonInput
                                        name="email-input"
                                        id="email-input"
                                        type="email"
                                        value={this.state.emailAddress}
                                        onIonChange={e => this.onEmailInputChange(e.detail.value)}
                                        required
                                        disabled={this.state.working}
                                        />
                                </IonItem>

                            </IonList>
                        </div>

                        {this.state.errorMessage
                            && <div className="flex flex-col w-full max-w-xs mt-8 pl-5 pr-5">
                                <IonItem
                                    color="danger"
                                    className="text-error"
                                    >
                                    <div className="p-2">
                                        {this.state.errorMessage}
                                    </div>
                                </IonItem>
                            </div>
                        }

                        <div className="flex flex-col w-full max-w-xs mt-4 pl-2 pr-2">
                            <IonButton
                                className="main-button h-14 w-full"
                                onClick={this.onSubmitClick}
                                disabled={!this.state.validated || this.state.working}
                                >
                                <span className="ml-2">
                                    Reset password
                                </span>
                            </IonButton>

                           <div className="mt-8">
                                <div className="text-regular">Don't need a password reset?</div>
                                <Link
                                    to="/"
                                    >
                                    Return to start
                                </Link>
                            </div>
                        </div>
                   </div>
                </IonContent>
            </IonPage>
        );
    }
}