//
// Views a full media asset.
//

import { InjectableClass, InjectProperty } from '@codecapers/fusion';
import { IonIcon, IonImg } from '@ionic/react';
import { play } from 'ionicons/icons';
import * as React from 'react';
import { IMediaAsset } from '../model/model';
import { IAuthentication, IAuthentication_id } from '../services/authentication';
import { OverlayIcon } from './OverlayIcon';

export interface IMediaAssetPreviewProps {
    //
    // The asset to load and preview.
    //
    mediaAsset: IMediaAsset;

    //
    // The height to render the image.
    //
    imageHeight: number;
}

interface IMediaAssetPreviewState {
}

@InjectableClass()
export class MediaAssetPreview extends React.Component<IMediaAssetPreviewProps, IMediaAssetPreviewState> {

    @InjectProperty(IAuthentication_id)
    authentication!: IAuthentication;

    render() {
        const assetUrl = this.authentication.makeAuthenticatedUrl(`/api/asset/get`, { id: `thumb/${this.props.mediaAsset.id}` });
        const isVideo = this.props.mediaAsset.contentType && this.props.mediaAsset.contentType.startsWith("video");
        const imageHeight = this.props.imageHeight;
        const aspectRatio = this.props.mediaAsset.resolution.width / this.props.mediaAsset.resolution.height;

        return (
            <>
                <IonImg
                    style={{
                        height: `${imageHeight}px`,
                        width: `${imageHeight * aspectRatio}px`,
                    }}
                    src={assetUrl}
                    />

                {isVideo
                    && <OverlayIcon>
                        <IonIcon
                            icon={play}
                            size="large"
                            className="text-white"
                            />
                    </OverlayIcon>
                }
            </>
        );
   }
}