import { InjectableClass, InjectProperty } from "@codecapers/fusion";
import { IonIcon, IonImg, IonThumbnail } from "@ionic/react";
import { helpOutline } from "ionicons/icons";
import React from "react";
import { IMediaAsset } from "../model/model";
import { IAuthentication, IAuthentication_id } from "../services/authentication";

export interface IThumbnailProps {
    //
    // The class for the thumbnail's root element.
    //
    className?: string;

    //
    // The profile picture, if there isone.
    //
    photo?: IMediaAsset;

    //
    // Event raised when the thumbnail is clicked.
    //
    onClick?: () => void;
}

export interface IThumbnailState {
}

@InjectableClass()
export class Thumbnail extends React.Component<IThumbnailProps, IThumbnailState> {

    @InjectProperty(IAuthentication_id)
    authentication!: IAuthentication;

    render() {
        let className = "flex-none";
        if (this.props.className) {
            className += " " + this.props.className;
        }
        return (
            <IonThumbnail 
                className={className}
                onClick={this.props.onClick}
                >
                {this.props.photo
                    ? <IonImg 
                        src={this.authentication.makeAuthenticatedUrl(`/api/asset/get`, { id: `thumb/${this.props.photo.id}` })}
                        />
                    : <IonIcon
                        icon={helpOutline}
                        style={{
                            fontSize: "45px",
                        }}
                        />
                }
            </IonThumbnail>
        );
    }
}

