//
// Views a full local media file.
//

import { IonSpinner } from '@ionic/react';
import * as React from 'react';
import { asyncHandler } from '../utils/async-handler';
import { loadFile } from '../utils/image';
import { updateState } from '../utils/update-state';

export interface IMediaFileViewProps {
    //
    // The file to load and preview.
    //
    imageFile: File;    
}

interface IMediaFileViewState {
    //
    // Base 64 encoded image data.
    //
    imageData?: string;
}

export class MediaFileView extends React.Component<IMediaFileViewProps, IMediaFileViewState> {

    constructor(props: IMediaFileViewProps) {
        super(props);

        this.state = {
        };

        this.componentDidMount = asyncHandler(this, this.componentDidMount);
    }

    async componentDidMount() {
        const imageData = await loadFile(this.props.imageFile);
        await updateState(this, { imageData: imageData });
    }

    render() {
        if (!this.state.imageData) {
            return <IonSpinner />;
        }
        
        return (
            <div className="flex flex-col h-full items-center justify-center">
                <img 
                    src={this.state.imageData}
                    />
            </div>
        );
   }
}