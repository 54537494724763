import { InjectableClass, InjectProperty } from "@codecapers/fusion";
import { IonButton, IonIcon } from "@ionic/react";
import { add, logOutOutline } from "ionicons/icons";
import * as React from "react";
import { asyncHandler, handleAsync } from "../utils/async-handler";
import { updateState } from "../utils/update-state";
import "./Profile.scss";
import { RouteComponentProps } from "react-router";
import { ICachedList, IRepository, IRepository_id } from "../services/repository";
import { IJourneyData, IProfileData } from "../model/model";
import { INotification, INotification_id } from "../services/notification";
import { IAuthentication, IAuthentication_id } from "../services/authentication";
import { ProfilePhoto } from "../components/ProfilePhoto";
import { Description } from "../components/Description";
import LoadableListScreen from "../components/LoadableListScreen";
import { Thumbnail } from "../components/Thumbnail";
import { INavigation, INavigation_id } from "../services/navigation";
import { JourneyCoverPhoto } from "../components/JourneyCoverPhoto";
const ReactCountryFlag = require("react-country-flag").default;

//
// Number of posts in a "page".
//
const PAGE_SIZE = 10;

//
// Parameters passed in by URL.
//
export interface IProfileScreenMatchProps {
    //
    // ID of the user we are viewing the profile for.
    // If undefined, we are viewing our own profile.
    //
    id?: string;
}
 
export interface IProfileScreenProps extends RouteComponentProps<IProfileScreenMatchProps> {
}

export interface IProfileScreenState {
    
    //
    // Set this to redirect to a different screen.
    //
    redirectTo?: string;

    //
    // Profile data (once loaded).
    //
    profile?: IProfileData;

    //
    // Journeys from this user, once loaded.
    //
    journeys?: ICachedList<IJourneyData>;
}

@InjectableClass()
export default class ProfileScreen extends React.Component<IProfileScreenProps, IProfileScreenState> {

    @InjectProperty(IRepository_id)
    post!: IRepository;

    @InjectProperty(INotification_id)
    notification!: INotification;

    @InjectProperty(IAuthentication_id)
    authentication!: IAuthentication;
    
    @InjectProperty(INavigation_id)
    navigation!: INavigation;
   
    constructor(props: IProfileScreenProps) {
        super(props);

        this.state = {
        };

        this.onBeforeRefresh = this.onBeforeRefresh.bind(this);
        this.onLoad = this.onLoad.bind(this);
        this.loadJourneys = this.loadJourneys.bind(this);
        this.onFollowClick = asyncHandler(this, this.onFollowClick);
        this.onUnfollowClick = asyncHandler(this, this.onUnfollowClick);
        this.onSignOutClicked = asyncHandler(this, this.onSignOutClicked);
    }

    //
    // Event raised before the screen is refreshed.
    //
    async onBeforeRefresh(): Promise<void> {
        this.post.clearCache(`profile.${this.props.match.params.id || "current"}`);
        if (this.state.profile) {
            this.post.clearCache(`journeys.${this.state.profile?.userId}`);
        }
    }

    //
    // Loads initial data.
    //
    private async onLoad() {
        const profile = await this.post.loadProfileForDisplay(this.props.match.params.id);
        await updateState(this, {
            profile: profile,
        });

        await this.loadJourneys(false);
    }

    //
    // Load journeys for the profile.
    //
    private async loadJourneys(loadMore: boolean) {
        if (!this.state.profile) {
            return;
        }

        const journeys = await this.post.loadJourneys(this.state.profile.userId, PAGE_SIZE, loadMore);
        await updateState(this, {
            journeys: journeys,
        });
    }

    //
    // User clicked to follow another user.
    //
    private async onFollowClick(): Promise<void> {
        try {
            await this.post.followUser(this.props.match.params.id!);
            await updateState(this, {
                profile: {
                    ...this.state.profile,
                    info: {
                        ...this.state.profile?.info,
                        following: true,
                        numFollowers: (this.state.profile?.info?.numFollowers || 0) + 1,
                    },
                },
            });
        }
        catch (err) {
            this.notification.error(`There was a problem following this profile.`);
            console.error("Error following profile:");
            console.error(err && err.stack || err);
        }
    }

    //
    // User clicked to unfollow another user.
    //
    private async onUnfollowClick(): Promise<void> {
        try {
            await this.post.unfollowUser(this.props.match.params.id!);
            await updateState(this, {
                profile: {
                    ...this.state.profile,
                    info: {
                        ...this.state.profile?.info,
                        following: false,
                        numFollowers: (this.state.profile?.info?.numFollowers || 0) - 1,
                    },
                },
            });
        }
        catch (err) {
            this.notification.error(`There was a problem unfollowing this profile.`);
            console.error("Error unfollowing profile:");
            console.error(err && err.stack || err);
        }
    }

    private async onSignOutClicked() {
        await this.authentication.signout();
        await updateState(this, { redirectTo: "/" });
    }

    render() {
        
        const menuButtons =
            this.state.profile?.own
            ? [ // Actions buttons for user's own profile.
                {
                    text: 'Edit profile',
                    handler: () => {
                        this.navigation.push("/main/edit-profile");
                    },
                }, 
                {
                    text: 'Privacy policy',
                    handler: () => {
                        this.navigation.open("https://www.theonrouteapp.com/privacy-policy");
                    },
                }, 
                {
                    text: 'Terms and conditions',
                    handler: () => {
                        this.navigation.open("https://www.theonrouteapp.com/terms-and-conditions");
                    },
                }, 
                {
                    text: 'Logout',
                    icon: logOutOutline,
                    cssClass: "mt-3",
                    handler: this.onSignOutClicked,
                }, 
            ]
            : [ // Action buttons when looking at another user's profile.
                {
                    text: 'Report offensive content',
                    handler: () => {
                        handleAsync(async () => {
                            await this.authentication.post("/api/report", { userId: this.state.profile?.userId });
                        });
                    },
                }, 
                {
                    text: 'Block',
                    handler: () => {
                        handleAsync(async () => {
                            await this.authentication.post("/api/block", { userId: this.state.profile?.userId });
                        });
                    },
                }, 
            ];

        return (
            <LoadableListScreen
                className="profile-screen"
                redirectTo={this.state.redirectTo}
                enableBackButton={this.props.match.params.id !== undefined}
                menuButtons={menuButtons}
                title={this.state.profile?.handle}
                numLoaded={this.state.journeys?.data.length ?? 0}
                hasMore={this.state.journeys?.haveMore ?? false}
                onBeforeRefresh={this.onBeforeRefresh}
                onLoad={this.onLoad}
                onLoadMore={() => this.loadJourneys(true)}
                >
                <div 
                    className="flex flex-col items-center w-full"
                    >

                    <div 
                        className="flex flew-row items-center w-full mt-4 pl-6 pr-6"
                        >
                        <ProfilePhoto 
                            size={100}
                            photo={this.state.profile?.photo}
                            />
                        <div className="flex flex-col items-center w-full pt-4 ml-5">
                            <div className="flex flex-row w-full mb-5">
                                <div className="flex flex-col items-center">
                                    <div className="text-count">{this.state.profile?.info?.numJourneys}</div>
                                    <div className="text-count-label">Journeys</div>
                                </div>                                   
                                <div className="flex-grow" />
                                <div className="flex flex-col items-center">
                                    <div className="text-count">{this.state.profile?.info?.numFollowers}</div>
                                    <div className="text-count-label">Followers</div>
                                </div>            
                                <div className="flex-grow" />
                                <div className="flex flex-col items-center">
                                    <div className="text-count">{this.state.profile?.info?.numFollowing}</div>
                                    <div className="text-count-label">Following</div>
                                </div>                                        
                            </div>
                            {this.state.profile?.own 
                                && <IonButton 
                                    className="edit-profile-button w-full"
                                    routerLink="/main/edit-profile"
                                    >
                                    Edit profile
                                </IonButton>
                            }
                            {this.state.profile?.info?.following === false
                                && <IonButton 
                                    className="follow-button w-full"
                                    onClick={this.onFollowClick}
                                    >
                                    <IonIcon icon={add} slot="start" />
                                    Follow
                                </IonButton>
                            }
                            {this.state.profile?.info?.following === true
                                && <IonButton 
                                    className="following-button w-full"
                                    onClick={this.onUnfollowClick}
                                    >
                                    Unfollow
                                </IonButton>
                            }
                        </div>
                    </div>
                </div>

                <div className="flex flex-col">
                
                    <div className="flex flex-col flex-none w-full pt-3 pl-6">

                        <div className="text-name">{this.state.profile?.screenName}</div>
                        <div className="text-handle"> @{this.state.profile?.handle}</div>

                        <div className="mt-2">
                            <Description
                                text={this.state.profile?.bio ?? ""}
                                />
                        </div>

                        {this.state.profile?.webLink
                            && <div className="mt-1">
                                <div 
                                    className="text-link"
                                    onClick={() => {
                                        window.open(`http://${this.state.profile?.webLink}`);
                                    }}
                                    >
                                    {this.state.profile?.webLink}
                                </div>
                            </div>
                        }

                        {/* NOT IN MVP
                        <div className="text-countries mt-2">
                            Countries Traveled
                        </div>
                        
                        <div className="flex flex-row mt-1"> 
                            <ReactCountryFlag
                                countryCode="US"
                                svg
                                style={{
                                    height: "1.15em",
                                }}
                                />

                            <ReactCountryFlag
                                countryCode="AF"
                                svg
                                style={{
                                    marginLeft: ".5em",
                                    height: "1.15em",
                                }}
                                />

                            <ReactCountryFlag
                                countryCode="AX"
                                svg
                                style={{
                                    marginLeft: ".5em",
                                    height: "1.15em",
                                }}
                                />

                            <ReactCountryFlag
                                countryCode="BJ"
                                svg
                                style={{
                                    marginLeft: ".5em",
                                    height: "1.15em",
                                }}
                                />
                        </div> */}


                    </div>
                        
                    <div className="flex flex-col w-full pt-2">
                        {/* NOT IN MVP
                        <div className="w-full h-0 page-border"></div>

                        <div className="flex flex-row flex-none items-center">

                            <div className="flex-grow" />

                            <IonButton 
                                fill="clear"
                                size="small"
                                className="flex flex-row items-center text-black ml-1"
                                >
                                <IonIcon icon={gridOutline} />
                            </IonButton>

                            <div className="flex-grow" />

                            <div className="h-full w-0 vertical-divider"></div>

                            <div className="flex-grow" />

                            <IonButton 
                                fill="clear"
                                size="small"
                                className="flex flex-row items-center text-black ml-1"
                                >
                                <IonIcon icon={bookmarkOutline} />
                            </IonButton>

                            <div className="flex-grow" />

                            <div className="h-full w-0 vertical-divider"></div>

                            <div className="flex-grow" />

                            <IonButton 
                                fill="clear"
                                size="small"
                                className="flex flex-row items-center text-black"
                                >
                                <IonIcon icon={mapOutline} slot="icon-only" />
                            </IonButton>                                                

                            <div className="flex-grow" />

                        </div> */}

                        <div className="mt-3 w-full h-0 page-border" />

                        <div className="grid grid-cols-2 w-full pt-4 pl-1 pr-1 pb-40 bg-gray-50">
                            {this.state.journeys?.data.map(journey => {
                                return (
                                    <div
                                        key={journey.id} 
                                        className="flex flex-col items-center overflow-hidden"
                                        onClick={() => this.navigation.push(`/main/journey/${journey.id}`)}
                                        >
                                        <div 
                                            className="flex flex-col items-center rounded-md bg-white m-2"
                                            >
                                            
                                            <JourneyCoverPhoto
                                                journey={journey} 
                                                />
        
                                            <div className="text-regular text-center p-1">
                                                {journey.title || "No title"}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </LoadableListScreen>
       );
    }
}
