import * as React from 'react';
import { asyncHandler } from '../utils/async-handler';
import { IonSearchbar } from '@ionic/react';
import { updateState } from '../utils/update-state';
import { debounce } from 'lodash';

//
// This is a seperate component to avoid rendering conflicts that cause the user
// to lose text when typing while search results are being rendered.
//

export interface ISearchBoxProps {
    //    
    // Event raised when search text has been updated.
    //
    onSearchTextUpdated: (searchText: string) => Promise<void>;
}

export interface ISearchBoxState {
    // 
    // Searched for location.
    //
    searchText: string;
}

export class SearchBox extends React.Component<ISearchBoxProps, ISearchBoxState> {

    //
    // Debounced handler for changing search text.
    //
    private onSearchTextUpdatedDebounced: Function;

    constructor(props: ISearchBoxProps) {
        super(props);

        this.state = {
            searchText: "",
        };

        this.onSearchTextChange = asyncHandler(this, this.onSearchTextChange);
        this.onSearchTextUpdatedDebounced = debounce(this.props.onSearchTextUpdated, 200);
    }

    shouldComponentUpdate(nextProps: ISearchBoxProps, nextState: ISearchBoxState) {
        return false; // Don't let this component rerender, otherwise we lose user text when search results are rendered in the parent.
    }

    //
    // Updates the search text.
    //
    private async onSearchTextChange(searchText: string): Promise<void> {
        await updateState(this, {
            searchText: searchText,
        });

        await this.onSearchTextUpdatedDebounced(searchText);
    }

    render() {
        console.log("Rendering search box with " + this.state.searchText);
        return (
            <IonSearchbar
                value={this.state.searchText}
                onIonChange={e => {
                    if (e.detail.value) {
                        this.onSearchTextChange(e.detail.value)
                    }
                }}
                />
       );
   }
}