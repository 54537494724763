import { InjectableClass, InjectProperty } from "@codecapers/fusion";
import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, withIonLifeCycle } from "@ionic/react";
import * as React from "react";
import { asyncHandler } from "../utils/async-handler";
import { updateState } from "../utils/update-state";
import { FullScreenSpinner } from "../components/FullScreenSpinner";
import { IRepository, IRepository_id } from "../services/repository";
import { INotification, INotification_id } from "../services/notification";
import { RouteComponentProps } from "react-router";
import { ILocation, IMediaAsset, IPostData, IPostDataUpdate } from "../model/model";
import { IAuthentication, IAuthentication_id, IMediaFile } from "../services/authentication";
import { PostEditor } from "../components/PostEditor";

//
// Parameters passed in by URL.
//
export interface IEditPostScreenMatchProps {
    //
    // ID of the post we are editing.
    //
    id: string;
}

export interface IEditPostScreenProps extends RouteComponentProps<IEditPostScreenMatchProps> {
}

export interface IEditPostScreenState {
    //
    // Set to true while loading.
    //
    loading: boolean;

    //
    // Set to true when the input is validated.
    //
    validated: boolean;

    //
    // Set true when working is being done.
    //
    working: boolean;

    //
    // Set to true when data has been changed.
    //
    changed: boolean;

    //
    // Data for the post, once loaded.
    //
    post?: IPostData;

    //
    // Text of the post.
    //
    text: string;

    //
    // Assets to be uploaded with the post.
    //
    mediaFiles: IMediaFile[];

    //
    // Location for the post, once selected.
    //
    location?: ILocation;

    //
    // Date of the post, selected by the user.
    // Format YYYY-MM-DD.
    //
    date?: string;

    //
    // Time of the post.
    //
    time?: string;
}

@InjectableClass()
class EditPostScreen extends React.Component<IEditPostScreenProps, IEditPostScreenState> {

    @InjectProperty(IRepository_id)
    post!: IRepository;

    @InjectProperty(INotification_id)
    notification!: INotification;

    @InjectProperty(IAuthentication_id)
    authentication!: IAuthentication;

    constructor(props: IEditPostScreenProps) {
        super(props);

        this.state = {
            loading: true,
            validated: false,
            working: false,
            changed: false,
            text: "",
            mediaFiles: [],
        };

        this.ionViewWillEnter = asyncHandler(this, this.ionViewWillEnter);
        this.onFieldChanged = asyncHandler(this, this.onFieldChanged);
        this.onSelectMediaAssets = asyncHandler(this, this.onSelectMediaAssets);
        this.onMediaAssetDeleted = this.onMediaAssetDeleted.bind(this);
        this.onMediaFileDeleted = this.onMediaFileDeleted.bind(this);
        this.onLocationSelected = asyncHandler(this, this.onLocationSelected);
        this.onDateSelected = asyncHandler(this, this.onDateSelected);
        this.onTimeSelected = asyncHandler(this, this.onTimeSelected);
        this.onSaveClicked = asyncHandler(this, this.onSaveClicked);
    }

    async ionViewWillEnter(): Promise<void> {
        try {
            await updateState(this, { 
                loading: true,
            });

            const post = await this.post.loadPost(this.props.match.params.id);
            await updateState(this, { 
                post: post,
                text: post.text,
                loading: false,
            });
        }
        catch (err) {
            console.log("Failed to load post:");
            console.error(err && err.stack || err);
            await this.notification.error("Failed to load post, please try again");
            await updateState(this, { 
                loading: false,                
            });
        }
    }    
        
    private async validate(): Promise<void> {
        await updateState(this, {
            validated: true, // Always valid.
            changed: this.state.text !== this.state.post?.text
                || this.state.location
                || this.state.mediaFiles.length > 0
                || this.state.date
                || this.state.time,
        }); 
    }

    //
    // Event raised when a field of the post has been changed.
    //
    private async onFieldChanged(fieldName: string, value: string | null | undefined): Promise<void> {
        const stateParams: any = {};
        stateParams[fieldName] = value;
        await updateState(this, stateParams);
        await this.validate();
    }

    //
    // User has requested to upload various media files.
    //
    private async onSelectMediaAssets(files: FileList): Promise<void> {

        const mediaFiles = this.state.mediaFiles.slice(); // Clone.
        for (let i = 0; i < files.length; ++i) {
            const file = files[i];
            mediaFiles.push({
                file: file,
            });
        }

        await updateState(this, { 
            mediaFiles: mediaFiles,
        });
        await this.validate();
    }

    //
    // Event raised when the user has selected a location.
    //
    private async onLocationSelected(location: ILocation): Promise<void> {
        await updateState(this, {
            location: location,
        });
        await this.validate();
    }

    //
    // Event raised when the user has selected a new date.
    //
    private async onDateSelected(date: string): Promise<void> {
        await updateState(this, {
            date: date,
        });
        await this.validate();
    }

    //
    // User has selected the time for the post.
    //
    private async onTimeSelected(time: string): Promise<void> {
        await updateState(this, {
            time: time,
        });
        await this.validate();
    }

    private async onSaveClicked(): Promise<void>  {
        if (!this.state.validated) {
            this.notification.error("You cannot save a post unless the requested information is provided.")
            return;
        }

        const fieldNames = [ "text", "date", "time", "location" ];
        const updateParams: IPostDataUpdate = {};
        let haveUpdate = false;

        for (const fieldName of fieldNames) {
            const fieldValue = (this.state as any)[fieldName];
            if (fieldValue !== (this.state.post as any)[fieldName]) {
                (updateParams as any)[fieldName] = fieldValue;
                haveUpdate = true;
            }
        }

        if (this.state.mediaFiles.length > 0) {
            haveUpdate = true;
        }

        if (!haveUpdate) {
            return;
        }

        try {
            await updateState(this, { working: true });

            if (this.state.mediaFiles.length > 0) {
                //
                // Ids for uploaded assets.
                //
                const mediaAssets = this.state.post!.mediaAssets.slice();
                updateParams.mediaAssets = mediaAssets;
                
                for (const mediaFile of this.state.mediaFiles) {
                    try {
                        const mediaAsset: IMediaAsset = await this.authentication.uploadMediaAsset(mediaFile);
                        mediaAssets.push(mediaAsset);
                    }
                    catch (err) {
                        //TODO: This can result in orphaned media assets. How to clean up?
                        this.notification.error(`Failed to upload media asset ${mediaFile.file.name}.`);
                        return;    
                    }
                }
            }

            await this.post.savePost(this.props.match.params.id, updateParams);
        
            await updateState(this, {
                changed: false,
                working: false,
            });
        }
        catch (err) {
            await updateState(this, { working: false });

            console.log("Error saving post:");
            console.error(err && err.stack || err);
            await this.notification.error("Failed to update post, please try again");
        }
    }

    //
    // Event raised when a user chooses to delete an uploaded media asset.
    //
    private async onMediaAssetDeleted(mediaAsset: IMediaAsset): Promise<void> {
        try {
            await updateState(this, { working: true });

            await this.authentication.deleteMediaAsset(mediaAsset);
    
            const remainingMediaAssets = (this.state.post?.mediaAssets ?? []).filter(asset => asset.id !== mediaAsset.id);
            const updateParams: IPostDataUpdate = {
                mediaAssets: remainingMediaAssets,                
            };
            await this.post.savePost(this.props.match.params.id, updateParams);        
            await updateState(this, { 
                working: false,
                post: {
                    ...this.state.post,
                    mediaAssets: remainingMediaAssets,
                },
            });
        }
        catch (err) {
            await updateState(this, { working: false });

            console.log("Error removing media asset:");
            console.error(err && err.stack || err);
            await this.notification.error("Failed to remove media asset, please try again");
        }
    }

    //
    // Event raised when a user chooses to delete a media file that isn't uploaded yet.
    //
    private async onMediaFileDeleted(mediaFile: IMediaFile): Promise<void> {
        await updateState(this, {
            mediaFiles: this.state.mediaFiles.filter(file => file !== mediaFile),
        });
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton 
                                className="text-black" 
                                defaultHref="/main/feed" 
                                text={""}
                                />
                        </IonButtons>

                        <IonTitle>
                            Edit post
                        </IonTitle>
                   </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                    <PostEditor
                        text={this.state.text ?? this.state.post?.text}
                        working={this.state.working}
                        mediaAssets={this.state.post?.mediaAssets ?? []}
                        mediaFiles={this.state.mediaFiles}
                        location={this.state.location ?? this.state.post?.location}
                        date={this.state.date ?? this.state.post?.date}
                        time={this.state.time ?? this.state.post?.time}
                        onSelectMediaAssets={this.onSelectMediaAssets}
                        onMediaAssetDeleted={this.onMediaAssetDeleted}
                        onMediaFileDeleted={this.onMediaFileDeleted}
                        onFieldChanged={this.onFieldChanged}
                        onLocationSelected={this.onLocationSelected}
                        onDateSelected={this.onDateSelected}
                        onTimeSelected={this.onTimeSelected}
                        />          

                    <div className="flex flex-col w-full mt-6 pl-8 pr-8"> 
                        <IonButton 
                            className="main-button h-14 w-full"
                            onClick={this.onSaveClicked}
                            disabled={!this.state.validated || this.state.working || !this.state.changed}
                            >
                            <span className="ml-2">
                                Save post
                            </span>
                        </IonButton>
                    </div>
                </IonContent>

                {(this.state.loading || this.state.working)
                    && <FullScreenSpinner opaque={this.state.loading} />                
                }

           </IonPage>
       );
    }
}

export default withIonLifeCycle(EditPostScreen);