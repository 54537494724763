import { InjectableClass, InjectProperty } from "@codecapers/fusion";
import { IonButton, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonList, IonPage, IonTextarea, IonTitle, IonToolbar } from "@ionic/react";
import * as React from "react";
import { asyncHandler } from "../utils/async-handler";
import { updateState } from "../utils/update-state";
import { FullScreenSpinner } from "../components/FullScreenSpinner";
import { IRepository, IRepository_id } from "../services/repository";
import { INotification, INotification_id } from "../services/notification";
import { PhotoSelector } from "../components/PhotoSelector";
import { IAuthentication, IAuthentication_id, IMediaFile } from "../services/authentication";
import { IMediaAsset } from "../model/model";
import { INavigation, INavigation_id } from "../services/navigation";

export interface INewJourneyScreenProps {
}

export interface INewJourneyScreenState {

    //
    // Set to true when the input is validated.
    //
    validated: boolean;

    //
    // Set true when working is being done.
    //
    working: boolean;

    //
    // Title of the journey.
    //
    journeyTitle: string;

    //
    // Description of the journey.
    //
    journeyDescription: string;

    //
    // Cover photo for the journey.
    //
    coverPhoto?: IMediaFile;
}

@InjectableClass()
export default class NewJourneyScreen extends React.Component<INewJourneyScreenProps, INewJourneyScreenState> {

    @InjectProperty(IRepository_id)
    post!: IRepository;

    @InjectProperty(INotification_id)
    notification!: INotification;

    @InjectProperty(IAuthentication_id)
    authentication!: IAuthentication;

    @InjectProperty(INavigation_id)
    navigation!: INavigation;

    constructor(props: INewJourneyScreenProps) {
        super(props);

        this.state = {
            validated: false,
            working: false,
            journeyTitle: "",
            journeyDescription: "",
        };

        this.onJourneyTitleChange = asyncHandler(this, this.onJourneyTitleChange);
        this.onJourneyDescriptionChange = asyncHandler(this, this.onJourneyDescriptionChange);
        this.onSelectMediaAsset = asyncHandler(this, this.onSelectMediaAsset);
        this.onStartJourneyClicked = asyncHandler(this, this.onStartJourneyClicked);
    }

    private async validate(): Promise<void> {
        const isValid = this.state.journeyTitle.length > 0 
            && this.state.journeyDescription.length > 0;
        await updateState(this, {
            validated: isValid,
        }); 
    }

    private async onJourneyTitleChange(value: string | null | undefined): Promise<void> {
        await updateState(this, { journeyTitle: value });
        await this.validate();
    }

    private async onJourneyDescriptionChange(value: string | null | undefined): Promise<void> {
        await updateState(this, { journeyDescription: value });
        await this.validate();
    }

    //
    // User has requested to upload a media asset.
    //
    private async onSelectMediaAsset(file: File): Promise<void> {
        await updateState(this, { 
            coverPhoto: {
                file: file,
            },
        });
    }

    private async onStartJourneyClicked(): Promise<void>  {
        if (!this.state.validated) {
            this.notification.error("You cannot start a journey unless the requested information is provided.")
            return;
        }

        let coverPhoto: IMediaAsset | undefined;

        if (this.state.coverPhoto) {
            coverPhoto = await this.authentication.uploadMediaAsset(this.state.coverPhoto);
        }

        await this.post.startNewJourney({
            title: this.state.journeyTitle,
            description: this.state.journeyDescription,
            coverPhoto: coverPhoto,
        });

        this.navigation.navigateRoot("/main/live-journey");
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>
                            New Journey
                        </IonTitle>
                   </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>

                    <div className="flex flex-col p-4">
                    
                        <IonList>
                            <IonItem>
                                <IonLabel position="floating">Journey title</IonLabel>
                                <IonInput
                                    value={this.state.journeyTitle}
                                    onIonChange={e => this.onJourneyTitleChange(e.detail.value)}
                                    required                   
                                    disabled={this.state.working}
                                    />
                            </IonItem>                            

                            <IonItem className="mt-1">
                                <IonLabel position="floating">Journey description</IonLabel>
                                <IonTextarea
                                    value={this.state.journeyDescription}
                                    onIonChange={e => this.onJourneyDescriptionChange(e.detail.value)}
                                    required                   
                                    autoGrow
                                    autoCapitalize="sentences"
                                    autofocus
                                    disabled={this.state.working}
                                    />
                            </IonItem>                            

                            <IonItem>
                                <IonLabel>Cover photo (optional)</IonLabel>

                                <PhotoSelector
                                    onSelectMediaAsset={this.onSelectMediaAsset}
                                    />
                            </IonItem>                                                        

                        </IonList>

                        <div className="flex flex-col w-full mt-16 pl-8 pr-8"> 
                            <IonButton 
                                className="main-button h-14 w-full"
                                onClick={this.onStartJourneyClicked}
                                disabled={!this.state.validated || this.state.working}
                                >
                                <span className="ml-2">
                                    Start journey
                                </span>
                            </IonButton>
                        </div>

                    </div>
                </IonContent>

                {this.state.working
                    && <FullScreenSpinner opaque={false} />                
                }

           </IonPage>
       );
    }
}
