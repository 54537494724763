import { InjectableClass, InjectProperty } from "@codecapers/fusion";
import { ActionSheetButton } from "@ionic/react";
import * as React from "react";
import { INotification, INotification_id } from "../services/notification";
import {  asyncHandler } from "../utils/async-handler";
import { updateState } from "../utils/update-state";
import LoadableScreen from "./LoadableScreen";
const InfiniteScroll = require("../lib/react-infinite-scroller/src/InfiniteScroll").default;

export interface ILoadableListScreenProps {

    //
    // Set this to redirect to a different screen.
    //
    redirectTo?: string;

    //
    // Number of items in the list that have been loaded so far.
    //
    numLoaded: number;

    //
    // Tells the infinite scroller if there's more data to be loaded.
    //
    hasMore: boolean;

    //
    // Set to true wnen working.
    //
    working?: boolean;

    //
    // Set to true to enable the back button.
    //
    enableBackButton?: boolean;

    //
    // Default back href for the back button.
    //
    defaultBackHref?: string;

    //
    // Enables the menu buttons.
    //
    enableMenuButton?: boolean;

    //
    // Sets the link for the menu button.
    //
    menuButtonLink?: string;

    //
    // Event raised when the menu button is clicked.
    //
    onMenuButtonClick?: () => void;

    //
    // Buttons to display in the action sheet menu.
    //
    menuButtons?: ActionSheetButton[];

    //
    // Class name for the page.
    //
    className?: string;

    //
    // The collapsed header for the page.
    //
    header?: JSX.Element;

    //
    // The collapsed header for the page.
    //
    title?: JSX.Element | string;

    //
    // The collapsible header for the page.
    //
    collapsibleHeader?: JSX.Element;

    //
    // An element to render outside the page's main content.
    //
    postContent?: JSX.Element;

    //
    // Event raise when the page will be entered.
    //
    onWillEnter?: () => Promise<void>;

    //
    // Event raised just before the screen is refreshed.
    //
    onBeforeRefresh?: () => Promise<void>;

    //
    // Event raised to load data for the screen.
    //
    onLoad: () => Promise<void>;

    //
    // Event raised to load more data for the screen.
    //
    onLoadMore: () => Promise<void>;
}

export interface ILoadableListScreenState {

    //
    // Set to true when this page is doing work.
    //
    working: boolean;
}

@InjectableClass()
export default class LoadableListScreen extends React.Component<ILoadableListScreenProps, ILoadableListScreenState> {

    @InjectProperty(INotification_id)
    notification!: INotification;

    constructor(props: ILoadableListScreenProps) {
        super(props);

        this.state = {
            working: false,
        };

        this.onLoadMore = asyncHandler(this, this.onLoadMore);
    }

    //
    // Event raised to load more data.
    //
    private async onLoadMore(): Promise<void> {
        try {
            await updateState(this, { working: true });
            await this.props.onLoadMore();
        }
        catch (err) {
            console.error("Failed to load more data:");
            console.error(err && err.stack || err);
            await this.notification.error("Failed to load more data, please try again.");
        }
        finally {
            await updateState(this, { working: false });
        }
    }


    render() {
        return (
            <LoadableScreen
                redirectTo={this.props.redirectTo}
                enableBackButton={this.props.enableBackButton}
                defaultBackHref={this.props.defaultBackHref}
                enableMenuButton={this.props.enableMenuButton}
                menuButtonLink={this.props.menuButtonLink}
                onMenuButtonClick={this.props.onMenuButtonClick}
                menuButtons={this.props.menuButtons}
                className={this.props.className}
                header={this.props.header}
                title={this.props.title}
                collapsibleHeader={this.props.collapsibleHeader}
                postContent={this.props.postContent}
                working={this.props.working || this.state.working}
                onWillEnter={this.props.onWillEnter}
                onBeforeRefresh={this.props.onBeforeRefresh}
                onLoad={this.props.onLoad}
                >
                <InfiniteScroll
                    pageStart={1}
                    initialLoad={false}
                    loadMore={this.onLoadMore}
                    hasMore={this.props.hasMore}
                    threshold={50}
                    useWindow={false}
                    >
                    {this.props.children || <div></div>}
                </InfiniteScroll>
            </LoadableScreen>
        );
    }
}
