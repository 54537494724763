import { InjectableClass, InjectProperty } from "@codecapers/fusion";
import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonList, IonPage, IonTextarea, IonTitle, IonToolbar, withIonLifeCycle } from "@ionic/react";
import * as React from "react";
import { asyncHandler } from "../utils/async-handler";
import { updateState } from "../utils/update-state";
import { FullScreenSpinner } from "../components/FullScreenSpinner";
import { IRepository, IRepository_id } from "../services/repository";
import { INotification, INotification_id } from "../services/notification";
import { RouteComponentProps } from "react-router";
import { PhotoSelector } from "../components/PhotoSelector";
import { IAuthentication, IAuthentication_id, IMediaFile } from "../services/authentication";
import { IJourneyData, IJourneyDataUpdate } from "../model/model";

//
// Parameters passed in by URL.
//
export interface IEditJourneyScreenMatchProps {
    //
    // ID of the journey we are editing.
    //
    id: string;
}

export interface IEditJourneyScreenProps extends RouteComponentProps<IEditJourneyScreenMatchProps> {
}

export interface IEditJourneyScreenState {
    //
    // Set to true while loading.
    //
    loading: boolean;

    //
    // Set to true when the input is validated.
    //
    validated: boolean;

    //
    // Set true when working is being done.
    //
    working: boolean;

    //
    // Set to true when data has been changed.
    //
    changed: boolean;

    //
    // Data for the journey, once loaded.
    //
    journey?: IJourneyData;

    //
    // Title of the journey.
    //
    title: string;

    //
    // Description of the journey.
    //
    description: string;

    //
    // The cover photo the new selected.
    //
    selectedCoverPhoto?: IMediaFile;

}

@InjectableClass()
class EditJourneyScreen extends React.Component<IEditJourneyScreenProps, IEditJourneyScreenState> {

    @InjectProperty(IRepository_id)
    post!: IRepository;

    @InjectProperty(INotification_id)
    notification!: INotification;

    @InjectProperty(IAuthentication_id)
    authentication!: IAuthentication;

    constructor(props: IEditJourneyScreenProps) {
        super(props);

        this.state = {
            loading: true,
            validated: false,
            working: false,
            changed: false,
            title: "",
            description: "",
        };

        this.ionViewWillEnter = asyncHandler(this, this.ionViewWillEnter);
        this.onFieldChange = asyncHandler(this, this.onFieldChange);
        this.onSelectMediaAsset = asyncHandler(this, this.onSelectMediaAsset);
        this.onSaveClicked = asyncHandler(this, this.onSaveClicked);
    }

    async ionViewWillEnter(): Promise<void> {
        try {
            await updateState(this, { 
                loading: true,
            });

            const journey = await this.post.loadJourney(this.props.match.params.id);
            await updateState(this, { 
                journey: journey,
                title: journey.title,
                description: journey.description,
                loading: false,
            });
        }
        catch (err) {
            console.log("Failed to load journey:");
            console.error(err && err.stack || err);
            await this.notification.error("Failed to load journey, please try again");
            await updateState(this, { 
                loading: false,                
            });
        }
    }    
        
    private async validate(): Promise<void> {
        const isValid = this.state.title.length > 0 
            && this.state.description.length > 0;
        await updateState(this, {
            validated: isValid,
            changed: this.state.title !== this.state.journey?.title
                || this.state.description !== this.state.journey?.description
                || this.state.selectedCoverPhoto,
        }); 
    }

    private async onFieldChange(fieldName: string, value: string | null | undefined): Promise<void> {
        const stateParams: any = {};
        stateParams[fieldName] = value;
        await updateState(this, stateParams);
        await this.validate();
    }

    //
    // User has requested to upload a media asset.
    //
    private async onSelectMediaAsset(file: File): Promise<void> {
        await updateState(this, { 
            selectedCoverPhoto: {
                file: file,
            },
        });
        await this.validate();
    }

    private async onSaveClicked(): Promise<void>  {
        if (!this.state.validated) {
            this.notification.error("You cannot save a journey unless the requested information is provided.")
            return;
        }

        const fieldNames = [ "title", "description" ];
        const updateParams: IJourneyDataUpdate = {};
        let haveUpdate = false;

        for (const fieldName of fieldNames) {
            const fieldValue = (this.state as any)[fieldName];
            if (fieldValue !== (this.state.journey as any)[fieldName]) {
                (updateParams as any)[fieldName] = fieldValue;
                haveUpdate = true;
            }
        }

        if (!haveUpdate && !this.state.selectedCoverPhoto) {
            return;
        }

        try {
            await updateState(this, { working: true });

            if (this.state.selectedCoverPhoto) {
                try {
                    updateParams.coverPhoto = await this.authentication.uploadMediaAsset(this.state.selectedCoverPhoto);
                }
                catch (err) {
                    this.notification.error(`Failed to upload photo ${this.state.selectedCoverPhoto.file.name}.`);
                    return;    
                }
            }

            await this.post.saveJourney(this.props.match.params.id, updateParams);
        
            await updateState(this, {
                changed: false,
                working: false,
            });
        }
        catch (err) {
            console.log("Error saving journey:");
            console.error(err && err.stack || err);
            await this.notification.error("Failed to update journey, please try again");
        }
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton 
                                className="text-black" 
                                defaultHref="/main/feed" 
                                text={""}
                                />
                        </IonButtons>

                        <IonTitle>
                            Edit journey
                        </IonTitle>
                   </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                    <div className="flex flex-col p-4">
                    
                        <IonList>
                            <IonItem className="mt-1">
                                <IonLabel position="floating">Journey title</IonLabel>
                                <IonInput
                                    value={this.state.title}
                                    onIonChange={e => this.onFieldChange("title", e.detail.value)}
                                    required                   
                                    disabled={this.state.working}
                                    />
                            </IonItem>                            

                            <IonItem className="mt-1">
                                <IonLabel position="floating">Journey description</IonLabel>
                                <IonTextarea
                                    value={this.state.description}
                                    onIonChange={e => this.onFieldChange("description", e.detail.value)}
                                    required                   
                                    autoGrow
                                    autoCapitalize="sentences"
                                    autofocus
                                    disabled={this.state.working}
                                    />
                            </IonItem>                            

                            <IonItem>
                                <IonLabel>Cover photo (optional)</IonLabel>

                                <PhotoSelector
                                    photo={this.state.journey?.coverPhoto}
                                    onSelectMediaAsset={this.onSelectMediaAsset}
                                    />
                            </IonItem>                                                        

                        </IonList>

                        <div className="flex flex-col w-full mt-16 pl-8 pr-8"> 
                            <IonButton 
                                className="main-button h-14 w-full"
                                onClick={this.onSaveClicked}
                                disabled={!this.state.validated || this.state.working || !this.state.changed}
                                >
                                <span className="ml-2">
                                    Save journey
                                </span>
                            </IonButton>
                        </div>

                    </div>
                </IonContent>

                {(this.state.loading || this.state.working)
                    && <FullScreenSpinner opaque={this.state.loading} />                
                }

           </IonPage>
       );
    }
}

export default withIonLifeCycle(EditJourneyScreen);