import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, withIonLifeCycle } from "@ionic/react";
import * as React from "react";
import { asyncHandler } from "../utils/async-handler";
import LocationPicker from "../components/LocationPicker";

export interface ILocationPickerTestScreenProps {
}

export interface ILocationPickerTestScreenState {
}

class LocationPickerTestScreen extends React.Component<ILocationPickerTestScreenProps, ILocationPickerTestScreenState> {

    constructor(props: ILocationPickerTestScreenProps) {
        super(props);

        this.state = {
            loading: true,
            redirectToMain: false,
        };
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle className="text-title">
                            Location picker test
                        </IonTitle>

                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                    <div className="h-full w-full flex flex-col">
                        <LocationPicker
                            initialLocation={{
                                latitude: 40.66402,
                                longitude: -74.10477,
                            }}
                            onLocationUpdated={evt => {
                                console.log("Location updated:");
                                console.log(evt);
                            }}
                            />
                    </div>

                </IonContent>

           </IonPage>
       );
    }
}

export default withIonLifeCycle(LocationPickerTestScreen);