import { InjectableClass, InjectProperty } from "@codecapers/fusion";
import { IonContent, IonHeader, IonPage, IonToolbar, withIonLifeCycle } from "@ionic/react";
import * as React from "react";
import { EditProfile } from "../components/EditProfile";
import { FullScreenSpinner } from "../components/FullScreenSpinner";
import { IRepository, IRepository_id } from "../services/repository";
import { asyncHandler } from "../utils/async-handler";
import { updateState } from "../utils/update-state";
import { Plugins } from "@capacitor/core";
import "./Setup.scss";
import { INavigation, INavigation_id } from "../services/navigation";

export interface ISetupScreenProps {

}

export interface ISetupScreenState {
    //
    // Set to true when data in the page is loading.
    //
    loading: boolean;

    //
    // Set to true when the backend is working.
    //
    working: boolean;
}

@InjectableClass()
class SetupScreen extends React.Component<ISetupScreenProps, ISetupScreenState> {

    @InjectProperty(IRepository_id)
    post!: IRepository;
    
    @InjectProperty(INavigation_id)
    navigation!: INavigation;

    constructor(props: ISetupScreenProps) {
        super(props);

        this.state = {
            loading: true,
            working: false,
        };

        this.ionViewWillEnter = asyncHandler(this, this.ionViewWillEnter);
    }

    async ionViewWillEnter() {

        Plugins.SplashScreen.show();

        await updateState(this, {
            loading: true,
        });

        try {
            const profile = await this.post.loadProfileForEdit();
            if (profile && profile.screenName.length > 0) {
                // User already has a profile.
                // Redirect them to the main screen.
                this.navigation.navigateRoot("/main/feed");
            }
            else {
                Plugins.SplashScreen.hide();
            }
        }
        finally {
            await updateState(this, {
                loading: false,
            });
        }       
    }


    render() {
        return (
            <IonPage className="setup">
                <IonHeader>
                    <IonToolbar>
                        <div className="flex flex-col items-center w-full pt-4">
                            <img className="logo" src="assets/logo/final logo_icon colour.svg" />
                            <div className="text-title text-center mt-2">
                                Welcome to your new account!
                            </div>
                            <div className="text-title text-center mt-1">
                                Let's set you up
                            </div>
                            <div className="text-xs mt-2 mb-3">
                                Note: You can edit these details later in your profile
                            </div>
                        </div>
                    </IonToolbar>
                </IonHeader>           

                <IonContent fullscreen>
                    <div className="flex flex-col items-center w-full p-4">
                        <EditProfile
                            saveButtonTitle="Next"
                            onSavingProfile={async () => {
                                await updateState(this, {
                                    working: true,
                                });
                            }}
                            onProfileSaved={async () => {
                                await updateState(this, { 
                                    working: false,
                                });
                                this.navigation.navigateRoot("/main/feed");
                            }}
                            onSaveFailed={async () => {
                                await updateState(this, {
                                    working: false,
                                });
                            }}
                            />                        
                    </div>
                </IonContent>

                {(this.state.loading || this.state.working)
                    && <FullScreenSpinner 
                        opaque={this.state.loading}
                        />                
                }

            </IonPage>

            // Old setup screen.
            // <IonPage className="setup">
            //     <IonContent fullscreen>
            //         <div className="flex flex-col h-full items-center pb-8">
            //             <div className="mt-16">
            //                 <img className="logo" src="assets/logo/final logo_icon colour.svg" />
            //             </div>
            //             <div className="text-title text-center mt-2">Welcome to your new account! Let's set you up</div>

            //             <div className="flex-grow"></div>

            //             <div className="flex flex-col w-full max-w-xs pl-5 pr-5"> 
            //                 <IonList className="w-full">
            //                     <IonItem lines="none">
            //                         <IonLabel>Upload profile photo</IonLabel>
            //                         <IonButton 
            //                             className="h-14 w-20 mt-2"
            //                             color="light"
            //                             >
            //                             <IonIcon 
            //                                 icon={camera} 
            //                                 slot="icon-only"
            //                                 />
            //                         </IonButton>
            //                     </IonItem>                                                        

            //                     <IonItem className="mt-8">
            //                         <IonLabel position="floating">Profile description (optional)</IonLabel>
            //                         <IonInput></IonInput>
            //                     </IonItem>                                                        

            //                     <IonItem className="mt-8">
            //                         <IonLabel position="floating">Website (optional)</IonLabel>
            //                         <IonInput></IonInput>
            //                     </IonItem>                                                        

            //                     <IonItem className="mt-8">
            //                         <IonLabel position="floating">What countries have you visited? (optional)</IonLabel>
            //                         <IonInput></IonInput>
            //                     </IonItem>                                                        

            //                 </IonList>
            //             </div>

            //             <div className="flex-grow"></div>

            //             <div className="flex flex-col w-full max-w-xs pl-5 pr-5">
            //                 <div className="flex flex-row items-center mt-6">
            //                     <div>1</div>
            //                     <div className="ml-3 text-gray-400">2</div>
            //                     <div className="flex-grow" />
            //                     <IonButton 
            //                         className="next-button h-14 w-32"
            //                         routerLink="/account/find-journeys"
            //                         routerDirection="none"
            //                         >
            //                         <span className="ml-2">
            //                             Next
            //                         </span>
            //                     </IonButton>
            //                 </div>
            //             </div>
            //         </div>
            //     </IonContent>
            // </IonPage>
        );
    }
}

export default withIonLifeCycle(SetupScreen);