import * as React from "react";
import { truncateText } from "../utils/text";

const MAX_DESCRIPTION_LENGTH = 90;

export interface IDescriptionProps {
    //
    // Text for the description.
    //
    text: string;
}


export interface IDescriptionState {
    //
    // Set to true once "Read more" has been clicked.
    //
    readMore: boolean;
}

export class Description extends React.Component<IDescriptionProps, IDescriptionState> {
    
    constructor(props: IDescriptionProps) {
        super(props);

        this.state = {
            readMore: false,
        }
    }

    render() {
        const maxLength = MAX_DESCRIPTION_LENGTH;
        const doTruncate = !this.state.readMore && this.props.text.length > maxLength;
        const text = doTruncate 
            ? truncateText(this.props.text, maxLength)
            : this.props.text;

        return (
            <div className="text-description">
                <span>{text}</span>
                {doTruncate
                    && <span
                        className="ml-1 text-read-more"
                        onClick={() => {
                            this.setState({ readMore: true });
                        }}
                        >
                        Read more
                    </span>
                }

            </div>
        );
    }

}