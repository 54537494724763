//
// Previews a local media files.
//

import { IonSpinner } from '@ionic/react';
import * as React from 'react';
import { asyncHandler } from '../utils/async-handler';
import { resizeImageData, resizeImageFile } from '../utils/image';
import { updateState } from '../utils/update-state';
import { extractVideoThumbnail } from '../utils/video';

export interface IMediaFilePreviewProps {
    //
    // The file to load and preview.
    //
    imageFile: File;    
}

interface IMediaFilePreviewState {
    //
    // Base 64 encoded image data.
    //
    imageData?: string;
}

export class MediaFilePreview extends React.Component<IMediaFilePreviewProps, IMediaFilePreviewState> {

    constructor(props: IMediaFilePreviewProps) {
        super(props);

        this.state = {
        };

        this.componentDidMount = asyncHandler(this, this.componentDidMount);
    }

    async componentDidMount() {
        if (this.props.imageFile.type.startsWith("image")) {
            console.log(`Previewing an image: ${this.props.imageFile.type}`);
            const imageData = await resizeImageFile(this.props.imageFile, 120); //TODO: This parameter should be set from the backend.
            await updateState(this, { imageData: imageData });
        }
        else if (this.props.imageFile.type.startsWith("video")) {
            console.log(`Previewing an video: ${this.props.imageFile.type}`);
            let imageData = await extractVideoThumbnail(this.props.imageFile, "image/jpeg");
            imageData = await resizeImageData(imageData, "image/jpeg", 120); //TODO: This parameter should be set from the backend.
            await updateState(this, { imageData: imageData });
        }
        else {
            console.log(`Unrecognised asset type: ${this.props.imageFile.type}`);
        }
    }

    render() {
        if (!this.state.imageData) {
            return <IonSpinner />;
        }
        
        return (
            <img 
                src={this.state.imageData}
                />
        );
   }
}