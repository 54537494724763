
//
// Read a file as a data URL so it can be attached to an img elemet.

import { IImageResolution } from "../model/model";

//
// Loads a file to a data URL.
//
export function loadFile(file: File): Promise<string> { 
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener('error', () => {
            reject(new Error(`Error reading file ${file.name}.`));
        });

        reader.addEventListener('load', evt => {
            resolve(evt.target!.result as string)
        });            
        
        reader.readAsDataURL(file);
    });
}

//
// Gets the size of an image element.
//
export function getImageResolution(image: HTMLImageElement): IImageResolution {
    return {
        width: image.width,
        height: image.height,
    };
}

//
// Resizes an image to have a minimum length along one axis.
//
export function resizeImage(image: HTMLImageElement, contentType: string, maxSize: number): string {
    const oc = document.createElement('canvas'); // As long as we don't reference this it will be garbage collected.
    const octx = oc.getContext('2d')!;
    oc.width = image.width;
    oc.height = image.height;
    octx.drawImage(image, 0, 0);

    // Commented out code could be useful.
    if (image.width > image.height) {
        oc.height = (image.height / image.width) * maxSize;
        oc.width = maxSize;
    } 
    else {
        oc.width = (image.width / image.height) * maxSize;
        oc.height = maxSize;
    }

    octx.drawImage(oc, 0, 0, oc.width, oc.height);
    octx.drawImage(image, 0, 0, oc.width, oc.height);
    const quality = 0.9; // A Number between 0 and 1 indicating the image quality to use for image formats that use lossy compression such as image/jpeg and image/webp.
    const imageData = oc.toDataURL(contentType, quality);
    return imageData;
}

//
// Loads URL or source data to an image element.
//
// https://stackoverflow.com/a/43354901/25868
export function loadImageElement(imageSrc: string): Promise<HTMLImageElement> {
    return new Promise<HTMLImageElement>((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            resolve(img);
        };
        img.src = imageSrc;
    });
}

export async function resizeImageData(imageData: string, contentType: string, maxSize: number): Promise<string> {
    const image = await loadImageElement(imageData);
    return await resizeImage(image, contentType, maxSize);
}

//
// Load the image from the file and resize it.
//
export async function resizeImageFile(file: File, maxSize: number): Promise<string> {
    return await resizeImageData(await loadFile(file), file.type, maxSize);
}
