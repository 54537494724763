import { InjectableClass, InjectProperty } from "@codecapers/fusion";
import * as React from "react";
import { updateState } from "../utils/update-state";
import { IAuthentication, IAuthentication_id } from "../services/authentication";
import { ICachedList, IRepository, IRepository_id } from "../services/repository";
import { INotification, INotification_id } from "../services/notification";
import LoadableListScreen from "../components/LoadableListScreen";
import "./Explore.scss";
import { INavigation, INavigation_id } from "../services/navigation";
import { IJourneyData } from "../model/model";
import { JourneyCoverPhoto } from "../components/JourneyCoverPhoto";

//
// Number of journeys in a "page".
//
const PAGE_SIZE = 10;

export interface IExploreScreenProps {
}

export interface IExploreScreenState {
    //
    // Posts to display in the feed once loaded.
    //
    journeys?: ICachedList<IJourneyData>;
}


@InjectableClass()
export default class ExploreScreen extends React.Component<IExploreScreenProps, IExploreScreenState> {

    @InjectProperty(IAuthentication_id)
    authentication!: IAuthentication;

    @InjectProperty(IRepository_id)
    post!: IRepository;

    @InjectProperty(INotification_id)
    notification!: INotification;

    @InjectProperty(INavigation_id)
    navigation!: INavigation;

    constructor(props: IExploreScreenProps) {
        super(props);

        this.state = {
        };

        this.onBeforeRefresh = this.onBeforeRefresh.bind(this);
        this.onLoad = this.onLoad.bind(this);
        this.onLoadMore = this.onLoadMore.bind(this);
    }

    //
    // Event raised before the screen is refreshed.
    //
    async onBeforeRefresh(): Promise<void> {
        this.post.clearCache("journeys.*");
    }

    //
    // Does the initial load of joruneys.
    //
    async onLoad(): Promise<void> {
        const journeys = await this.post.loadJourneys(undefined, PAGE_SIZE, false);
        await updateState(this, {
            journeys: journeys,
        });
    }

    //
    // Loads more journeys.
    //
    async onLoadMore(): Promise<void> {
        const journeys = await this.post.loadJourneys(undefined, PAGE_SIZE, true);
        await updateState(this, {
            journeys: journeys,
        });
    }

    render() {
        return (
            <LoadableListScreen 
                className="explore-screen"
                title="Explore"
                numLoaded={this.state.journeys?.data.length ?? 0}
                hasMore={this.state.journeys?.haveMore ?? false}
                onBeforeRefresh={this.onBeforeRefresh}
                onLoad={this.onLoad}
                onLoadMore={this.onLoadMore}
                >
                <div className="grid grid-cols-2 w-full">
                    {this.state.journeys?.data.map(journey => {
                        return (
                            <div
                                key={journey.id}
                                onClick={() => this.navigation.push(`/main/journey/${journey.id}`)}
                                >
                                <JourneyCoverPhoto
                                    journey={journey} 
                                    />
                            </div>
                        );
                    })}
                </div>
            </LoadableListScreen>
       );
    }
}
