import { InjectableClass, InjectProperty } from "@codecapers/fusion";
import { IonButton, IonIcon, IonItem, IonCard, IonImg, IonCardContent, IonSlides, IonSlide } from "@ionic/react";
import { analyticsOutline, chatbubbleOutline, heartOutline, locationOutline, chatboxOutline } from "ionicons/icons";
import * as React from "react";
import { IPostData } from "../model/model";
import moment from "moment";
import "./ViewPosts.scss";
import { IAuthentication, IAuthentication_id } from "../services/authentication";
import { ProfilePhoto } from "./ProfilePhoto";
import { asyncHandler } from "../utils/async-handler";
import { IRepository, IRepository_id } from "../services/repository";
import { Description } from "./Description";
import { MediaAssetPreview } from "./MediaAssetPreview";
import resize from '@awes-io/resize'
export interface IViewPostsProps {
    //
    // Posts to be displayed.
    //
    posts: IPostData[];
}

export interface IViewPostsState {
}

@InjectableClass()
export class ViewPosts extends React.Component<IViewPostsProps, IViewPostsState> {

    @InjectProperty(IAuthentication_id)
    authentication!: IAuthentication;

    @InjectProperty(IRepository_id)
    post!: IRepository;

    constructor(props: IViewPostsProps) {
        super(props);

        this.state = {
        };

        this.onLikeClick = asyncHandler(this, this.onLikeClick);
    }

    private async onLikeClick(post: IPostData): Promise<void> {

        if (post.liked) {
            post.liked = false;
            post.numLikes -= 1;
            await this.post.unlikePost(post.id);
        }
        else {
            post.liked = true;
            post.numLikes += 1;
            await this.post.likePost(post.id);
        }

        this.forceUpdate(); //TODO: This can work better if the post is in a component of it's won.
    }

    render() {
        return (
            <div className="view-posts flex flex-col">

                {this.props.posts.map(post => {
                    return (
                        <div
                            key={post.id}
                            >
                            <div className="flex flex-col flex-none w-full mt-4">
                                {post.profile
                                    && <IonItem
                                        detail={false}
                                        lines="none"
                                        routerLink={`/main/profile/${post.userId}`}
                                        className="flex-none w-full ml-4"
                                        >
                                        <div className="flex flew-row items-center flex-none w-full">
                                            <ProfilePhoto
                                                size={40}
                                                photo={post.profile?.photo}
                                                />
                                            <div className="flex flex-col ml-4">
                                                <div className="text-profile">{post.profile?.screenName}</div>
                                                <div className="flex flex-row flex-none">
                                                  <div className="text-location mt-2">{post.location && post.location.name || "Location not reverse geocoded yet"}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </IonItem>
                                }
                                <IonCard className="mt-1">
                                    <IonSlides pager={post.mediaAssets.length > 1} options={{initialSlide: 0}}>
                                        {post.mediaAssets.map(mediaAsset => {
                                            return (
                                              <IonSlide className="feedSlide" onClick={() => window.location.href=`/main/map/${post.journeyId}`} key={mediaAsset.id}>
                                              <IonImg
                                                style={{
                                                    height: `300px`,
                                                    objectFit: 'cover',
                                                    width: `${290 * mediaAsset.resolution.width / mediaAsset.resolution.height}px`,
                                                }}
                                                src={this.authentication.makeAuthenticatedUrl(`/api/asset/get`, { id: `thumb/${mediaAsset.id}` })}
                                                />
                                              </IonSlide>
                                            );
                                        })}
                                      </IonSlides>
                                    <IonCardContent className="p-0" >
                                      <div className="flex flex-startflex-none items-center w-full">
                                        <IonButton
                                          fill="clear"
                                          size="small"
                                          className="text-black p-0 ml-4 post-buttons"
                                          onClick={() => this.onLikeClick(post)}
                                          no-margin
                                          >
                                          <IonIcon icon={heartOutline} className="p-0"/>
                                          <div className="text-count mr-1 w-4 p-0">{post.numLikes}</div>
                                        </IonButton>
                                        <IonButton
                                          fill="clear"
                                          size="small"
                                          className="text-black ml-1 p-0 post-buttons"
                                          routerLink={`/main/post/${post.id}`}
                                          no-margin
                                          >
                                          <IonIcon icon={chatboxOutline} className="p-0" />
                                          <div className="text-count mr-1 w-4 p-0">{post.numComments}</div>
                                        </IonButton>
                                        </div>

                                          {post.text
                                            &&
                                              <div className="flex flex-start w-full items-center ml-2 p-0">
                                                <IonItem
                                                    detail={false}
                                                    lines="none"
                                                    routerLink={`/main/post/${post.id}`}
                                                    className="ml-2"
                                                    >
                                                    <Description text={post.text} />
                                                </IonItem>
                                              </div>
                                          }
                                    </IonCardContent>
                                </IonCard>
                            </div>
                        </div>
                    );
                })}

                <div
                    className="flex-none w-full"
                    style={{ height: "150px" }}
                    />

            </div>
        );
    }
}
