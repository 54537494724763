import { InjectableClass, InjectProperty } from "@codecapers/fusion";
import { IonAlert, IonButton, IonIcon, IonItem } from "@ionic/react";
import {  mapOutline } from "ionicons/icons";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import { IRepository, IRepository_id } from "../services/repository";
import { updateState } from "../utils/update-state";
import "./ViewJourney.scss";
import { IJourneyData, IProfileData } from "../model/model";
import moment from "moment";
import { ViewJourney } from "../components/ViewJourney";
import { ProfilePhoto } from "../components/ProfilePhoto";
import { Description } from "../components/Description";
import LoadableScreen from "../components/LoadableScreen";
import { INotification, INotification_id } from "../services/notification";
import { asyncHandler, handleAsync } from "../utils/async-handler";
import { INavigation, INavigation_id } from "../services/navigation";
import { IAuthentication, IAuthentication_id } from "../services/authentication";

//
// Parameters passed in by URL.
//
export interface IViewJourneyScreenMatchProps {
    //
    // ID of the journey we are viewing.
    //
    id: string;
}

export interface IViewJourneyProps extends RouteComponentProps<IViewJourneyScreenMatchProps> {
}

export interface IViewJourneyState {
    //
    // Set this to redirect to a different screen.
    //
    redirectTo?: string;

    //
    // Set to true to show the menu.
    //
    showMenu: boolean;

    //
    // Data for the journey, once loaded.
    //
    journey?: IJourneyData;

    //
    // Users profile data, once loaded.
    //
    profile?: IProfileData;

    //
    // Set to true to show the delete post confirmation dialog.
    //
    showDeleteJourneyConfirmation: boolean;
}

@InjectableClass()
export default class ViewJourneyScreen extends React.Component<IViewJourneyProps, IViewJourneyState> {

    @InjectProperty(IRepository_id)
    post!: IRepository;

    @InjectProperty(INotification_id)
    notification!: INotification;

    @InjectProperty(INavigation_id)
    navigation!: INavigation;

    @InjectProperty(IAuthentication_id)
    authentication!: IAuthentication;

    constructor(props: IViewJourneyProps) {
        super(props);

        this.state = {
            showMenu: false,
            showDeleteJourneyConfirmation: false,
        };

        this.onLoad = this.onLoad.bind(this);
        this.onCompleteJourneyClicked = asyncHandler(this, this.onCompleteJourneyClicked);
        this.onDeleteJourneyClicked = asyncHandler(this, this.onDeleteJourneyClicked);
        this.onConfirmDeleteJourneyClicked = asyncHandler(this, this.onConfirmDeleteJourneyClicked);
    }

    //
    // Load data for the page.
    //
    private async onLoad(): Promise<void> {
        const journey = await this.post.loadJourney(this.props.match.params.id);
        await updateState(this, {
            journey: journey,
        });

        const profile = await this.post.loadProfileForDisplay(journey.userId);
        await updateState(this, {
            profile: profile,
        });
    }

    private async onCompleteJourneyClicked() { //TODO: This code now shared between view / live journey screens.
        try {
            await updateState(this, { working: true });

            await this.post.saveJourney(this.props.match.params.id, { status: "complete" });

            await updateState(this, {
                working: false,
                redirectTo: "/main/feed",
            });
        }
        catch (err) {
            console.log(`An error occured completing the journey.`);
            console.error(err && err.stack || err);
            this.notification.error(`An error occured completing the journey.`);
            await updateState(this, { working: true });
        }
    }

    //
    // Event raised when the user has clicked the button to delete their own journey.
    //
    private async onDeleteJourneyClicked(): Promise<void> {
        await updateState(this, { showDeleteJourneyConfirmation: true });
    }

    //
    // Event raised when the user has confirmed to delete their journey.
    //
    private async onConfirmDeleteJourneyClicked(): Promise<void> {
        try {
            await this.post.deleteJourney(this.props.match.params.id);
        }
        catch (err) {
            console.error(`Failed to delete journey ${this.props.match.params.id}.`);
            console.error(err && err.stack || err);
            this.notification.error("Failed to delete journey, please try again later");
            return;
        }

        this.navigation.navigateRoot("/main/feed");
        this.notification.success("You deleted your journey");
    }

    render() {
        let dateUpdated: string = "";
        if (this.state.journey) {
            if (this.state.journey.posts && this.state.journey.posts.length > 0) {
                const latestPost = this.state.journey.posts[0];
                dateUpdated = `Updated ${moment(latestPost.dateUpdated).fromNow()}`;
            }
            else {
                dateUpdated = `Updated ${moment(this.state.journey.dateCreated).fromNow()}`;
            }
        }

        const menuButtons =
            this.state.journey?.own
                ? [ // Menu buttons for user's own journey.
                    {
                        text: 'Edit journey',
                        handler: () => {
                            this.navigation.push(`/main/edit-journey/${this.state.journey?.id}`);
                        },
                    },
                    {
                        text: 'Complete journey',
                        cssClass: "mt-2",
                        handler: this.onCompleteJourneyClicked,
                    },

                    {
                        text: 'Delete journey',
                        role: "destructive",
                        handler: this.onDeleteJourneyClicked,
                    },
                ]
                : [ // Menu buttons when viewing another user's journey.
                    {
                        text: 'Report offensive content',
                        handler: () => {
                            handleAsync(async () => {
                                await this.authentication.post("/api/report", { journeyId: this.state.journey?.id });
                            });
                        },
                    },
                ];

        return (
            <LoadableScreen
                className="view-journey-screen"
                redirectTo={this.state.redirectTo}
                enableBackButton={this.props.match.params.id !== undefined}
                title="View journey"
                menuButtons={menuButtons}
                onMenuButtonClick={() => {
                    this.setState({ showMenu: true });
                }}
                onLoad={this.onLoad}
                >
                <div className="flex flex-col items-stretch">

                    <div className="flex flex-row items-center w-full">
                        <IonItem
                            detail={false}
                            lines="none"
                            routerLink={`/main/profile/${this.state.profile?.userId}`}
                            className="flex flew-row flex-grow items-center w-full ml-4"
                            >
                            <ProfilePhoto
                                size={40}
                                photo={this.state.profile?.photo}
                                />

                            <div className="flex flex-col ml-2">
                                <div className="text-profile">{this.state.profile?.screenName}</div>
                                <div className="text-time">{dateUpdated}</div>
                            </div>
                        </IonItem>

                        <div className="flex-grow" />

                        <IonButton
                            fill="clear"
                            routerLink={`/main/map/${this.state.journey?.id}`}
                            >
                            <IonIcon
                                icon={mapOutline}
                                slot="icon-only"
                                className="text-black"
                                />
                        </IonButton>
                    </div>

                    <div className="flex flex-row flex-none">
                        <div className="flex flex-col w-full">

                            <div className="text-title ml-4 mr-4">
                                {this.state.journey && this.state.journey.title}
                            </div>

                            <div className="ml-4 mr-4">
                                <Description
                                    text={this.state.journey?.description ?? ""}
                                    />
                            </div>

                            <div className="w-full h-0 mt-1 page-border"></div>
                        </div>
                    </div>

                    {this.state.journey
                        && <ViewJourney journey={this.state.journey} />
                    }
                </div>

                <IonAlert
                    isOpen={this.state.showDeleteJourneyConfirmation}
                    onDidDismiss={() => this.setState({ showDeleteJourneyConfirmation: false })}
                    header="Delete journey"
                    message="Are you sure you want to delete this journey? It will also delete all posts for the journey"
                    buttons={[
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            handler: () => this.setState({ showDeleteJourneyConfirmation: false }),
                        },
                        {
                            text: 'OK',
                            role: "destructive",
                            handler: this.onConfirmDeleteJourneyClicked,
                        },
                    ]}
                    />

            </LoadableScreen>
       );
    }
}
