import React from 'react';
import { IonSpinner } from '@ionic/react';

export interface ISpinnerProps {
    //
    // Set to true to make the spinner background opaque.
    //
    opaque: boolean;
}

export interface ISpinnerState {
}

export class Spinner extends React.Component<ISpinnerProps, ISpinnerState> {
    
    render() {
        return (
            <div 
                className="absolute pointer-events-none"
                style={{
                    backgroundColor: this.props.opaque ? "white" : "rgba(255, 255, 255, 0.2)",
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                }}
                >
                <div 
                    className="absolute"
                    style={{
                        left: "50%",
                        top: "50%",                        
                    }}
                    >
                    <div 
                        className="relative"
                        style={{
                            left: "-50%",
                            top: "-50%",
                        }}
                        >
                        <IonSpinner />
                    </div>
                </div>
            </div>
        );
    }
}