//
// Renders a journey's cover photo.
//

import { InjectableClass, InjectProperty } from "@codecapers/fusion";
import React from "react";
import { updateSetAccessor } from "typescript";
import { IJourneyData, IMediaAsset } from "../model/model";
import { IRepository, IRepository_id } from "../services/repository";
import { asyncHandler } from "../utils/async-handler";
import { updateState } from "../utils/update-state";
import { Thumbnail } from "./Thumbnail";

export interface IJourneyCoverPhotoProps {

    //
    // The journey.
    //
    journey: IJourneyData;
}

interface IJourneyCoverPhotoState {

    //
    // The last photo from the user, if any.
    //
    lastPhoto?: IMediaAsset;
}

@InjectableClass()
export class JourneyCoverPhoto extends React.Component<IJourneyCoverPhotoProps, IJourneyCoverPhotoState> {

    @InjectProperty(IRepository_id)
    post!: IRepository;

    constructor(props: IJourneyCoverPhotoProps) {
        super(props);

        this.state = {};

        this.componentDidMount = asyncHandler(this, this.componentDidMount);
    }

    async componentDidMount() {

        // Find the most recent posted photo.
        if (this.props.journey.coverPhoto === undefined) {
            const postsList = await this.post.loadPostsForJourney(this.props.journey.id, 10, false);
            for (const post of postsList.data) {
                if (post.mediaAssets && post.mediaAssets.length > 1) {
                    await updateState(this, { lastPhoto: post.mediaAssets[0] });
                    break;
                }
            }
        }
    }    

    render() {
        return (
            <>
                {this.props.journey.coverPhoto !== undefined
                    && <Thumbnail 
                        photo={this.props.journey.coverPhoto} 
                    />
                }

                {this.props.journey.coverPhoto === undefined
                    && <Thumbnail
                        photo={this.state.lastPhoto}
                        />
                }
            </>
        );
    }
}