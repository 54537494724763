//
// Views a full media asset.
//

import { InjectableClass, InjectProperty } from '@codecapers/fusion';
import { IonImg } from '@ionic/react';
import * as React from 'react';
import { IMediaAsset } from '../model/model';
import { IAuthentication, IAuthentication_id } from '../services/authentication';

export interface IMediaAssetViewProps {
    //
    // The asset to load and preview.
    //
    mediaAsset: IMediaAsset;
}

interface IMediaAssetViewState {
}

@InjectableClass()
export class MediaAssetView extends React.Component<IMediaAssetViewProps, IMediaAssetViewState> {

    @InjectProperty(IAuthentication_id)
    authentication!: IAuthentication;

    render() {
        const assetUrl = this.authentication.makeAuthenticatedUrl(`/api/asset/get`, { id: `full/${this.props.mediaAsset.id}` });

        if (this.props.mediaAsset.contentType && 
            this.props.mediaAsset.contentType.startsWith("video")) {
            return (                
                <div className="flex flex-col h-full items-center justify-center">
                    <video
                        muted
                        playsInline
                        loop
                        controls
                        style={{
                            width: "100%",
                        }}
                        >
                        <source 
                            src={assetUrl}
                            type={this.props.mediaAsset.contentType}
                            />
                    </video>                
                </div>
            );
        }
        return (
            <div className="flex flex-col h-full items-center justify-center">
                <img
                     src={assetUrl} 
                     />
            </div>
        );
   }
}