import { IonReactRouter } from "@ionic/react-router";

export const INavigation_id = "INavigation";

export interface INavigation {

    //
    // Navigate to a root page.
    //
    navigateRoot(route: string): void;

    //
    // Push a new page on the stack.
    //
    push(route: string): void;

    //
    // Opens a URL in the browser.
    //
    open(url: string): void;

    //
    // Navigate to the previous screen.
    //
    back(): void;
}

export class Navigation {

    private router: IonReactRouter;

    constructor(router: IonReactRouter) {
        this.router = router;

        router.history.listen((location, action) => {
            console.log("!! Route change:");
            console.log(location);
            console.log(action);
        });
    }

    //
    // Navigate to a root page.
    //
    navigateRoot(route: string): void {
        console.log("Navigating to root page: " + route);
        this.router.history.replace(route);
    }
    
    //
    // Push a new page on the stack.
    //
    push(route: string): void {
        console.log("Pushing new page: " + route);
        this.router.history.push(route);
    }

    //
    // Opens a URL in the browser.
    //
    open(url: string): void {
        window.open(url, "_blank");
    }

    //
    // Navigate to the previous screen.
    //
    back(): void {
        this.router.history.goBack();
    }
}