import React, { useEffect, useRef } from "react";
import { IonCard, IonButton, IonContent, IonCardContent, IonCardHeader, IonItem } from "@ionic/react";
import { IJourneyData, IProfileData } from "../model/model";
import { ProfilePhoto } from "../components/ProfilePhoto";
import { Description } from "../components/Description";
import { createGesture, Gesture } from "@ionic/core";
import { ViewJourney } from '../components/ViewJourney'
import "./BottomDrawer.scss"

const BottomDrawer = (props: {
  profile?:IProfileData,
  journey?:IJourneyData
}) => {
  const drawerRef = useRef(null);
  const contentRef = useRef(null);
  const windowHeight = window.innerHeight;
  const drawerHeight = windowHeight - 138
  const { profile, journey } = props

  useEffect(() => {
    let c = drawerRef.current;

    const gesture: Gesture = createGesture({
      el: c || new Node(),
      gestureName: "my-swipe",
      direction: "y",
      onMove: (event) => {
        if (event.deltaY < -300) return;

        let a = document.getElementById("bottomDrawer");
        console.log('onMove')
        if (a === null) return;
        // closing with a downward swipe
        if (event.deltaY < -20) {
          return;
        } else if (event.deltaY > 50) {
          a.style.transform = "";
          a.dataset.open = "false";
          console.log("here");
          return;
        } else {
          console.log("hereerere", event.deltaY);
          a.style.transform = `translateY(${event.deltaY}px)`;
        }


        // a.style.transform = `translateY(${event.deltaY}px)`;
      },
      onEnd: (event) => {
        let c = document.getElementById("bottomDrawer");

        if (c === null) return;
        c.style.transition = ".3s ease-out";

        console.log(event.deltaY)
        console.log(windowHeight / 20)
        console.log(event.deltaY < -(windowHeight / 20), c.dataset.open)
        if (event.deltaY < -(windowHeight / 20) && (c.dataset.open === "false" || c.dataset.open === undefined)) {
          c.style.transform = `translateY(-${10}px) `;
          c.dataset.open = "true";
          console.log('one')
        }
        else if (event.deltaY < (windowHeight / 20) && c.dataset.open === 'true') {
          c.style.transform = `translateY(-${75}px) `;
          c.dataset.open = 'top'
          console.log('two')
        }
        else if (event.deltaY > (windowHeight / 20) && c.dataset.open === 'true') {
          c.style.transform = `translateY(${0}px) `;
          c.dataset.open = 'false'
          console.log('three')
        }
        else {
          c.style.transform = `translateY(${50}vh) `;
          c.dataset.open = 'false'
          console.log('four')
        }
      }
    });
    gesture.enable(true);
  }, []);

  const toggleDrawer = () => {
    let c = document.getElementById("bottomDrawer");

    if (c === null) return;

    if (c.dataset.open === "true" && c !== null) {
      c.style.transition = ".5s ease-out";
      c.style.transform = "";
      c.dataset.open = "false";
      console.log('xx')
    } else {
      c.style.transition = ".5s ease-in";
      c.style.transform = `translateY(${-430}px) `;
      c.dataset.open = "true";
    }
  };

  return (

        <IonContent scrollY={false} className="ion-padding">
          <IonCard
            id="bottomDrawer"
            className="bottom-drawer"
          >
            <div style={{ textAlign: "center" }} ref={drawerRef}>
              <IonButton className="drawer-button"
                size="small"
              />
            </div>
            <IonCardHeader>
              <IonItem
                  detail={false}
                  lines="none"
                  routerLink={`/main/profile/${profile?.userId}`}
                  className="flex flew-row flex-grow items-center w-full ml-4"
                  >
                  <ProfilePhoto
                      size={40}
                      photo={profile?.photo}
                      />

                  <div className="flex flex-col ml-2">
                      <div className="text-profile">{profile?.screenName}</div>
                  </div>
              </IonItem>
            </IonCardHeader>
            <IonCardContent>
              <div className="text-title ml-4 mr-4">
                {journey && journey.title}
              </div>

              <div className="ml-4 mr-4">
                <Description text={journey?.description ?? ""} />
              </div>

              <div className="w-full h-0 mt-1 page-border"></div>
              { journey && <ViewJourney journey={journey} />  }
            </IonCardContent>

          </IonCard>
        </IonContent>
  );
};

export default BottomDrawer;
