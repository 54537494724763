import { InjectableClass, InjectProperty } from "@codecapers/fusion";
import { IonAvatar, IonIcon, IonImg } from "@ionic/react";
import { personCircleOutline } from "ionicons/icons";
import React from "react";
import { IMediaAsset } from "../model/model";
import { IAuthentication, IAuthentication_id } from "../services/authentication";

export interface IProfilePhotoProps {
    //
    // Display size for the photo.
    //
    size: number;
    
    //
    // The profile picture, if there isone.
    //
    photo?: IMediaAsset;
}

export interface IProfilePhotoState {
}

@InjectableClass()
export class ProfilePhoto extends React.Component<IProfilePhotoProps, IProfilePhotoState> {

    @InjectProperty(IAuthentication_id)
    authentication!: IAuthentication;

    render() {
        return (
            <IonAvatar 
                className="profile-avatar flex-none"
                style={{
                    width: `${this.props.size}px`,
                    height: `${this.props.size}px`,
                }}
                >
                {this.props.photo
                    ? <IonImg 
                        src={this.authentication.makeAuthenticatedUrl(`/api/asset/get`, { id: `thumb/${this.props.photo.id}` })}
                        />
                    : <IonIcon
                        icon={personCircleOutline}
                        style={{
                            fontSize: `${this.props.size}px`,
                        }}
                        />
                }
            </IonAvatar>
        );
    }
}

