import { InjectableClass, InjectProperty } from "@codecapers/fusion";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import { IJourneyData, IPostData } from "../model/model";
import { INotification, INotification_id } from "../services/notification";
import { ICachedList, IRepository, IRepository_id } from "../services/repository";
import { updateState } from "../utils/update-state";
import "./Feed.scss";
import { ViewPosts } from "../components/ViewPosts";
import LoadableListScreen from "../components/LoadableListScreen";
import { Plugins } from "@capacitor/core";

//
// Number of posts in a "page".
//
const PAGE_SIZE = 10;

export interface IFeedScreenProps extends RouteComponentProps<{}> {

}

export interface IFeedScreenState {
    //
    // Posts to display in the feed once loaded.
    //
    posts?: ICachedList<IPostData>;
}

@InjectableClass()
export default class FeedScreen extends React.Component<IFeedScreenProps, IFeedScreenState> {

    @InjectProperty(IRepository_id)
    post!: IRepository;

    @InjectProperty(INotification_id)
    notification!: INotification;

    constructor(props: IFeedScreenProps) {
        super(props);

        this.state = {
        };

        this.onBeforeRefresh = this.onBeforeRefresh.bind(this);
        this.onLoad = this.onLoad.bind(this);
        this.onLoadMore = this.onLoadMore.bind(this);
    }

    //
    // Event raised before the screen is refreshed.
    //
    async onBeforeRefresh(): Promise<void> {
        this.post.clearCache("feed");
    }

    //
    // Does the initial load of posts.
    //
    async onLoad(): Promise<void> {
        const posts = await this.post.loadFeed(PAGE_SIZE, false);
        await updateState(this, {
            posts: posts,
        });

        setTimeout(() => { // Give it a moment for initial photos to load.
            Plugins.SplashScreen.hide(); // Make sure the splash screen is finally hidden.
        }, 1000);
    }

    async onLoadMore(): Promise<void> {
        const posts = await this.post.loadFeed(PAGE_SIZE, true);
        await updateState(this, {
            posts: posts,
        });
    }

    render() {
        return (
            <LoadableListScreen
                className="feed-screen"
                title={(
                    <div className="flex flex-row">
                        <div className="flex-grow m-0 text-left">
                            <img className="logo-sm" src="assets/logo/final logo_logo - colour.svg" />
                        </div>
                    </div>
                )}
                numLoaded={this.state.posts?.data.length ?? 0}
                hasMore={this.state.posts?.haveMore ?? false}
                onBeforeRefresh={this.onBeforeRefresh}
                onLoad={this.onLoad}
                onLoadMore={this.onLoadMore}
                >
                {this.state.posts
                    && <ViewPosts
                        posts={this.state.posts.data}
                        />
                }
            </LoadableListScreen >
        );
    }
}
