import { InjectableClass, InjectProperty } from "@codecapers/fusion";
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, withIonLifeCycle } from "@ionic/react";
import * as React from "react";
import { asyncHandler } from "../utils/async-handler";
import { updateState } from "../utils/update-state";
import { FullScreenSpinner } from "../components/FullScreenSpinner";
import { IRepository, IRepository_id } from "../services/repository";
import { IProfileData } from "../model/model";
import { INotification, INotification_id } from "../services/notification";
import { EditProfile } from "../components/EditProfile";

export interface IEditProfileScreenProps {
}

export interface IEditProfileScreenState {
    //
    // Set to true when data in the page is loading.
    //
    loading: boolean;

    //
    // Set to true when the backend is working.
    //
    working: boolean;

    //
    // Profile data (once loaded).
    //
    profile?: IProfileData;
}

@InjectableClass()
class EditProfileScreen extends React.Component<IEditProfileScreenProps, IEditProfileScreenState> {

    @InjectProperty(IRepository_id)
    post!: IRepository;

    @InjectProperty(INotification_id)
    notification!: INotification;

    constructor(props: IEditProfileScreenProps) {
        super(props);

        this.state = {
            loading: true,
            working: false,
        };

        this.ionViewWillEnter = asyncHandler(this, this.ionViewWillEnter);
        this.onProfileSaved = asyncHandler(this, this.onProfileSaved);
    }

    async ionViewWillEnter() {
        await updateState(this, {
            loading: true,
        });

        try {
            const profile = await this.post.loadProfileForEdit();
            await updateState(this, {
                profile: profile,
            });
        }
        catch (err) {
            this.notification.error(`Failed to load profile.`);
            console.error("Error getting profile:");
            console.error(err && err.stack || err);
        }
        finally {
            await updateState(this, {
                loading: false,
            });
        }       
    }

    private async onProfileSaved(): Promise<void> {
        // Reload profile.
        const profile = await this.post.loadProfileForEdit();
        await updateState(this, {
            working: false,
            profile: profile,
        });
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton 
                                className="text-black" 
                                defaultHref="/main/profile" 
                                text={""}
                                />
                        </IonButtons>

                        <IonTitle className="text-title">
                            Edit profile
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>

                    <div className="p-4">
                        {this.state.profile
                            && <EditProfile
                                saveButtonTitle="Save edits"
                                profile={this.state.profile}
                                onSavingProfile={async () => {
                                    await updateState(this, {
                                        working: true,
                                    });
                                }}
                                onProfileSaved={this.onProfileSaved}
                                onSaveFailed={async () => {
                                    await updateState(this, {
                                        working: false,
                                    });
                                }}
                                />
                        }
                    </div>

                </IonContent>

                {(this.state.loading || this.state.working)
                    && <FullScreenSpinner 
                        opaque={this.state.loading}
                        />                
                }

           </IonPage>
       );
    }
}

export default withIonLifeCycle(EditProfileScreen);