
//
// Truncate some text to a maximum length.
//
export function truncateText(input: string, max: number): string {
    if (!input) {
        return "";
    }

    if (input.length > max) {
        return input.substring(0, max) + "...";
    }
    else {
        return input;
    }
}