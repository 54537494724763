import { IonAvatar, IonButton, IonChip, IonContent, IonIcon, IonInput, IonItem, IonLabel, IonList, IonPage } from "@ionic/react";
import { add, camera } from "ionicons/icons";
import * as React from "react";
import "./FindJourneys.scss";

export interface IFindJourneysScreenProps {

}

export interface IFindJourneysScreenState {

}

export class FindJourneysScreen extends React.Component<IFindJourneysScreenProps, IFindJourneysScreenState> {

    render() {
        return (
            <IonPage className="find-journeys">
                <IonContent fullscreen>
                    <div className="flex flex-col h-full items-center pb-8">
                        <div className="mt-16">
                            <img className="logo" src="assets/logo/final logo_icon colour.svg" />
                        </div>
                        <div className="text-title text-center mt-2">Let's find some journeys</div>

                        <div className="flex-grow"></div>

                        <div className="flex flex-col w-full max-w-xs pl-5 pr-5"> 
                            <div className="text-suggested text-left w-full">Suggested</div>

                            <IonList className="w-full" lines="none">

                                <IonItem className="flex flex-row mt-4">
                                    <IonAvatar>
                                        <img src="https://www.w3schools.com/howto/img_avatar.png" />
                                    </IonAvatar>

                                    <div className="text-regular ml-4">@chris</div>

                                    <div className="flex-grow" />

                                    <IonButton
                                        fill="clear"
                                        >
                                        <IonIcon 
                                            icon={add} 
                                            slot="start"
                                            />
                                            Follow
                                    </IonButton>
                                </IonItem>                                                        

                                <IonItem className="flex flex-row mt-4">
                                    <IonAvatar>
                                        <img src="https://www.w3schools.com/howto/img_avatar2.png" />
                                    </IonAvatar>

                                    <div className="text-regular ml-4">@hiya</div>

                                    <div className="flex-grow" />

                                    <IonButton
                                        fill="clear"
                                        >
                                        <IonIcon 
                                            icon={add} 
                                            slot="start"
                                            />
                                            Follow
                                    </IonButton>
                                </IonItem>                                                        

                                <IonItem className="flex flex-row mt-4">
                                    <IonAvatar>
                                        <img src="https://www.w3schools.com/w3images/avatar2.png" />
                                    </IonAvatar>

                                    <div className="text-regular ml-4">@lars</div>

                                    <div className="flex-grow" />

                                    <IonButton
                                        fill="clear"
                                        >
                                        <IonIcon 
                                            icon={add} 
                                            slot="start"
                                            />
                                            Follow
                                    </IonButton>
                                </IonItem>                                                        

                            </IonList>
                        </div>

                        <div className="flex-grow"></div>

                        <div className="flex flex-col w-full max-w-xs pl-5 pr-5">
                            <div className="flex flex-row items-center mt-6">
                                <div className="mr-3 text-gray-400">1</div>
                                <div>2</div>
                                <div className="flex-grow" />
                                <IonButton 
                                    className="next-button h-14 w-32"
                                    routerLink="/main/feed"
                                    routerDirection="root"
                                    >
                                    <span className="ml-2">
                                        Next
                                    </span>
                                </IonButton>
                            </div>
                        </div>
                    </div>
                </IonContent>
            </IonPage>
        );
    }
}