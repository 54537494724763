import { InjectableClass, InjectProperty } from "@codecapers/fusion";
import { IonActionSheet, IonAlert, IonButton, IonIcon, IonItem } from "@ionic/react";
import { mapOutline } from "ionicons/icons";
import * as React from "react";
import { IRepository, IRepository_id } from "../services/repository";
import { asyncHandler } from "../utils/async-handler";
import { updateState } from "../utils/update-state";
import "./LiveJourney.scss";
import { INotification, INotification_id } from "../services/notification";
import { IAuthentication, IAuthentication_id, IMediaFile } from "../services/authentication";
import { IJourneyData, ILocation, IMediaAsset, IProfileData } from "../model/model";
import { ViewJourney } from "../components/ViewJourney";
import { ProfilePhoto } from "../components/ProfilePhoto";
import { Description } from "../components/Description";
import moment from "moment";
import LoadableScreen from "../components/LoadableScreen";
import 'react-day-picker/lib/style.css';
import { INavigation, INavigation_id } from "../services/navigation";
import { PostEditor } from "../components/PostEditor";

export interface ILiveJourneyProps {
}

export interface ILiveJourneyState {
    // 
    // Set to true to show the menu.
    //
    showMenu: boolean;

    //
    // Data for the live journey, once loaded.
    //
    journey?: IJourneyData;

    //
    // Users profile data, once loaded.
    //
    profile?: IProfileData;

    //
    // Set to true while backend work is being dnoe.
    //
    working: boolean;

    //
    // Set to true once the new journey update has been validated.
    //
    validated: boolean;

    //
    // A new update for the journey.
    //
    text: string;

    //
    // Assets to be uploaded with the post.
    //
    mediaFiles: IMediaFile[];

    //
    // Location for the post, once selected.
    //
    location?: ILocation;

    //
    // Date of the post, selected by the user.
    // Defaults to today.
    // Format YYYY-MM-DD.
    //
    date: string;

    //
    // Time of the post, selected by the user.
    // Defaults to now.
    //
    time: string;

    //
    // Set to true to show the delete post confirmation dialog.
    //
    showDeleteJourneyConfirmation: boolean;
}

@InjectableClass()
export default class LiveJourneyScreen extends React.Component<ILiveJourneyProps, ILiveJourneyState> {

    @InjectProperty(IRepository_id)
    post!: IRepository;

    @InjectProperty(INotification_id)
    notification!: INotification;

    @InjectProperty(IAuthentication_id)
    authentication!: IAuthentication;

    @InjectProperty(INavigation_id)
    navigation!: INavigation;

    constructor(props: ILiveJourneyProps) {
        super(props);

        this.state = {
            showMenu: false,
            showDeleteJourneyConfirmation: false,
            working: false,
            validated: false,
            text: "",
            mediaFiles: [],
            date: "",
            time: "",
        };

        this.onBeforeRefresh = this.onBeforeRefresh.bind(this);
        this.onLoad = this.onLoad.bind(this);
        this.onFieldChanged = asyncHandler(this, this.onFieldChanged);
        this.onPostClicked = asyncHandler(this, this.onPostClicked);
        this.onSelectMediaAssets = asyncHandler(this, this.onSelectMediaAssets);
        this.onLocationSelected = asyncHandler(this, this.onLocationSelected);
        this.onDateSelected = asyncHandler(this, this.onDateSelected);
        this.onTimeSelected = asyncHandler(this, this.onTimeSelected);
        this.onCompleteJourneyClicked = asyncHandler(this, this.onCompleteJourneyClicked);
        this.onDeleteJourneyClicked = asyncHandler(this, this.onDeleteJourneyClicked);
        this.onConfirmDeleteJourneyClicked = asyncHandler(this, this.onConfirmDeleteJourneyClicked);
        this.onMediaFileDeleted = this.onMediaFileDeleted.bind(this);
    }

    //
    // Event raised before the screen is refreshed.
    //
    async onBeforeRefresh(): Promise<void> {
        this.post.clearCache("liveJourney");
    }

    //
    // Event raised to load data for the screen.
    //
    async onLoad(): Promise<void> {
        const now = moment();
        await updateState(this, {
            journey: undefined,
            profile: undefined,
            date: now.format("YYYY-MM-DD"),
            hour: now.hour(),
            minute: now.minute(),
        });

        const journey = await this.post.getLiveJourney();
        if (!journey) {
            // Redirect and allow the user to create a new journey.
            this.navigation.navigateRoot("/main/new-journey");
        }
        else {
            const profile = await this.post.loadProfileForDisplay();
            await updateState(this, { 
                journey: journey,
                profile: profile,
            });
        }
    }    

    private async validate(): Promise<void> {
        const isValid = this.state.location !== undefined; // Location is the minimum required for a post.
        await updateState(this, {
            validated: isValid,
        }); 
    }

    //
    // Event raised when a field of the post has been changed.
    //
    private async onFieldChanged(fieldName: string, value: string | null | undefined): Promise<void> {
        const stateParams: any = {};
        stateParams[fieldName] = value;
        await updateState(this, stateParams);
        await this.validate();
    }

    private async onPostClicked(): Promise<void>  {
        try {
            await updateState(this, { working: true });
    
            //
            // Ids for uploaded assets.
            //
            const mediaAssets: IMediaAsset[] = [];
            
            for (const mediaFile of this.state.mediaFiles) {
                try {
                    const mediaAsset: IMediaAsset = await this.authentication.uploadMediaAsset(mediaFile);
                    mediaAssets.push(mediaAsset);
                }
                catch (err) {
                    //
                    // FAILURE HERE CAN RESULT IN ORPHANED MEDIA ASSETS, BUT THEY WON'T BE REFERENCED BY THE DATABASE
                    // SO THEY CAN BE GARBAGE COLLECTED LATER.
                    //                    
                    this.notification.error(`Failed to upload media asset ${mediaFile.file.name}.`);
                    return;    
                }
            }

            await this.post.addNewPost({
                text: this.state.text.trim(),
                location: this.state.location!,
                mediaAssets: mediaAssets,
                journeyId: this.state.journey!.id,
                date: this.state.date,
                time: this.state.time,
            });
    
            // Reset state for a new post.
            await updateState(this, {
                text: "",
                mediaFiles: [],
                validated: false,
            });

            await this.onLoad();

            this.notification.success(`Your update was published`);
        }    
        finally {
            await updateState(this, {
                working: false,
            });
        }
    }

    //
    // User has requested to upload various media files.
    //
    private async onSelectMediaAssets(files: FileList): Promise<void> {

        const mediaFiles = this.state.mediaFiles.slice(); // Clone.
        for (let i = 0; i < files.length; ++i) {
            const file = files[i];
            mediaFiles.push({
                file: file,
            });
        }

        await updateState(this, { 
            mediaFiles: mediaFiles,
        });
        await this.validate();
    }

    //
    // Event raised when the user has selected a location.
    //
    private async onLocationSelected(location: ILocation): Promise<void> {
        await updateState(this, {
            location: location,
            selectedLocation: undefined,
            showLocationPicker: false,
        });
        await this.validate();
    }

    //
    // Event raised when the user has selected a new date.
    //
    private async onDateSelected(date: string): Promise<void> {
        await updateState(this, {
            date: date,
        });
        await this.validate();
    }

    //
    // User has selected the time for the post.
    //
    private async onTimeSelected(time: string): Promise<void> {
        await updateState(this, {
            time: time,
        });
        await this.validate();
    }

    private async onCompleteJourneyClicked() { //TODO: This code now shared between view / live journey screens.
        if (!this.state.journey) {
            return;
        }

        try {
            await updateState(this, { working: true });

            await this.post.saveJourney(this.state.journey.id, { status: "complete" });

            await updateState(this, { 
                working: false,
                redirectTo: "/main/new-journey",
            });
        }
        catch (err) {
            console.log(`An error occured completing the journey.`);
            console.error(err && err.stack || err);
            this.notification.error(`An error occured completing the journey.`);            
            await updateState(this, { working: true });
        }
    }

    //
    // Event raised when the user has clicked the button to delete their own journey.
    //
    private async onDeleteJourneyClicked(): Promise<void> {
        if (!this.state.journey) {
            return;
        }

        await updateState(this, { showDeleteJourneyConfirmation: true });
    }
    
    //
    // Event raised when the user has confirmed to delete their journey.
    //
    private async onConfirmDeleteJourneyClicked(): Promise<void> {
        if (!this.state.journey) {
            return;
        }

        try {
            await this.post.deleteJourney(this.state.journey.id);
        }
        catch (err) {
            console.error(`Failed to delete journey ${this.state.journey.id}.`);
            console.error(err && err.stack || err);
            this.notification.error("Failed to delete journey, please try again later");
            return;
        }

        this.navigation.navigateRoot("/main/feed");
        this.notification.success("You deleted your journey"); 
    }

    //
    // Event raised when a user chooses to delete a media file that isn't uploaded yet.
    //
    private async onMediaFileDeleted(mediaFile: IMediaFile): Promise<void> {
        await updateState(this, {
            mediaFiles: this.state.mediaFiles.filter(file => file !== mediaFile),
        });
    }

    render() {

        let dateUpdated: string = "";
        if (this.state.journey) {
            if (this.state.journey.posts && this.state.journey.posts.length > 0) {
                const latestPost = this.state.journey.posts[0];
                dateUpdated = `Updated ${moment(latestPost.dateUpdated).fromNow()}`;
            }
            else {
                dateUpdated = `Updated ${moment(this.state.journey.dateCreated).fromNow()}`;
            }
        }

        return (
            <LoadableScreen
                className="live-journey-screen"
                enableMenuButton={true}
                onMenuButtonClick={() => {
                    this.setState({ showMenu: true });
                }}
                title={this.state.journey && "Live journey"}
                postContent={(
                    <>
                        <IonActionSheet
                            isOpen={this.state.showMenu}
                            onDidDismiss={() => {
                                this.setState({ showMenu: false });
                            }}
                            buttons={[
                                {
                                    text: 'Edit journey',
                                    handler: () => {
                                        this.navigation.push(`/main/edit-journey/${this.state.journey?.id}`);
                                    },
                                }, 
                                {
                                    text: 'Complete journey',
                                    cssClass: "mt-2",
                                    handler: this.onCompleteJourneyClicked,
                                }, 

                                {
                                    text: 'Delete journey',
                                    role: "destructive",
                                    handler: this.onDeleteJourneyClicked,
                                }, 
                            ]}
                        />
                    </>
                )}
                onBeforeRefresh={this.onBeforeRefresh}
                onLoad={this.onLoad}                
                >
                <div className="flex flex-col items-stretch">
                    
                    <div className="flex flex-row items-center w-full">
                        <IonItem
                            detail={false}
                            lines="none"
                            routerLink={`/main/profile/${this.state.profile?.userId}`}
                            className="flex flew-row flex-grow items-center w-full ml-4"
                            >                                
                            <ProfilePhoto 
                                size={40}
                                photo={this.state.profile?.photo}
                                />

                            <div className="flex flex-col ml-2">
                                <div className="text-profile">{this.state.profile?.screenName}</div>
                                <div className="text-time">{dateUpdated}</div>
                            </div>
                        </IonItem>

                        <div className="flex-grow" />

                        <IonButton
                            fill="clear"
                            routerLink={`/main/map/${this.state.journey?.id}`}
                            >
                            <IonIcon 
                                icon={mapOutline} 
                                slot="icon-only"
                                className="text-black"
                                />
                        </IonButton>
                    </div>

                    <div className="text-title ml-4">
                        {this.state.journey?.title}
                    </div>

                    <div className="mt-2 ml-4">
                        <Description
                            text={this.state.journey?.description ?? ""}
                            />
                    </div>

                    <div className="mt-2 w-full h-0 page-border"></div>

                    <div
                        className="card mt-4 ml-4 mr-4 mb-2"
                        >

                        <PostEditor
                            text={this.state.text}
                            working={this.state.working}
                            mediaAssets={[]}
                            mediaFiles={this.state.mediaFiles}
                            location={this.state.location}
                            date={this.state.date}
                            time={this.state.time}
                            onSelectMediaAssets={this.onSelectMediaAssets}
                            onMediaFileDeleted={this.onMediaFileDeleted}
                            onFieldChanged={this.onFieldChanged}
                            onLocationSelected={this.onLocationSelected}
                            onDateSelected={this.onDateSelected}
                            onTimeSelected={this.onTimeSelected}
                            />          
                    </div>

                    <div className="flex flex-col w-full mt-0 pl-6 pr-6"> 
                        <IonButton 
                            className="main-button h-14 w-full"
                            onClick={this.onPostClicked}
                            disabled={!this.state.validated || this.state.working}
                            >
                            Post
                        </IonButton>
                    </div>

                    <div className="mt-2 w-full h-0 page-border"></div>

                    {this.state.journey
                        && <ViewJourney journey={this.state.journey} />
                    }
                    
                </div>

                <IonAlert
                    isOpen={this.state.showDeleteJourneyConfirmation}
                    onDidDismiss={() => this.setState({ showDeleteJourneyConfirmation: false })}
                    header="Delete journey"
                    message="Are you sure you want to delete this journey? It will also delete all posts for the journey"
                    buttons={[
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            handler: () => this.setState({ showDeleteJourneyConfirmation: false }),
                        },
                        {
                            text: 'OK',
                            role: "destructive",
                            handler: this.onConfirmDeleteJourneyClicked,
                        },
                    ]}                    
                    />

            </LoadableScreen>
       );
    }
}
