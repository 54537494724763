//
// Authentication service for use by the UI.
//

import axios from 'axios';
import { AxiosResponse, AxiosRequestConfig } from 'axios';
import { IEventSource, EventSource, BasicEventHandler } from '../utils/event-source';
import { InjectableClass, InjectProperty, InjectableSingleton } from '@codecapers/fusion';

import { Capacitor, Plugins } from '@capacitor/core';
import { getImageResolution, loadFile, loadImageElement, resizeImage, resizeImageData } from '../utils/image';
import { IMediaAsset } from '../model/model';
import { v4 } from 'uuid';
import LogRocket from 'logrocket';
import { handleAsync } from '../utils/async-handler';
import { extractVideoThumbnail, getVideoResolution } from '../utils/video';
const { Storage } = Plugins;
const { PushNotifications } = Plugins;

const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');

export interface IAuthResponse {
    ok: boolean;
    errorMessage?: string;
}

export const BASE_URL = "https://api.theonrouteapp.com";
// export const BASE_URL = "http://localhost:5001";

//
// Represents a media asset to be uploaded.
//
export interface IMediaFile {

    //
    // The local file for the media asset.
    //
    file: File;
}


export interface IAuthentication {

    //
    // Event raised when user has signed in.
    //
    onSignedIn: IEventSource<BasicEventHandler>;

    //
    // Event raised when user has signed out.
    //
    onSignedOut: IEventSource<BasicEventHandler>;

    //
    // Event raised when the signin in check has been completed.
    //
    onSigninCheckCompleted: IEventSource<BasicEventHandler>;

    //
    // Event raised if the app goes offline.
    //
    onDisconnected: IEventSource<BasicEventHandler>;

    //
    // Check if we can connect to the server.
    //
    checkConnectivity(): Promise<boolean>;

    //
    // Returns true if a user is currently known to be authenticated.
    //
    isSignedIn(): boolean;

    //
    // Asynchronously check if the user is currently signed in.
    //
    checkSignedIn(): Promise<boolean>;

    //
    // Returns true if a signin check with the server has completed.
    //
    signinCheckCompleted(): boolean;

    //
    // Sign a user in.
    //
    authenticate(email: string, password: string): Promise<IAuthResponse>;

    //
    // Sign a user out.
    //
    signout(): Promise<void>;

    //
    // Register a new user.
    //
    register(email: string, password: string): Promise<IAuthResponse>;

    //
    // Resend a confirmation email to an unconfirmed user.
    //
    resendConfirmationEmail(email: string): Promise<void>;

    //
    // Request a password reset for a user.
    //
    requestPasswordReset(email: string): Promise<void>;

    //
    // Set a user's password to a new value.
    //
    resetPassword(email: string, password: string, token: string): Promise<IAuthResponse>;

    //
    // Set a user's password to a new value.
    //
    updatePassword(password: string): Promise<void>;

    //
    // Confirm user's account.
    //
    confirm(email: string, token: string): Promise<IAuthResponse>;

    //
    // Make an authenticated HTTP GET request and return the response.
    //
    get<T = any>(route: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>>;

    //
    // Make an authenticated HTTP POST request and return the response.
    //
    post<T = any>(route: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>>;

    //
    // Make an authenticated HTTP PUT request and return the response.
    //
    put<T = any>(route: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>>;

    //
    // Make an authenticated HTTP DELETE request and return the response.
    //
    delete<T = any>(route: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>>;

    //
    // Create an authenticated version of a URL.
    //
    makeAuthenticatedUrl(baseUrl: string, params?: any): string;

    //
    // Get a named configuration value.
    //
    getConfigValue<T = any>(valueName: string, defaultValue: T): T;

    //
    // Resizes and uploads an asset to the backend.
    // This code should really be here, but it's a good place to share it.
    //
    uploadMediaAsset(mediaFile: IMediaFile): Promise<IMediaAsset>;

    //
    // Deletes a media asset that is no longer needed.
    //
    deleteMediaAsset(mediaAsset: IMediaAsset): Promise<void>;
}

//
// Unsure of preferred naming convention here regarding the const.
//
export const IAuthentication_id = "IAuthentication"

@InjectableSingleton(IAuthentication_id)
export class Authentication implements IAuthentication {

    //
    // Configuration downloaded from the backend.
    //
    private config: any = {};

    //
    // The authentication token allocated once signed in.
    //
    private authToken: string | undefined = undefined;

    //
    // Current user id, once signed in.
    //
    private id: string | undefined = undefined;

    //
    // Set to true when the signin check has been completed.
    //
    private signedInCheck: boolean = false;

    //
    // Event raised when user has signed in.
    //
    onSignedIn: IEventSource<BasicEventHandler> = new EventSource<BasicEventHandler>();

    //
    // Event raised when user has signed out.
    //
    onSignedOut: IEventSource<BasicEventHandler> = new EventSource<BasicEventHandler>();

    //
    // Event raised when the signin in check has been completed.
    //
    onSigninCheckCompleted: IEventSource<BasicEventHandler> = new EventSource<BasicEventHandler>();

    //
    // Event raised if the app goes offline.
    //
    onDisconnected: IEventSource<BasicEventHandler> = new EventSource<BasicEventHandler>();

    private constructor() {

        // Private constructor.

        axios.post(`${BASE_URL}/api/start`)
            .then(response => {
                this.config = response && response.data || {};
            })
            .catch(err => {
                console.error(`Failed to contact backend.`);
                console.error(err && err.stack || err);
            });

        if (isPushNotificationsAvailable) {
            PushNotifications.addListener("registration", deviceToken => {
                if (this.id) {
                    handleAsync(async  () => {
                        // Register device token against this user.
                        await this.post("/api/register-device", {
                            userId: this.id,
                            deviceToken: deviceToken.value,
                        });
                    });
                }
            });
        }
    }

    //
    // Check if we can connect to the server.
    //
    async checkConnectivity(): Promise<boolean> {
        try {
            await axios.get(`${BASE_URL}/alive`)
            return true;
        }
        catch (err) {
            return false;
        }
    }

    //
    // Get a named configuration value.
    //
    getConfigValue<T = any>(valueName: string, defaultValue: T): T {
        if (!this.config) {
            return defaultValue;
        }

        const nameParts = valueName.split(".");
        let working = this.config;
        for (const part of nameParts) {
            working = working[part];
            if (working === undefined) {
                return defaultValue;
            }
        }

        return working;
    }

    //
    // Gets the current auth token (lazy loading it from storage the first time).
    //
    private async getAuthToken(): Promise<string | undefined>  {
        if (this.authToken) {
            return this.authToken;
        }

        this.authToken = (await Storage.get({ key: "t" })).value || undefined;
        this.id = (await Storage.get({ key: "id" })).value || undefined;

        if (this.id) {
            console.log(`Restored user ${this.id}.`);
        }

        return this.authToken;
    }

    //
    // Returns true if a user is currently known to be authenticated.
    //
    isSignedIn(): boolean {
        return this.authToken !== undefined;
    }

    //
    // Asynchronously check if the user is currently signed in.
    //
    async checkSignedIn(): Promise<boolean> {
        const token = await this.getAuthToken();
        if (token) {
            // Validate token.
            const response = await axios.post(BASE_URL + "/api/auth/validate", {
                token: token,
            });

            if (!response.data.ok) {
                // Not validated.
                await this.updateSignedinState(undefined, undefined);
                return false;
            }
            else {
                await this.updateSignedinState(token, response.data.id);
            }
        }
        else {
            // Not signed in.
            await this.updateSignedinState(undefined, undefined);
        }

        return this.isSignedIn();
    }

    //
    // Update signedin state.
    //
    private async updateSignedinState(authToken: string | undefined, id: string | undefined): Promise<void> {
        if (authToken === undefined) {
            await Storage.remove({ key: "t" });
            await Storage.remove({ key: "id" });
        }
        else {
            await Storage.set({ key: "t", value: authToken });

            if (id) {
                await Storage.set({ key: "id", value: id });
                LogRocket.identify(id);
            }

            await this.registerForPushNotifications();
        }

        const signedIn = this.authToken === undefined && authToken !== undefined;
        const signedOut = this.authToken !== undefined && authToken === undefined;


        this.authToken = authToken;
        this.id = id;

        if (id) {
            console.log(`Current user is ${id}.`);
        }

        this.signedInCheck = true;
        if (signedIn) {
            await this.onSignedIn.raise();
        }
        else if (signedOut) {
            await this.onSignedOut.raise();
        }

        await this.onSigninCheckCompleted.raise();
    }

    //
    // Returns true if a signin check with the server has completed.
    //
    signinCheckCompleted(): boolean {
        return this.signedInCheck;
    }

    //
    // Authenticates a user.
    //
    async authenticate(email: string, password: string): Promise<IAuthResponse> {
        const response = await this._post("/api/auth/authenticate", {
            email: email,
            password: password,
        });

        if (response.data.ok) {
            await this.updateSignedinState(response.data.token, response.data.id);
        }
        else {
            await this.updateSignedinState(undefined, undefined);
        }

        return response.data;
    }

    //
    // Registers the user for push notifications.
    //
    private async registerForPushNotifications(): Promise<void> {
        if (isPushNotificationsAvailable) {
            //
            // Register the user for push notifications, if not already.
            //
            const response = await PushNotifications.requestPermission();
            if (response.granted) {
                await PushNotifications.register();
            }
        }
    }

    //
    // Sign a user out.
    //
    async signout(): Promise<void> {
        await this.updateSignedinState(undefined, undefined);
    }

    //
    // Register a new user.
    //
    async register(email: string, password: string): Promise<IAuthResponse> {
        const response = await this._post("/api/auth/register", {
            email,
            password,
        });

        return response.data;
    }

    //
    // Resend a confirmation email to an unconfirmed user.
    //
    async resendConfirmationEmail(email: string): Promise<void> {
        await this._post("/api/auth/resend-confirmation-email", {
            email: email,
        });
    }

    //
    // Request a password reset for a user.
    //
    async requestPasswordReset(email: string): Promise<void> {
        await this._post("/api/auth/request-password-reset", {
            email: email,
        });
    }

    //
    // Set a user's password to a new value.
    //
    async resetPassword(email: string, password: string, token: string): Promise<IAuthResponse> {
        const response = await this._post("/api/auth/reset-password", {
            email: email,
            password: password,
            token: token,
        });

        return response.data;
    }

    //
    // Set a user's password to a new value.
    //
    async updatePassword(password: string): Promise<void> {
        await this.post("/api/auth/update-password", {
            password: password,
        });
    }

    //
    // Confirm user's account.
    //
    async confirm(email: string, token: string): Promise<IAuthResponse> {
        const response = await this._post("/api/auth/confirm", {
            email: email,
            token: token,
        });

        return response.data;
    }

    //
    // Create the Axios configuration object.
    //
    private async makeConfiguration(config?: AxiosRequestConfig): Promise<AxiosRequestConfig> {
        if (!config) {
            config = {};
        }
        else {
            config = Object.assign({}, config); // Clone so as not to modify original.
        }

        const authToken = await this.getAuthToken();
        if (authToken) {

            if (!config.headers) {
                config.headers = {};
            }

            config.headers.Authorization = `Bearer ${authToken}`;
        }

        return config;
    }

    //
    // Makes an unauthenticated HTTP GET request.
    //
    private async _get<T>(route: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        try {
            return await axios.get(BASE_URL + route, config);
        }
        catch (err) {
            const connected = await this.checkConnectivity();
            if (!connected) {
                await this.onDisconnected.raise();
            }

            throw err;
        }
    }

    //
    // Makes an unauthenticated HTTP POST request.
    //
    private async _post<T = any>(route: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        try {
            return await axios.post(BASE_URL + route, data, config);
        }
        catch (err) {
            const connected = await this.checkConnectivity();
            if (!connected) {
                await this.onDisconnected.raise();
            }

            throw err;
        }
    }

    //
    // Makes an unauthenticated HTTP DELETE request.
    //
    private async _delete<T = any>(route: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        try {
            return await axios.delete(BASE_URL + route, config);
        }
        catch (err) {
            const connected = await this.checkConnectivity();
            if (!connected) {
                await this.onDisconnected.raise();
            }

            throw err;
        }
    }

    //
    // Make an authenticated HTTP GET request and return the response.
    //
    async get<T>(route: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        const modifiedConfig = await this.makeConfiguration(config);
        try {
            return await this._get(route, modifiedConfig);
        }
        catch (err) {
            if (err.response && err.response.status === 401) {
                // Token has expired, no longer authenticated.
                await this.updateSignedinState(undefined, undefined);
            }
            throw err;
        }
    }

    //
    // Make an authenticated HTTP POST request and return the response.
    //
    async post<T = any>(route: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        const modifiedConfig = await this.makeConfiguration(config);
        try {
            return await this._post(route, data, modifiedConfig);
        }
        catch (err) {
            if (err.response && err.response.status === 401) {
                // Token has expired, no longer authenticated.
                await this.updateSignedinState(undefined, undefined);
            }
            throw err;
        }
    }

    //
    // Make an authenticated HTTP PUT request and return the response.
    //
    async put<T = any>(route: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        const modifiedConfig = await this.makeConfiguration(config);
        try {
            return await axios.put(BASE_URL + route, data, modifiedConfig);
        }
        catch (err) {
            if (err.response && err.response.status === 401) {
                // Token has expired, no longer authenticated.
                await this.updateSignedinState(undefined, undefined);
            }
            throw err;
        }
    }

    //
    // Make an authenticated HTTP DELETE request and return the response.
    //
    async delete<T = any>(route: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        const modifiedConfig = await this.makeConfiguration(config);
        try {
            return await this._delete(route, modifiedConfig);
        }
        catch (err) {
            if (err.response && err.response.status === 401) {
                // Token has expired, no longer authenticated.
                await this.updateSignedinState(undefined, undefined);
            }
            throw err;
        }
    }

    //
    // Create an authenticated version of a URL.
    //
    makeAuthenticatedUrl(baseUrl: string, params?: any): string {
        if (!params) {
            params = { t: this.authToken };
        }
        else {
            params = Object.assign({}, params, { t: this.authToken });
        }

        let first = true;
        let url = BASE_URL + baseUrl;

        for (const key of Object.keys(params)) {
            if (first) {
                first = false;
                url += `?`;
            }
            else {
                url += '&';
            }

            url += `${key}=${params[key]}`;
        }

        return url;
    }

    //
    // Resizes and uploads an asset to the backend.
    // This code should really be here, but it's a good place to share it.
    //
    private async resizeandUploadImage(assetId: string, contentType: string, image: HTMLImageElement, maxSize: number): Promise<void> {
        console.log(`Uploading media asset type ${contentType} with max dimension ${maxSize}.`);
        const resizedImageData = await resizeImage(image, contentType, maxSize);
        const imageBlob = await fetch(resizedImageData).then(res => res.blob()); // Fetch base64 encoded image data to blog that can be uploaded.
        const uploadRoute = `/api/asset/upload?id=${assetId}`;
        await this.post(uploadRoute, imageBlob, {
            headers: {
                "content-type": contentType,
            },
        });
    }

    //
    // Extracts, resizes and uploads a video thumbnail to the backend.
    //
    private async uploadVideoThumbnail(assetId: string, contentType: string, file: File, maxSize: number): Promise<void> {
        console.log(`Uploading video thumbnail ${contentType} with max dimension ${maxSize}.`);

        let imageData = await extractVideoThumbnail(file, "image/jpeg");
        imageData = await resizeImageData(imageData, "image/jpeg", maxSize);
        const imageBlob = await fetch(imageData).then(res => res.blob()); // Fetch base64 encoded image data to blob that can be uploaded.
        const uploadRoute = `/api/asset/upload?id=${assetId}`;
        await this.post(uploadRoute, imageBlob, {
            headers: {
                "content-type": "image/jpeg",
            },
        });
    }

    //
    // Uploads the raw media file without any resizing.
    //
    private async uploadRawFile(assetId: string, contentType: string, file: File): Promise<void> {
        const uploadRoute = `/api/asset/upload?id=${assetId}`;
        await this.post(uploadRoute, file, {
            headers: {
                "content-type": contentType,
            },
        });
    }

    //
    // Generates a thumbnail and small image and uploads them.
    //
    async uploadMediaAsset(mediaFile: IMediaFile): Promise<IMediaAsset> {
        const assetId = v4();
        const contentType = mediaFile.file.type;
        const isVideo = contentType.startsWith("video");
        if (isVideo) {
            await Promise.all([
                this.uploadVideoThumbnail(
                    `thumb/${assetId}`,
                    contentType,
                    mediaFile.file,
                    this.getConfigValue("thumbSize", 512)
                ),
                this.uploadRawFile(
                    `full/${assetId}`,
                    contentType,
                    mediaFile.file
                ),
            ]);

            const mediaAsset: IMediaAsset = {
                id: assetId,
                contentType: contentType,
                resolution: await getVideoResolution(mediaFile.file),
            };
            return mediaAsset;
        }
        else {
            const imageData = await loadFile(mediaFile.file);
            const image = await loadImageElement(imageData);

            await Promise.all([
                this.resizeandUploadImage(
                    `thumb/${assetId}`,
                    contentType,
                    image,
                    this.getConfigValue("thumbSize", 256)
                ),
                this.resizeandUploadImage(
                    `full/${assetId}`,
                    contentType,
                    image,
                    this.getConfigValue("fullSize", 2048)
                ),
            ]);

            const mediaAsset: IMediaAsset = {
                id: assetId,
                contentType: contentType,
                resolution: getImageResolution(image),
            };
            return mediaAsset;
            }
    }

    //
    // Deletes a media asset that is no longer needed.
    //
    async deleteMediaAsset(mediaAsset: IMediaAsset): Promise<void> {
        const assetId = mediaAsset.id;
        await this.delete(`/api/asset?id=thumb/${assetId}`);
        await this.delete(`/api/asset?id=full/${assetId}`);
    }

}
