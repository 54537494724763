import * as React from 'react';
import L from "leaflet";
import { asyncHandler } from '../utils/async-handler';
import { ILocation } from '../model/model';
import { InjectableClass, InjectProperty } from '@codecapers/fusion';
import { IAuthentication, IAuthentication_id } from '../services/authentication';
import "./LocationPicker.scss"
import { updateState } from '../utils/update-state';
import { SearchBox } from './SearchBox';
import Map from "./Map";

const DEFAULT_ZOOM = 7;

export interface ILocationPickerProps {
    //
    // Initial location to focus on.
    // If not specifed it defaults to users current location.
    //
    initialLocation?: ILocation;

    //
    // Event raised when user has selected a new location.
    //
    onLocationUpdated?: (location: ILocation) => void;
}

interface ILocationPickerState {
    //
    // This is a key that's used to reset the search box.
    //
    searchBoxKey: number;

    //
    // Results of the search.
    //
    searchLocations: ILocation[];
}

@InjectableClass()
export default class LocationPicker extends React.Component<ILocationPickerProps, ILocationPickerState> {

    @InjectProperty(IAuthentication_id)
    authentication!: IAuthentication;

    //
    // Reference to the map's HTML element.
    //
    private mapRef: React.RefObject<Map>;

    //
    // Leaflet map instance, once loaded.
    //
    private map?: L.Map;

    //
    // Selected location on the map.
    //
    private locationMarker?: L.Marker<any>;
    
    constructor(props: ILocationPickerProps) {
        super(props);

        this.state = {
            searchBoxKey: 1,
            searchLocations: [],
        };

        this.mapRef = React.createRef<Map>();

        this.componentDidMount = asyncHandler(this, this.componentDidMount);
        this.onMapLoaded = this.onMapLoaded.bind(this);
        this.onMapClicked = this.onMapClicked.bind(this);
        this.onSearchTextUpdated = asyncHandler(this, this.onSearchTextUpdated);
    }

    //
    // Event raised when the map has been loaded.
    //
    async componentDidMount(): Promise<void> {
        await this.onMapLoaded();
    }

    //
    // Event raised when the map has been loaded.
    //
    private async onMapLoaded(): Promise<void> {
        if (this.mapRef.current) {
            this.map = this.mapRef.current!.getMap();
            if (this.map) {
        
                const initialLocation = this.mapRef.current!.getInitialLocation()!;
        
                if (this.props.onLocationUpdated) {
                    this.props.onLocationUpdated(initialLocation);
                }

                if (this.locationMarker) {
                    this.locationMarker.setLatLng([ initialLocation.latitude, initialLocation.longitude ]);
                }
                else {
                    this.locationMarker = L.marker([ initialLocation.latitude, initialLocation.longitude ])
                        .addTo(this.map!);
                }        
            }
        }
    }

    componentWillUnmount() {
        if (this.locationMarker) {
            this.locationMarker.remove();
            this.locationMarker = undefined;
        }
    }

    //
    // Updates the search text.
    //
    private async onSearchTextUpdated(searchText: string): Promise<void> {

        const response = await this.authentication.get(`/api/geocode?place=${searchText}`);
        await updateState(this, {
            searchLocations: response.data,
        });
    }

    //
    // User has selected a search location.
    //
    private async onSelectSearchLocation(location: ILocation): Promise<void> {
        const latLng = L.latLng(location.latitude, location.longitude);

        if (this.locationMarker) {
            this.locationMarker.setLatLng(latLng);
        }

        if (this.map) {
            this.map.setView(latLng, DEFAULT_ZOOM);
        }

        if (this.props.onLocationUpdated) {
            this.props.onLocationUpdated(location);
        }

        await updateState(this, {
            searchBoxKey: this.state.searchBoxKey + 1,
            searchLocations: [],
        });
    }

    //
    // Event raised when the map is clicked.
    //
    private async onMapClicked(location: ILocation): Promise<void> {
        if (this.locationMarker) {
            this.locationMarker.setLatLng([ location.latitude, location.longitude ]);
        }

        if (location.name === undefined) {
            // Find the location name.
            const response = await this.authentication.post("/api/reverse-geocode", { location: location });
            const reverseGeocodedLocation = response.data as ILocation;
            if (reverseGeocodedLocation.name) {
                location.name = reverseGeocodedLocation.name;
            }
        }

        if (this.props.onLocationUpdated) {
            this.props.onLocationUpdated(location);
        }
    }

    render() {
        return (
            <div
                className="location-picker flex flex-col flex-grow w-full relative"
                >

                <div
                    className="absolute"
                    style={{
                        left: 30,
                        right: 30,
                        top: 15,
                        width: "auto",
                        zIndex: 2000,
                    }}
                    >
                    <SearchBox
                        key={this.state.searchBoxKey}
                        onSearchTextUpdated={this.onSearchTextUpdated}
                        />

                    {this.state.searchLocations.length > 0
                        && <div 
                            className="bg-white rounded bg-opacity-90 ml-4 mr-4 overflow-y-scroll"
                            style={{
                                maxHeight: "20em",
                            }}
                            >
                            <div>
                                {this.state.searchLocations.map((searchLocation, index) => {
                                    return (
                                        <div
                                            className="mb-2 p-3 border-0 border-b border-gray-200 border-solid"
                                            key={`${searchLocation.name}-${index}`}
                                            onClick={() => this.onSelectSearchLocation(searchLocation)}
                                            >
                                            {searchLocation.name}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    }
                </div>

                <Map
                    ref={this.mapRef}
                    cacheKey="location-picker"
                    initialLocation={this.props.initialLocation}                    
                    defaultToUserLocation={true}
                    onLoaded={this.onMapLoaded}
                    onClicked={this.onMapClicked}
                    tileLayer={this.authentication.getConfigValue("locationPicker.tileLayer", {
                            urlTemplate: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                            options: {
                                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                            },
                        }) 
                    }
                    zoom={this.authentication.getConfigValue("locationPicker.zoom", {
                        min: 0,
                        max: 16,
                    })}
                    />                
            </div>
        );
   }
}

