//
// Posts service for use by the UI.
//

import { InjectProperty, InjectableSingleton } from '@codecapers/fusion';
import { IAuthentication, IAuthentication_id } from './authentication';
import { ICommentData, IJourneyData, IJourneyDataUpdate, INewCommentData, INewJourneyData, INewPostData, INotificationData, IPostData, IPostDataUpdate, IProfileData, IProfileDataUpdate } from '../model/model';
import moment from 'moment';

export interface IRepository {
    //
    // Clears part of the cache.
    //
    clearCache(cacheKey?: string): void;   

    //
    // Starts a new journey.
    //
    startNewJourney(newJourneyData: INewJourneyData): Promise<string>;

    //
    // Gets the user's current live journey.
    //
    getLiveJourney(): Promise<IJourneyData | null>;

    //
    // Loads a specific journey.
    //
    loadJourney(id: string): Promise<IJourneyData>;

    //
    // Updates an existing journey.
    //
    saveJourney(id: string, journeyUpdates: IJourneyDataUpdate): Promise<void>;

    //
    // Deletes a journey.
    //
    deleteJourney(id: string): Promise<void>;

    //
    // Loads journeys for a user (or all users).
    //
    loadJourneys(userId: string | undefined, limit: number, loadMore: boolean): Promise<ICachedList<IJourneyData>>;

    //
    // Adds a new post to a journey.
    //
    addNewPost(newPostData: INewPostData): Promise<string>;

    //
    // Loads a post.
    //
    loadPost(id: string): Promise<IPostData>;

    //
    // Updates an existing post.
    //
    savePost(id: string, postUpdates: IPostDataUpdate): Promise<void>;

    //
    // Deletes an existing post.
    //
    deletePost(id: string): Promise<void>;

    //
    // Likes a post
    //
    likePost(postId: string): Promise<void>;

    //
    // Unlikes a post
    //
    unlikePost(postId: string): Promise<void>;

    //
    // Adds a comment to a post.
    //
    addNewComment(newCommentData: INewCommentData): Promise<void>;

    //
    // Loads comment for a particular post.
    //
    loadCommentsForPost(postId: string, limit: number, loadMore: boolean): Promise<ICachedList<ICommentData>>;

    //
    // Deletes a comment.
    //
    deleteComment(id: string): Promise<void>;

    //
    // Loads the users feed.
    //
    loadFeed(limit: number, loadMore: boolean): Promise<ICachedList<IPostData>>;

    //
    // Loads posts for a particular user.
    //
    loadPosts(userId: string, limit: number, loadMore: boolean): Promise<ICachedList<IPostData>>;

    //
    // Loads posts for a particular journey.
    //
    loadPostsForJourney(journeyId: string, limit: number, loadMore: boolean): Promise<ICachedList<IPostData>>;

    //
    // Loads the profile for the current user or a specific user.
    //
    loadProfileForDisplay(id?: string): Promise<IProfileData>;

    //
    // Loads the current user profile for editing.
    //
    loadProfileForEdit(): Promise<IProfileData>;

    //
    // Updates the current user's profile with new data.
    //
    saveProfile(profileUpdates: IProfileDataUpdate): Promise<void>;

    //
    // Follows a user.
    //
    followUser(toUserId: string): Promise<void>;

    //
    // Unfollows a user.
    //
    unfollowUser(toUserId: string): Promise<void>;

    //
    // Loads the user's notifications.
    //
    loadNotifications(limit: number, loadMore: boolean): Promise<ICachedList<INotificationData>>;
}

// 
// ID of the deendency.
// 
export const IRepository_id = "IRepository" 

//
// A list that has been cached in memory.
//
export interface ICachedList<T> {
    //
    // Loaded data.
    //
    data: T[];

    //
    // Set to true when there is more data to load.
    //
    haveMore: boolean;
}

interface ICache {
    [cacheKey: string]: any;
}

@InjectableSingleton(IRepository_id)
class Repository implements IRepository {

    @InjectProperty(IAuthentication_id)
    authentication!: IAuthentication;

    //
    // Caches downloaded objects.
    //
    private cache: ICache = {};

    private constructor() {
        // Private constructor.
    }

    //
    // Clears part of the cache.
    //
    clearCache(cacheKey?: string): void {
        if (cacheKey === undefined) {
            this.cache = {}; // Wipe out the cache.
            return;
        }

        const cacheKeyParts = cacheKey.split(".");
        let working = this.cache;
        
        for (let i = 0; i < cacheKeyParts.length-1; ++i) {
            working = this.cache[cacheKeyParts[i]];
            if (working === undefined) {
                return; // Nothing to clear.
            }
        }

        delete working[cacheKeyParts[cacheKeyParts.length-1]];
    }

    //
    // Starts a new journey.
    //
    async startNewJourney(newJourneyData: INewJourneyData): Promise<string> {

        this.clearCache();

        const journeyData = Object.assign({}, newJourneyData, {
            status: "live",
        });

        const query = {
            update: {
                journey: {                        
                    args: {
                        params: journeyData,
                    },
                },
            },
        };

        const response = await this.authentication.post("/api/query", { query: query });
        return response.data.result.journey.id;
    }

    //
    // Gets the user's current live journey.
    //
    async getLiveJourney(): Promise<IJourneyData | null> {

        if (this.cache.liveJourney) {
            return this.cache.liveJourney;
        }

        const query = {
            get: {
                liveJourney: {                        
                    resolve: {
                        posts: {
                        },
                    }
                },
                profile: {
                },
            },
        };

        const response = await this.authentication.post("/api/query", { query: query });
        const liveJourney: IJourneyData = response.data.result.liveJourney;
        if (liveJourney) {
            liveJourney.posts.sort((a, b) => {
                const aDate = moment(a.date + " " + a.time, "YYYY-MM-DD HH:mm").toDate();
                const bDate = moment(b.date + " " + b.time, "YYYY-MM-DD HH:mm").toDate();
                return bDate.getTime() - aDate.getTime();
            })
            this.cache.liveJourney = liveJourney;
        }
        return liveJourney;
    }

    //
    // Loads a specific journey.
    //
    async loadJourney(id: string): Promise<IJourneyData> {
        if (!this.cache.journey) {
            this.cache.journey = {};
        }

        let journey: IJourneyData = this.cache.journey[id];
        if (journey) {
            return journey;
        }

        const query = {
            get: {
                journey: {                        
                    args: {
                        id: id,
                    },
                    resolve: {
                        posts: {
                        },
                    }
                },
            },
        };

        const response = await this.authentication.post("/api/query", { query: query });
        journey = response.data.result.journey;
        if (journey) {
            journey.posts.sort((a, b) => {
                const aDate = moment(a.date + " " + a.time, "YYYY-MM-DD HH:mm").toDate();
                const bDate = moment(b.date + " " + b.time, "YYYY-MM-DD HH:mm").toDate();
                return aDate.getTime() - bDate.getTime();
            });
        }
        this.cache.journey[id] = journey;        
        return journey;
    }

    //
    // Updates an existing journey.
    //
    async saveJourney(id: string, journeyUpdates: IJourneyDataUpdate): Promise<void> {

        this.clearCache();

        const query = {
            update: {
                journey: {                        
                    args: {
                        id: id,
                        params: journeyUpdates,
                    },
                },
            },
        };
        await this.authentication.post("/api/query", { query: query });
    }

    //
    // Deletes a journey.
    //
    async deleteJourney(id: string): Promise<void> {
        this.clearCache();

        const query = {
            delete: {
                journey: {                        
                    args: {
                        id: id,
                    },
                },
            },
        };
        await this.authentication.post("/api/query", { query: query });
    }

    //
    // Loads journeys for a user.
    //
    async loadJourneys(userId: string | undefined, limit: number, loadMore: boolean): Promise<ICachedList<IJourneyData>> {
        if (!this.cache.journeys) {
            this.cache.journeys = {};
        }

        const cacheKey = userId ?? "*";
        if (!this.cache.journeys[cacheKey]) {
            this.cache.journeys[cacheKey] = {
                haveMore: true,
                data: [],
            };
        }

        const journeys = this.cache.journeys[cacheKey] as ICachedList<IJourneyData>;
        if (!journeys.haveMore) {
            return journeys;
        }

        if (journeys.data.length > 0 && !loadMore) {
            // 
            // Just return the initially loaded data, we don't need to load another page at this point.
            //
            return journeys;
        }

        //
        // Load more.
        //
        
        const query = {
            get: {
                journey: {
                    args: {
                        userId: userId,
                        skip: journeys.data.length,
                        limit: limit,
                    },
                },
            },
        };

        const response = await this.authentication.post("/api/query", { query: query });
        const loaded = response.data.result.journey;
        if (loaded.length === 0) {
            journeys.haveMore = false;
        }
        else {
            journeys.data = journeys.data.concat(loaded);
        }

        return journeys;        
    }

    //
    // Adds a new post to a journey.
    //
    async addNewPost(newPostData: INewPostData): Promise<string> {

        this.clearCache();

        const query = {
            update: {
                post: {                        
                    args: {
                        params: newPostData,
                    },
                },
            },
        };
        const response = await this.authentication.post("/api/query", { query: query });
        return response.data.result.post.id;
    }

    //
    // Loads a post.
    //
    async loadPost(id: string): Promise<IPostData> {

        if (!this.cache.post) {
            this.cache.post = {};
        }
        
        let post = this.cache.post[id];
        if (post) {
            return post;
        }

        const query = {
            get: {
                post: {                        
                    args: {
                        id: id,
                    },
                    resolve: {
                        journey: {                            
                        },
                        profile: {
                        },
                    },
                },
            },
        };
        const response = await this.authentication.post("/api/query", { query: query });
        post = response.data.result.post;
        this.cache.post[id] = post;
        return post;
    }

    //
    // Updates an existing post.
    //
    async savePost(id: string, postUpdates: IPostDataUpdate): Promise<void> {

        this.clearCache();

        const query = {
            update: {
                post: {                        
                    args: {
                        id: id,
                        params: postUpdates,
                    },
                },
            },
        };
        await this.authentication.post("/api/query", { query: query });
    }

    //
    // Deletes an existing post.
    //
    async deletePost(id: string): Promise<void> {
        this.clearCache();

        const query = {
            delete: {
                post: {                        
                    args: {
                        id: id,
                    },
                },
            },
        };
        await this.authentication.post("/api/query", { query: query });
    }


    //
    // Likes a post
    //
    async likePost(postId: string): Promise<void> {

        this.clearCache();

        const query = {
            update: {
                likePost: {                        
                    args: {
                        params: {
                            postId: postId,
                        }
                    }
                },
            },
        };
        await this.authentication.post("/api/query", { query: query });
    }

    //
    // Unlikes a post
    //
    async unlikePost(postId: string): Promise<void> {

        this.clearCache();

        const query = {
            delete: {
                likePost: {                        
                    args: {
                        params: {
                            postId: postId,
                        }
                    }
                },
            },
        };
        await this.authentication.post("/api/query", { query: query });
    }

    //
    // Adds a comment to a post.
    //
    async addNewComment(newCommentData: INewCommentData): Promise<void> {

        this.clearCache();

        const query = {
            update: {
                commentPost: {                        
                    args: {
                        postId: newCommentData.postId,
                        params: {
                            text: newCommentData.text,                            
                        },
                    },
                },
            },
        };
        this.authentication.post("/api/query", { query: query });
    }

    //
    // Loads comment for a particular post.
    //
    async loadCommentsForPost(postId: string, limit: number, loadMore: boolean): Promise<ICachedList<ICommentData>> {

        if (!this.cache.comments) {
            this.cache.comments = {};
        }

        if (!this.cache.comments[postId]) {
            this.cache.comments[postId] = {
                haveMore: true,
                data: [],
            };
        }

        const comments = this.cache.comments[postId] as ICachedList<ICommentData>;
        if (!comments.haveMore) {
            return comments;
        }

        if (comments.data.length > 0 && !loadMore) {
            // 
            // Just return the initially loaded data, we don't need to load another page at this point.
            //
            return comments;
        }

        //
        // Load more.
        //

        const query = {
            get: {
                commentPost: {
                    args: {
                        postId: postId,
                        skip: comments.data.length,
                        limit: limit,
                    },
                    resolve: {
                        profile: {
                        },
                    }
                },
            },
        };
        
        const response = await this.authentication.post("/api/query", { query: query });
        const loaded = response.data.result.commentPost;
        if (loaded.length === 0) {
            comments.haveMore = false;
        }
        else {
            comments.data = comments.data.concat(loaded);
        }

        return comments;
    }

    //
    // Deletes a comment.
    //
    async deleteComment(id: string): Promise<void> {
        this.clearCache();

        const query = {
            delete: {
                commentPost: {                        
                    args: {
                        id: id,
                    },
                },
            },
        };
        this.authentication.post("/api/query", { query: query });
    }

    //
    // Loads the users feed.
    //
    async loadFeed(limit: number, loadMore: boolean): Promise<ICachedList<IPostData>> {
        if (!this.cache.feed) {
            this.cache.feed = {
                haveMore: true,
                data: [],
            };
        }

        const feed = this.cache.feed as ICachedList<IPostData>;
        if (!feed.haveMore) {
            return feed;
        }

        if (feed.data.length > 0 && !loadMore) {
            // 
            // Just return the initially loaded data, we don't need to load another page at this point.
            //
            return feed;
        }

        //
        // Load more.
        //

        const query = {
            get: {
                feed: {
                    args: {
                        skip: feed.data.length,
                        limit: limit,
                    },
                },
            },
        };
        const response = await this.authentication.post("/api/query", { query: query });
        const loaded = response.data.result.feed;
        if (loaded.length === 0) {
            feed.haveMore = false;
        }
        else {
            feed.data = feed.data.concat(loaded);
        }
        return feed;
    }

    //
    // Loads posts for a particular user.
    //
    async loadPosts(userId: string, limit: number, loadMore: boolean): Promise<ICachedList<IPostData>> {

        if (!this.cache.posts) {
            this.cache.posts = {};
        }

        if (!this.cache.posts[userId]) {
            this.cache.posts[userId] = {
                haveMore: true,
                data: [],
            };
        }

        const posts = this.cache.posts[userId] as ICachedList<IPostData>;
        if (!posts.haveMore) {
            return posts;
        }

        if (posts.data.length > 0 && !loadMore) {
            // 
            // Just return the initially loaded data, we don't need to load another page at this point.
            //
            return posts;
        }

        //
        // Load more.
        //
        
        const query = {
            get: {
                post: {
                    args: {
                        userId: userId,
                        skip: posts.data.length,
                        limit: limit,
                    },
                },
            },
        };
        const response = await this.authentication.post("/api/query", { query: query });
        const loaded = response.data.result.post;
        if (loaded.length === 0) {
            posts.haveMore = false;
        }
        else {
            posts.data = posts.data.concat(loaded);
        }
        return posts;
    }

    //
    // Loads posts for a particular journey.
    //
    async loadPostsForJourney(journeyId: string, limit: number, loadMore: boolean): Promise<ICachedList<IPostData>> {

        if (!this.cache.journeyPosts) {
            this.cache.journeyPosts = {};
        }

        if (!this.cache.journeyPosts[journeyId]) {
            this.cache.journeyPosts[journeyId] = {
                haveMore: true,
                data: [],
            };
        }

        const posts = this.cache.journeyPosts[journeyId] as ICachedList<IPostData>;
        if (!posts.haveMore) {
            return posts;
        }

        if (posts.data.length > 0 && !loadMore) {
            // 
            // Just return the initially loaded data, we don't need to load another page at this point.
            //
            return posts;
        }

        //
        // Load more.
        //
        
        const query = {
            get: {
                post: {
                    args: {
                        journeyId: journeyId,
                        skip: posts.data.length,
                        limit: limit,
                    },
                },
            },
        };

        const response = await this.authentication.post("/api/query", { query: query });
        const loaded = response.data.result.post;
        if (loaded.length === 0) {
            posts.haveMore = false;
        }
        else {
            posts.data = posts.data.concat(loaded);
        }
        return posts;
    }

    //
    // Loads the profile for the current user or a specific user.
    //
    async loadProfileForDisplay(id?: string): Promise<IProfileData> {
        if (!this.cache.profile) {
            this.cache.profile = {};
        }

        const cacheKey = id ?? "current";
        let profile = this.cache.profile[cacheKey];
        if (profile) {
            return profile;
        }

        const query = {
            get: {
                profile: {                        
                    args: {
                        id: id,
                    },
                    resolve: {
                        info: {},
                    },
                },
            },
        }
        const response = await this.authentication.post("/api/query", { query: query });
        profile = response.data.result.profile;
        this.cache.profile[cacheKey] = profile;
        return profile;
    }

    //
    // Loads the current user profile for editing.
    //
    async loadProfileForEdit(): Promise<IProfileData> {
        const query = {
            get: {
                profile: {                        
                    args: {
                    },
                },
            },
        }
        const response = await this.authentication.post("/api/query", { query: query });
        return response.data.result.profile;
    }

    //
    // Updates the current user's profile with new data.
    //
    async saveProfile(profileDataUpdate: IProfileDataUpdate): Promise<void> {

        this.clearCache();

        const query = {
            update: {
                profile: {                    
                    args: {
                        params: profileDataUpdate,
                    },
                },
            },
        }
        await this.authentication.post("/api/query", { query: query });
    }

    //
    // Follows a user.
    //
    async followUser(toUserId: string): Promise<void> {

        this.clearCache();

        const query = {
            update: {
                follow: {                        
                    args: {
                        params: {
                            toId: toUserId,
                        }
                    }
                },
            },
        };
        await this.authentication.post("/api/query", { query: query });
    }

    //
    // Unfollows a user.
    //
    async unfollowUser(toUserId: string): Promise<void> {

        this.clearCache();

        const query = {
            delete: {
                follow: {                        
                    args: {
                        params: {
                            toId: toUserId,
                        }
                    }
                },
            },
        };
        await this.authentication.post("/api/query", { query: query });
    }

    //
    // Loads the user's notifications.
    //
    async loadNotifications(limit: number, loadMore: boolean): Promise<ICachedList<INotificationData>> {
        if (!this.cache.notifications) {
            this.cache.notifications = {
                haveMore: true,
                data: [],
            };
        }

        const notifications = this.cache.notifications as ICachedList<INotificationData>;
        if (!notifications.haveMore) {
            return notifications;
        }

        if (notifications.data.length > 0 && !loadMore) {
            // 
            // Just return the initially loaded data, we don't need to load another page at this point.
            //
            return notifications;
        }

        //
        // Load more.
        //

        const query = {
            get: {
                notification: {
                    args: {
                        skip: notifications.data.length,
                        limit: limit,
                    },
                },
            },
        };
        const response = await this.authentication.post("/api/query", { query: query });
        const loaded = response.data.result.notification;
        if (loaded.length === 0) {
            notifications.haveMore = false;
        }
        else {
            notifications.data = notifications.data.concat(loaded);
        }
        return notifications;
    }
}
