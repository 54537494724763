import { IonButton } from "@ionic/react";
import React from "react";
import Modal from "./Modal";

export interface IActionModalProps {
    //
    // Set to true to show the modal.
    //
    show: boolean;

    //
    // Title of the modal.
    //
    title: string;

    //
    // Set to true to enable the ok button.
    //
    enableOk: boolean;

    //
    // Title for the ok button.
    //
    okTitle: string;

    //
    // Event raised when the button has been clicked.
    //
    onOk: () => Promise<void>;

    //
    // Event raised when the modal is closed.
    //
    onClose: () => Promise<void>;
}

interface IActionModalState {

}

export default class ActionModal extends React.Component<IActionModalProps, IActionModalState> {

    render() {
        return (
            <Modal
                show={this.props.show}
                title={this.props.title}
                onClose={this.props.onClose}
                >
                <div 
                    className="flex flex-col items-center justify-center w-full h-full min-h-full"
                    >
                    <div 
                        className="flex flex-col flex-grow w-full"
                        style={{
                            borderBottom: "1px solid var(--ion-toolbar-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.2))))"
                        }}
                        >
                        {this.props.children}
                    </div>

                    <div className="flex flex-col w-full mt-0 mt-2 pl-6 pr-6 mb-16"> 
                        <IonButton 
                            className="main-button h-14 w-full"
                            onClick={this.props.onOk}
                            disabled={!this.props.enableOk}
                            >
                            {this.props.okTitle}
                        </IonButton>
                    </div>

                </div>
            </Modal>
        )        
    }
}