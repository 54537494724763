import { InjectableClass, InjectProperty } from "@codecapers/fusion";
import { IonButton, IonInput, IonItem, IonLabel, IonList, IonTextarea } from "@ionic/react";
import * as React from "react";
import { asyncHandler } from "../utils/async-handler";
import { updateState } from "../utils/update-state";
import { IRepository, IRepository_id } from "../services/repository";
import { IProfileDataUpdate } from "../model/model";
import { INotification, INotification_id } from "../services/notification";
import { IAuthentication, IAuthentication_id, IMediaFile } from "../services/authentication";
import { PhotoSelector } from "./PhotoSelector";

export interface IEditProfileProps {
    //
    // Text to display on the save button.
    //
    saveButtonTitle: string;

    //
    // Profile data to be edited.
    //
    profile?: IProfileDataUpdate;

    //
    // Event raised when saving the profile.
    //
    onSavingProfile: () => Promise<void>;

    //
    // Event raised when the profile has been saved.
    //
    onProfileSaved: () => Promise<void>;

    //
    // Event raised when failed to save the profile.
    //
    onSaveFailed: () => Promise<void>;
}

export interface IEditProfileState {
    //
    // Set to true when talking to the backend.
    //
    working: boolean;

    //
    // Set to true when details of the profile have changed.
    //
    changed: boolean;

    //
    // The user's unique handle.
    //
    handle: string;

    //
    // The name of the profile.
    //
    screenName: string;

    //
    // User's first name.
    //
    firstName: string;

    //
    // User's last name.
    //
    lastName: string; 

    //
    // The bio for the profile.
    //
    bio: string;

    //
    // The web link for the profile.
    //
    webLink: string;

    //
    // Photo for the user's profile.
    //
    profilePhoto?: IMediaFile;

    //
    // Set to true when user input is validated and can be saved.
    //
    validated: boolean;

    //
    // Error message to display to user, if any.
    //
    errorMessage?: string;
}

@InjectableClass()
export class EditProfile extends React.Component<IEditProfileProps, IEditProfileState> {

    @InjectProperty(IRepository_id)
    post!: IRepository;

    @InjectProperty(INotification_id)
    notification!: INotification;

    @InjectProperty(IAuthentication_id)
    authentication!: IAuthentication;

    //
    // Ref to the hidden file input, so it can be invoked programmatically.
    //
    private fileInputRef: React.RefObject<HTMLInputElement>;

    constructor(props: IEditProfileProps) {
        super(props);

        this.state = {
            working: false,
            changed: false,
            handle: this.props.profile?.handle || "",
            screenName: this.props.profile?.screenName || "",
            firstName: this.props.profile?.firstName || "",
            lastName: this.props.profile?.lastName || "",
            bio: this.props.profile?.bio || "",
            webLink: this.props.profile?.webLink || "",
            validated: true,
        };

        this.fileInputRef = React.createRef<HTMLInputElement>();

        this.onFieldChange = asyncHandler(this, this.onFieldChange);
        this.onSaveClick = asyncHandler(this, this.onSaveClick);
        this.onSelectMediaAsset = asyncHandler(this, this.onSelectMediaAsset);
    }

    //
    // Checks if the user's handle contains white space.
    //
    private checkHandleWhitespace(): boolean {
        return this.state.handle.trim().includes(" ")
            || this.state.handle.trim().includes("\t")
            || this.state.handle.trim().includes("\r")
            || this.state.handle.trim().includes("\n");
    }
    
    private async validate(): Promise<void> {
        let isValid = this.state.handle.trim().length > 0
            && this.state.screenName.trim().length > 0
            && this.state.firstName.trim().length > 0
            && this.state.lastName.trim().length > 0;

        let errorMessage: string | undefined;
        if (isValid) {
            isValid = !this.checkHandleWhitespace();
            if (!isValid) {
                errorMessage = "Handle cannot contain any spaces or other whitespace characters";
            }
        }

        if (isValid) {
            const response = await this.authentication.post("/api/check-handle", { handle: this.state.handle });
            isValid = response.data.ok;
            if (!isValid) {
                errorMessage = response.data.errorMessage || `The handle is already taken, please choose a different handle`;
            }
        }

        await updateState(this, {
            validated: isValid,
            errorMessage: errorMessage,
        }); 
    }

    private async onFieldChange(fieldName: string, value: string | null | undefined): Promise<void> {
        if (value === (this.state as any)[fieldName]) {
            // No change.
            return;
        }

        const newState: any = {
            changed: true,
        };

        newState[fieldName] = value;

        await updateState(this, newState);
        await this.validate();
    }

    private async onSaveClick(): Promise<void> {

        if (!this.state.validated) {
            return;
        }

        const profileDataUpdate: any = {};
        let updatesExist = false;

        const fields = ["handle", "screenName", "firstName", "lastName", "bio", "webLink"];
        for (const fieldName of fields) {
            const fieldValue = (this.state as any)[fieldName] as string;
            if (!this.props.profile || (fieldValue && fieldValue.length > 0 && fieldValue !== (this.props.profile as any).fieldName)) {
                profileDataUpdate[fieldName] = fieldValue.trim();
                updatesExist = true;
            }
        }

        if (!updatesExist && !this.state.profilePhoto) {
            await updateState(this, { 
                changed: false,
            });
            return;
        }

        try {
            await this.props.onSavingProfile();
            await updateState(this, { working: true });

            if (this.state.profilePhoto) {
                try {
                    profileDataUpdate.photo = await this.authentication.uploadMediaAsset(this.state.profilePhoto);
                    //todo: if user is changing media asset need to delete their previous photo.
                }
                catch (err) {
                    this.notification.error(`Failed to upload profile photo ${this.state.profilePhoto.file.name}.`);
                    return;    
                }
            }

            await this.post.saveProfile(profileDataUpdate);

            await updateState(this, { 
                changed: false,
                errorMessage: undefined,
            });

            await this.props.onProfileSaved();
        }
        catch (err) {
            console.error(`Failed to update profile.`);
            console.error(err && err.stack || err);
            this.notification.error("Failed to update your profile, please try again soon.");
            await this.props.onSaveFailed();
        }
        finally {
            await updateState(this, { 
                working: false, 
            });
        }
    }    
    
    //
    // User has requested to upload a media asset.
    //
    private async onSelectMediaAsset(file: File): Promise<void> {
        await updateState(this, { 
            profilePhoto: {
                file: file,
            },
            changed: true,
        });
    }

    render() {
        return (
            <>
                <IonList className="w-full">
                    <IonItem>
                        <IonLabel position="floating">Unique handle</IonLabel>
                        <IonInput
                            style={{
                                textTransform: "lowercase",
                            }}
                            name="unique-handle-input"
                            id="unique-handle-input" 
                            value={this.state.handle}
                            onIonChange={e => this.onFieldChange("handle", e.detail.value)}
                            disabled={this.state.working}

                            />
                    </IonItem>                            

                    <IonItem>
                        <IonLabel position="floating">Screen name</IonLabel>
                        <IonInput
                            name="screen-name-input"
                            id="screen-name-input" 
                            value={this.state.screenName}
                            onIonChange={e => this.onFieldChange("screenName", e.detail.value)}
                            autocapitalize="words"
                            disabled={this.state.working}
                            />
                    </IonItem>                            

                    <IonItem>
                        <IonLabel>Profile photo (optional)</IonLabel>

                        <PhotoSelector
                            photo={this.props.profile?.photo}
                            onSelectMediaAsset={this.onSelectMediaAsset}
                            />
                    </IonItem>                                                        

                    <IonItem>
                        <IonLabel position="floating">Bio (optional)</IonLabel>
                        <IonTextarea
                            name="bio-input"
                            id="bio-input" 
                            value={this.state.bio}
                            onIonChange={e => this.onFieldChange("bio", e.detail.value)}
                            autocapitalize="sentences"
                            autoGrow={true}
                            disabled={this.state.working}
                            />
                    </IonItem>                            

                    <IonItem>
                        <IonLabel position="floating">Your web page (optional)</IonLabel>
                        <IonInput
                            name="web-link-input"
                            id="web-link-input" 
                            value={this.state.webLink}
                            onIonChange={e => this.onFieldChange("webLink", e.detail.value)}
                            disabled={this.state.working}
                            />
                    </IonItem>                            

                    <IonItem>
                        <IonLabel position="floating">First name *</IonLabel>
                        <IonInput
                            name="first-name-input"
                            id="first-name-input" 
                            value={this.state.firstName}
                            onIonChange={e => this.onFieldChange("firstName", e.detail.value)}
                            autocapitalize="words"
                            disabled={this.state.working}
                            />
                    </IonItem>                            

                    <IonItem>
                        <IonLabel position="floating">Last name *</IonLabel>
                        <IonInput
                            name="last-name-input"
                            id="last-name-input" 
                            value={this.state.lastName}
                            onIonChange={e => this.onFieldChange("lastName", e.detail.value)}
                            autocapitalize="words"
                            disabled={this.state.working}
                            />
                    </IonItem>

                </IonList>

                <div className="text-xs ml-6 mt-2">
                    * Private details not shown to other users
                </div>

                {this.state.errorMessage
                    && <div className="flex flex-col w-full mt-8 pl-5 pr-5"> 
                        <IonItem
                            color="danger"                                    
                            className="text-error"
                            >
                            <div className="p-2">
                                {this.state.errorMessage}
                            </div>
                        </IonItem>
                    </div>
                }

                <div className="mt-8 pl-8 pr-8 pb-8 w-full">
                    <IonButton 
                        className="main-button h-14 w-full"
                        onClick={this.onSaveClick}
                        disabled={this.state.working || !this.state.changed || !this.state.validated}
                        >
                        <span className="ml-2">
                            {this.props.saveButtonTitle}
                        </span>
                    </IonButton>
                </div>
            </>
       );
    }
}
